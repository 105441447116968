import React from 'react';
import { TextField } from '../text-field';
import { INumberFieldProps } from './number-field.models';

export const NumberField: React.FC<INumberFieldProps> = ({
  min,
  max,
  defaultValue,
  fullWidth,
  onChange,
  ...props
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (onChange) {
      onChange(e);
    }
  };

  const handlekeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e): void => {
    if (e.key === 'e' || e.key === '+') {
      e.preventDefault();
    }
  };

  return (
    <TextField
      InputProps={{ min,
        max,
        defaultValue,
        fullWidth,
        ...props.InputProps }}
      onChange={handleChange}
      onKeyDown={handlekeyDown}
      type="number"
      {...props}
    />
  );
};
