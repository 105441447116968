import React from 'react';
import { createUseStyles } from 'react-jss';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { useAppStore } from '../../../stores/root/root.context';
import { Button } from '../ui/button';
import { Dialog, DialogFooter, DialogHeader } from '../ui/dialog';
import { Divider } from '../ui/divider';
import { Stack } from '../ui/stack';
import { TabPanel } from '../ui/tab-panel/tab-panel.view';
import { Tab, Tabs, useTabs } from '../ui/tabs';
import { Typography } from '../ui/typography';
import { DialogCreateNewsGeneral } from './dialog-create-news.general';
import { DialogCreateNewsLinks } from './dialog-create-news.links';
import { NewsCreateProps } from './dialog-create-news.models';
import { DialogCreateNewsText } from './dialog-create-news.text';


const PREFIX = 'DialogCreateNews';
const useStyles = createUseStyles(
  () => ({
    root: {
      width: '992px',
      maxHeight: '684px',
      minHeight: '684px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      position: 'relative',
      margin: '0 auto',
    },
    footer: {
      marginTop: 24,
      position: 'absolute',
      bottom: 40,
      right: 40,
      width: 'calc(100% - 80px)',
    },
    tabPanel: {
      marginTop: '32px',
      fontSize: '16px',
    },
  }),
  { name: PREFIX },
);

export const DialogCreateNews: React.FC<NewsCreateProps> = observer((exampleProps) => {
  const { className, onClose, onSubmit, readOnly, ...other } = exampleProps;

  const classes = useStyles();
  const rootCN = cn(classes.root, className);

  const { newsForm: form } = useAppStore();
  const news = form.getValues();
  const disabledModeration = !form.isValid;

  const tabs = useTabs('general');

  const handleSave = (): void => {
    if (onSubmit) {
      onSubmit();
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      color="primary"
      className={rootCN}
      onClose={(e): void => {
        if (onClose) {
          onClose(e);
        }
        form.reset();
      }}
      {...other}>
      <form
        onSubmit={(e): void => {
          e.stopPropagation();
          e.preventDefault();
          handleSave();
        }}
      >
        <DialogHeader onClose={onClose}>
          <Typography variant="h3" fontWeight="bold" style={{ marginBottom: 20 }}>
            {readOnly ? 'Просмотр новости' : 'Добавить новую запись'}
          </Typography>
        </DialogHeader>

        <Divider />

        <Tabs value={tabs.value} onChange={tabs.onChange} style={{ width: '100%' }}>
          <Tab value="general" label="Общая информация" />
          <Tab value="text" label="Текст новости" />
          {/* <Tab value="links" label="Похожие статьи" /> */}
        </Tabs>

        <TabPanel className={classes.tabPanel} current={tabs.value} value="general">
          <DialogCreateNewsGeneral readOnly={readOnly} />
        </TabPanel>

        <TabPanel className={classes.tabPanel} current={tabs.value} value="text">
          <DialogCreateNewsText readOnly={readOnly} />
        </TabPanel>

        <TabPanel className={classes.tabPanel} current={tabs.value} value="links">
          <DialogCreateNewsLinks />
        </TabPanel>

        {!readOnly && <DialogFooter className={classes.footer}>
          <Stack direction="column" spacing={24}>
            <Divider />
            <Stack spacing={16} justifyContent="flex-end">
              <Button
                variant="outlined"
                color="gray"
                style={{ justifyContent: 'center', height: '44px', fontSize: '16px' }}
                onClick={onClose}
              >
        Отменить
              </Button>
              <Button
                variant="gradient"
                color="primary"
                style={{ justifyContent: 'center', border: 'none', height: '44px', fontSize: '16px' }}
                onClick={handleSave}
                disabled={disabledModeration}
              >
        Сохранить
              </Button>
            </Stack>

          </Stack>
        </DialogFooter>}
      </form>
    </Dialog>
  );
});
