import React from 'react';
import { FormLabel } from '../../components/ui/form-label';

type ControlBlockProps = React.HtmlHTMLAttributes<HTMLDivElement> & { label: string; control: JSX.Element; required?: boolean };

export const ControlBlock: React.FC<ControlBlockProps> = ({
  label,
  control,
  required,
  style = {},
  ...other
}) => (
  <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 4, ...style }} {...other}>
    <FormLabel required={required}>{label}</FormLabel>
    {control}
  </div>
);
