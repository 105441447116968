import { IPortalStore } from '..';
import { convertTag } from './tag.mappers';
import { IPortalTag } from './types';

export type IPortalGetTagArgs = { _id: string };

export type IPortalGetTagRes<TDate = Date> = IPortalTag<TDate>;

export type IPortalFindTagArgs = {
  limit?: number;
  offset?: number;
  sort?: string;
} & IPortalTotalTagsArgs;
export type IPortalFindTagRes<TDate = Date> = {
  items: IPortalTag<TDate>[],
  totalCount: number,
};
export type IPortalTotalTagsArgs = {
  title?: string;
};

export type IPortalCreateTagArgs = {
  title: string;
};
export type IPortalCreateTagRes<TDate = Date> = IPortalTag<TDate>;

export type IPortalDeleteTagArgs = { _id: string };
export type IPortalDeleteTagRes = void;

export type IPortalUpdateTagArgs = {
  _id: string;
  title: string;
};
export type IPortalUpdateTagRes = void;

export type IPortalAcceptTagArgs = { _id: string };
export type IPortalAcceptTagRes = void;

export type IPortalDeclineTagArgs = { _id: string };
export type IPortalDeclineTagRes = void;

export class PortalTag {

  protected portal: IPortalStore;

  constructor(portal: IPortalStore) {
    this.portal = portal;
  }

  public async find(args: IPortalFindTagArgs): Promise<IPortalFindTagRes> {
    const res = await this.portal
      .jsonRequest<IPortalFindTagRes<string>>({
      method: 'GET',
      url: 'tag/all',
      data: args,
    });

    return {
      items: res.items.map(convertTag),
      totalCount: res.totalCount,
    };
  }

  public async create(args: IPortalCreateTagArgs): Promise<IPortalCreateTagRes> {
    const res = await this.portal
      .jsonRequest<IPortalCreateTagRes<string>>({
      method: 'POST',
      url: '/tag/create',
      data: args,
    });

    return convertTag(res);
  }

  public async delete(args: IPortalDeleteTagArgs): Promise<IPortalDeleteTagRes> {
    await this.portal.textRequest({
      method: 'DELETE',
      url: `tag/delete/${args._id}`,
    });
  }

  public async update(args: IPortalUpdateTagArgs): Promise<IPortalUpdateTagRes> {
    await this.portal.textRequest({
      method: 'PATCH',
      url: 'tag/update',
      data: args,
    });
  }

  public async publish(args: IPortalAcceptTagArgs): Promise<IPortalAcceptTagRes> {
    await this.portal.textRequest({
      method: 'PATCH',
      url: `/tag/publish/${args._id}`,
    });
  }

  public async decline(args: IPortalDeclineTagArgs): Promise<IPortalDeclineTagRes> {
    await this.portal.textRequest({
      method: 'PATCH',
      url: `/tag/decline/${args._id}`,
    });
  }

}
