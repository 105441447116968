import { Link, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { BrowserRoute } from '../../../routes/browser.routes';
import { CalendarDayIcon } from '../../components/ui/icons/calendar-day';
import { LightningIcon } from '../../components/ui/icons/lightning';
import { Paper } from '../../components/ui/paper';
import { Stack } from '../../components/ui/stack';
import { Tab, TabPanel, Tabs, useTabs } from '../../components/ui/tabs';
import { Typography } from '../../components/ui/typography';
import { ActivityListPage } from './activity-list.view';
import { NewsListPage } from './news-list.view';


export const NewsActivityBlock: React.FC = observer(() => {
  const location = useLocation();
  const tabs = useTabs(location.pathname);

  return (
    <>
      <Paper style={{ padding: 40, paddingTop: 30 }}>
        <div>
          <Stack style={{ alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="h2" fontWeight="bold" style={{ marginBottom: 0, marginTop: 0 }}>
              Управление новостями и мероприятиями
            </Typography>
          </Stack>
          <Stack style={{ flexDirection: 'column', marginBottom: 48 }}>
            <Tabs value={tabs.value} onChange={tabs.onChange} style={{ width: '100%', padding: '8px 0px 0px 0' }}>
              <Tab
                value={BrowserRoute.news}
                style={{ textDecoration: 'none' }}
                startIcon={<LightningIcon />}
                label="Новости"
                component={(p): JSX.Element => <Link to={BrowserRoute.news} {...p} />}
              />
              <Tab
                value={BrowserRoute.activity}
                style={{ textDecoration: 'none' }}
                startIcon={<CalendarDayIcon />}
                label="Мероприятия"
                component={(p): JSX.Element => <Link to={BrowserRoute.activity} {...p} />}
              />
            </Tabs>
          </Stack>
          <TabPanel current={tabs.value} value={BrowserRoute.news}>
            <NewsListPage />
          </TabPanel>

          <TabPanel current={tabs.value} value={BrowserRoute.activity}>
            <ActivityListPage />
          </TabPanel>
        </div>
      </Paper>
    </>
  );
});
