import React, { FC } from 'react';
import { SvgIcon, SvgIconProps } from '../svg-icon';

export const FileIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.50002 1.66916V1.66666H16.665C17.125 1.66666 17.5 2.04582 17.5 2.49332V17.5067C17.4998 17.726 17.4125 17.9362 17.2574 18.0912C17.1022 18.2463 16.8918 18.3333 16.6725 18.3333H3.32752C3.21808 18.3326 3.10987 18.3102 3.00906 18.2677C2.90825 18.2251 2.81681 18.1631 2.73997 18.0851C2.66313 18.0072 2.60239 17.9149 2.56122 17.8135C2.52005 17.7121 2.49925 17.6036 2.50002 17.4942V6.66666L7.50002 1.66916ZM4.85835 6.66666H7.50002V4.02499L4.85835 6.66666ZM9.16669 3.33332V7.49999C9.16669 7.721 9.07889 7.93297 8.92261 8.08925C8.76633 8.24553 8.55437 8.33332 8.33335 8.33332H4.16669V16.6667H15.8334V3.33332H9.16669Z"
      fill="currentColor"
    />
    <rect x="5.83301" y="13.3333" width="8.33333" height="1.25" fill="currentColor" />
    <rect x="5.83301" y="10.8333" width="8.33333" height="1.25" fill="currentColor" />
    <rect x="10.833" y="8.33334" width="3.33333" height="1.25" fill="currentColor" />
    <rect x="10.833" y="5.83334" width="3.33333" height="1.25" fill="currentColor" />
  </SvgIcon>
);
