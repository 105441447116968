import { createUseStyles } from 'react-jss';
import { observer } from 'mobx-react';
import { useAppStore } from '../../../stores/root/root.context';
import { ControlBlock } from '../control-block';
import { Button } from '../ui/button';
import { IconButton } from '../ui/icon-button';
import { AddIcon } from '../ui/icons/add';
import { CloseIcon } from '../ui/icons/close';
import { onErrorSnackbar } from '../ui/snackbar/snackbar.utils';
import { Stack } from '../ui/stack';
import { Typography } from '../ui/typography';
import { UploadFile } from '../ui/upload-file';

const PREFIX = 'DialogCreateActivityFiles';
const useStyles = createUseStyles(() => ({
  root: {
    maxHeight: 330,
    overflowY: 'auto',
    marginTop: 32,
  },
}), { name: PREFIX });

type DialogCreateActivityFilesProps = {
  readOnly?: boolean;
};


export const DialogCreateActivityFiles: React.FC<DialogCreateActivityFilesProps> = observer((props) => {
  const { readOnly } = props;
  const { portal, auth, activityForm: form } = useAppStore();
  const classes = useStyles();

  const files = form.getFieldValue('files') as string[];

  if (readOnly && files.filter((item) => item).length < 1) {
    return (
      <Typography style={{ marginTop: 32 }}>Файлы отсутствуют.</Typography>
    );
  }

  return (
    <Stack
      className={classes.root}
      direction="column"
      spacing={24}
    >
      {files.map((file, index: number) => (
        <Stack key={`files-${index}`} direction="row" spacing={4} alignItems="center">
          <ControlBlock
            label={`Файл о мероприятии #${index + 1}`}
            control={
              <UploadFile
                action={portal.portal.activity.uploadFileUrl}
                authorization={auth.token}
                onError={onErrorSnackbar}
                value={file}
                accept=".xlsx,.xls,.cfb,.ods,.odt,.odp,.doc,.docx,.csv,.tsv,.pdf"
                // @ts-ignore
                onChange={(v): void => form.updateFile(index, v)}
                readOnly={readOnly}
              />
            }
          />
          {files.length > 1 && !readOnly &&
            <IconButton onClick={(): void => form.deleteFile(index)} style={{ marginBottom: 0, marginTop: 'auto' }}>
              <CloseIcon style={{ width: 24, height: 24, color: '#909EBB', marginBottom: 0, marginTop: 0 }} />
            </IconButton>
          }
        </Stack>
      ))}
      {!readOnly && <Button
        startIcon={<AddIcon />}
        variant="outlined"
        color="primary"
        onClick={(): void => form.addFile()}
        style={{ justifyContent: 'center', border: 'none', color: '#0071CE', fontSize: '16px', marginTop: '4px' }}
      >
        добавить файл
      </Button>}
    </Stack>
  );
});
