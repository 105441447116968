import React from 'react';
import { createUseStyles } from 'react-jss';
import cn from 'classnames';
import { Container, ContainerProps } from '../container';

export type LayoutContentProps = ContainerProps;

const PREFIX = 'LayoutContent';
const useStyles = createUseStyles(
  () => ({
    root: {
      padding: 40,
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',

      width: '100%',
      height: '100%',
    },
  }),
  { name: PREFIX },
);

export const LayoutContent: React.FC<LayoutContentProps> = (props) => {
  const { className, maxWidth = 'xl', ...other } = props;

  const classes = useStyles();
  const rootCN = cn(classes.root, className);

  return <Container maxWidth={maxWidth} className={rootCN} {...other} />;
};
