import React, { HTMLAttributes } from 'react';
import { createUseStyles } from 'react-jss';
import cn from 'classnames';
import { Typography } from '../../components/ui/typography';

export type PageMessageProps = HTMLAttributes<HTMLDivElement> & { message: React.ReactNode };

const PREFIX = 'PageMessage';
const useStyles = createUseStyles(
  () => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      minHeight: 'calc(100vh - 120px)',
    },
  }),
  { name: PREFIX },
);

export const PageMessage: React.FC<PageMessageProps> = (exampleProps) => {
  const { className, message, ...other } = exampleProps;

  const classes = useStyles();
  const rootCN = cn(classes.root, className);

  return (
    <div className={rootCN}>
      <Typography>{message}</Typography>
    </div>
  );
};
