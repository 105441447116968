import React, { FC } from 'react';
import { ThemeProvider } from 'react-jss';
import { BrowserRouter, useRoutes } from 'react-router-dom';
import { Wrapper } from '@unione-pro/unione.commons.webpack';
import { appRoutesDefinition } from './routes/app.routes';
import { Access } from './views/components/access';
import { Auth } from './views/components/auth';
import { Snackbar } from './views/components/ui/snackbar';
import { appTheme } from './views/components/ui/theme';

const Content: React.FC = () => {
  const appRoutes = useRoutes(appRoutesDefinition);

  return appRoutes;
};

export const App: FC = () => (
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={appTheme}>
        <Auth>
          <Access>
            <Wrapper>
              <Content />
              <Snackbar />
            </Wrapper>
          </Access>
        </Auth>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);
