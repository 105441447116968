import React, { HTMLAttributes } from 'react';
import { createUseStyles } from 'react-jss';
import cn from 'classnames';
import { CircularProgress } from '../circular-progress';
import { PaperClipIcon } from '../icons/paper-clip';
import { Typography } from '../typography';

const PREFIX = 'UploadFileField';
const useStyles = createUseStyles(
  (theme) => ({
    root: {
      border: '2px dashed #E4E7F2',
      cursor: 'pointer',
    },
    error: {
      border: `2px dashed ${theme.palette.error.main}`,
    },
    box: {
      padding: '27px 35px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    label: {
      marginLeft: 16,
      cursor: 'pointer',
    },
    icon: {
      width: 22,
      height: 24,
      color: '#99C6EB',
    },
  }),
  { name: PREFIX },
);

export type UploadFileFieldProps = HTMLAttributes<HTMLDivElement> & { multiple?: boolean; loading?: boolean; error?: boolean; };

export const UploadFileField: React.FC<UploadFileFieldProps> = (exampleProps) => {
  const { className, multiple, loading, error, ...other } = exampleProps;

  const classes = useStyles();
  const rootCN = cn(classes.root, { [classes.error]: error && !loading }, className);

  return (
    <div className={rootCN} {...other}>
      {!loading && (
        <div className={classes.box}>
          <PaperClipIcon className={classes.icon} />

          <Typography className={classes.label} color="textSecondary" component="label">
            Переместите файл{multiple ? 'ы' : ''} сюда или{' '}
            <Typography color="primary" component="span">
              выберите файл{multiple ? 'ы' : ''}
            </Typography>
          </Typography>
        </div>
      )}
      {loading && (
        <div style={{ display: 'flex', justifyContent: 'center', padding: '9px 35px' }}>
          <CircularProgress style={{ width: 60, height: 60 }} />
        </div>
      )}
    </div>
  );
};
