import React from 'react';
import { createUseStyles } from 'react-jss';
import { TimePicker } from 'antd';
import cn from 'classnames';
import dayjs from 'dayjs';
import { FormHelperText } from '../form-helper-text';
import { ITimeFieldProps } from './time-field.models';

const PREFIX = 'TimeField';
const useStyles = createUseStyles(
  (theme) => ({
    root: {},
    picker: {
      width: '187px',
      '&:hover, &:focus': {
        borderColor: '#d9d9d9',
        boxShadow: 'none',
      },
      '& .ant-picker-disabled': {
        cursor: 'auto !important',
      },
      '& .ant-picker-input': {
        padding: '3px 8px',
        '& input': {
          cursor: 'auto !important',
          color: theme.palette.text.primary,
          fontSize: '14px',
          height: '20px',
          '&::placeholder': {
            color: theme.palette.text.secondary,
          },
        },
      },
    },
    helplerText: {
      width: '187px',
    },
    fullWidth: {
      width: '100%',
    },
    readOnly: {
      borderColor: 'transparent',
      background: '#F5F6F8',
      color: '#3B4168',
    },
    icon: {
      width: 20,
      height: 20,
      cursor: 'pointer',
      position: 'relative',
      color: theme.palette.text.secondary,

      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
  }),
  { name: PREFIX },
);

export const TimeField: React.FC<ITimeFieldProps> = (props) => {
  const {
    value,
    onChange,
    fullWidth,
    className,
    helperText,
    color,
    placeholder,
    disabled,
    error,
    TimePickerProps,
    readOnly,
    ...other
  } = props;

  const classes = useStyles();
  const rootCN = cn(classes.root, { [classes.fullWidth]: fullWidth }, className);
  const pickerCN = cn(classes.picker, { [classes.readOnly]: readOnly });

  const overrideColor = error ? 'error' : color;

  const handleChange = (val: dayjs.Dayjs | null): void => {
    if (onChange && val) {
      onChange(val.toDate());
    }
  };

  return (
    <div className={rootCN} color={overrideColor} {...other}>
      <TimePicker
        className={pickerCN}
        value={value ? dayjs(value) : undefined}
        disabled={disabled || readOnly}
        placeholder={placeholder}
        showSecond={false}
        size="large"
        format="HH:mm"
        showNow={false}
        suffixIcon={false}
        onChange={(v): void => handleChange(v)}
        allowClear={!readOnly}
      />
      {!!helperText && <FormHelperText className={classes.helplerText} color={overrideColor} variant="label">{helperText}</FormHelperText>}
    </div>
  );
};
