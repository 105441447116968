import React from 'react';
import { createUseStyles } from 'react-jss';
import { observer } from 'mobx-react';
import { useSearch } from '../../../../shared/utils/hooks';
import { QUESTION_FORM_INITIAL_STATE } from '../../../../stores/form/question/defaults';
import { IPortalQuestion } from '../../../../stores/question/types';
import { useAppStore } from '../../../../stores/root/root.context';
import { QuestionDialog } from '../../../components/dialog-question/dialog-question.view';
import { Button } from '../../../components/ui/button';
import { useDialogState } from '../../../components/ui/dialog/dialog.hook';
import { AddIcon } from '../../../components/ui/icons/add';
import { SearchIcon } from '../../../components/ui/icons/search';
import { Menu } from '../../../components/ui/menu';
import { MenuItem } from '../../../components/ui/menu/menu-item.view';
import { useMenuState } from '../../../components/ui/menu/menu.hook';
import { onErrorSnackbar, snackbar } from '../../../components/ui/snackbar/snackbar.utils';
import { Stack } from '../../../components/ui/stack';
import { Table } from '../../../components/ui/table';
import { TablePagination, useTablePaginationState } from '../../../components/ui/table-pagination';
import { TextField } from '../../../components/ui/text-field';
import { getQuestionListColumns } from './questions-list.columns';


const PREFIX = 'QuestionListPage';
const useStyles = createUseStyles(
  () => ({
    root: {},
    header: {
      flex: 1,
      marginTop: 24,
      width: '100%',
      marginBottom: 24,
    },
  }),
  { name: PREFIX },
);

export const QuestionListPage: React.FC = observer(() => {
  const classes = useStyles();
  const { portal, questionForm: form } = useAppStore();

  const [selectedQuestion, setSelectedQuestion] = React.useState<IPortalQuestion | undefined>();

  const menuState = useMenuState();
  const search = useSearch();
  const pagination = useTablePaginationState(0, 10);
  const questionDialog = useDialogState();

  const [createQuestion] = portal.createQuestion.useMutation({
    onError: onErrorSnackbar,
  });
  const [deleteQuestion] = portal.deleteQuestion.useMutation({
    onError: onErrorSnackbar,
  });
  const [updateQuestion] = portal.updateQuestion.useMutation({
    onError: onErrorSnackbar,
  });

  const { data, loading, refetch } = portal.questionList.useQuery({
    variables: {
      limit: pagination.rowsPerPage,
      offset: pagination.page * pagination.rowsPerPage,
      sort: 'sort_priority,-status,-created_at',
      title: search.debounced,
    },
  });


  const handleSave = (): void => {
    const formValues = form.getArgs();
    if (!selectedQuestion) {
      createQuestion({ ...formValues }).then((): void => {
        refetch();
        snackbar({ message: 'Вопрос создан!', severity: 'success' });
        questionDialog.close();
      });
    }
    else {
      updateQuestion({
        ...formValues,
        _id: selectedQuestion._id,
      }).then((): void => {
        refetch();
        snackbar({ message: 'Вопрос обновлен!', severity: 'success' });
        questionDialog.close();
      });
    }
  };

  const changePriority = (
    priority: number,
    record: { sort_priority: number; section: { _id: string; }; system: { _id: string; }; _id: string; },
  ): void => {
    if (record.sort_priority === priority) {
      return;
    }

    updateQuestion({
      section: record.section._id,
      system: record.system._id,
      sort_priority: priority,
      _id: record._id,
    } as IPortalQuestion).then((): void => {
      refetch();
      snackbar({ message: 'Вопрос обновлен!', severity: 'success' });
    });
  };

  const handleDelete = (_id: string): void => {
    deleteQuestion({ _id }).then((): void => {
      refetch();
      snackbar({ message: 'Вопрос удален!', severity: 'success' });
    });
  };

  const columns = getQuestionListColumns({
    onMore: (record) => (e): void => {
      e.stopPropagation();
      e.preventDefault();

      if (menuState.open) {
        menuState.onClose();
      }
      else {
        menuState.show(e);
        setSelectedQuestion(record);
      }
    },
    // @ts-ignore
    onUpdatePriority: changePriority,
  });

  return (
    <div className={classes.root}>
      <Stack direction="row" justifyContent="space-between" className={classes.header}>
        <TextField
          style={{ maxWidth: 480 }}
          fullWidth
          placeholder="Поиск по названию"
          InputProps={{ endAdornment: <SearchIcon /> }}
          value={search.value}
          onChange={(e): void => {
            search.change(e.target.value);
          }}
        />

        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={(): void => {
            form.reset(QUESTION_FORM_INITIAL_STATE);
            setSelectedQuestion(undefined);
            questionDialog.open();
          }}
        >
                    Добавить новую запись
        </Button>
      </Stack>

      <Table<IPortalQuestion>
        columns={columns}
        loading={loading}
        rowKey="_id"
        data={data?.items}
        hover
        footer={(): JSX.Element => (
          <TablePagination
            total={data?.totalCount || 0}
            page={pagination.page}
            rowsPerPage={pagination.rowsPerPage}
            onChangePage={pagination.onChangePage}
            onChangeRowsPerPage={pagination.onChangeRowsPerPage}
          />
        )}
      />

      {selectedQuestion && <Menu {...menuState.props} placement="bottom-right">
        <MenuItem
          onClick={(): void => {
            menuState.onClose();
            form.load(selectedQuestion);
            questionDialog.open();
          }}
        >
                    Редактировать
        </MenuItem>
        <MenuItem
          onClick={(): void => {
            menuState.onClose();
            handleDelete(selectedQuestion._id);
          }}
        >
                    Удалить
        </MenuItem>
      </Menu>}

      <QuestionDialog {...questionDialog.props} onSubmit={handleSave} />
    </div>
  );
});
