import React from 'react';
import { ColumnsType } from 'rc-table/lib/interface';
import { courseStatusToLocale } from '../../../../shared/utils/format';
import { toHumanDate } from '../../../../shared/utils/human-date';
import { IPortalCourse, IPortalCourseStatus } from '../../../../stores/course/types';
import { tableCellMore } from '../../../components/ui/table/table.columns';
import { Typography } from '../../../components/ui/typography';

export const getCourseListColumns = (opts: {
  onMore: (course: IPortalCourse) => (e: React.MouseEvent<HTMLElement>) => void;
}): ColumnsType<IPortalCourse> => {
  const columns: ColumnsType<IPortalCourse> = [
    {
      title: 'Название',
      width: 200,
      render: (value, record) => <Typography style={{ wordWrap: 'break-word' }}>{record.title}</Typography>,
    },
    {
      title: 'Дата создания/обновления',
      width: 200,
      render: (value, record) => <Typography>{toHumanDate(record.created_at)}</Typography>,
    },
    {
      title: 'Статус',
      width: 200,
      render: (value, record) => <Typography style={{ color: record.status === IPortalCourseStatus.published ? '#73D071' : '#BDBDBD' }}>{courseStatusToLocale(record.status)}</Typography>,
    },
    tableCellMore(opts.onMore),
  ];

  return columns;
};
