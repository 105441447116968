import React from 'react';
import cn from 'classnames';
import { TabPanelProps } from '../tabs/tabs.models';
import { createUseStyles } from '../theme/createUseStyles';

const PREFIX = 'TabPanel';

const useStyles = createUseStyles(
  () => ({
    root: {},
    hidden: {
      display: 'none',
    },
  }),
  { name: PREFIX },
);

export const TabPanel: React.FC<TabPanelProps> = (props) => {
  const { className, hidden, current, value, ...other } = props;

  const classes = useStyles();

  return (
    <div
      {...other}
      className={cn(classes.root, { [classes.hidden]: typeof current === 'undefined' || value !== current }, className)}
    >
      {props.children}
    </div>
  );
};
