import React from 'react';
import { createUseStyles } from 'react-jss';
import cn from 'classnames';
import { getTextRowsStyles } from '../../../../shared/utils/style/get-text-rows-style';
import { TypographyProps } from './typography.models';

const PREFIX = 'Typography';
const useStyles = createUseStyles(
  (theme) => ({
    root: {
      ...theme.typography.subtitle1,
      fontWeight: theme.typography.fontWeightLight,
      fontFamily: theme.typography.fontFamily,
      margin: 0,
    },

    colorTextSecondary: {
      color: theme.palette.text.secondary,
    },
    colorTextPrimary: {
      color: theme.palette.text.primary,
    },
    colorPrimary: {
      color: theme.palette.primary.main,
    },

    variantLink: {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
    variantH1: {
      ...theme.typography.h1,
      fontFamily: theme.typography.fontFamilyMontserrat,
    },
    variantH2: {
      ...theme.typography.h2,
      fontFamily: theme.typography.fontFamilyMontserrat,
    },
    variantH3: {
      ...theme.typography.h3,
      fontFamily: theme.typography.fontFamilyMontserrat,
    },
    variantH4: {
      ...theme.typography.h4,
      fontFamily: theme.typography.fontFamilyMontserrat,
    },
    variantH5: {
      ...theme.typography.h5,
      fontFamily: theme.typography.fontFamilyMontserrat,
    },
    variantH6: {
      ...theme.typography.h6,
      fontFamily: theme.typography.fontFamilyMontserrat,
    },
    variantSubtitle1: {
      ...theme.typography.subtitle1,
    },
    variantBody1: {
      ...theme.typography.body1,
    },
    variantLabel: {
      ...theme.typography.label,
    },
    variantCaption: {
      ...theme.typography.caption,
    },
    variantInherit: {
      fontSize: 'inherit',
      fontWeight: 'inherit',
    },
    fontWeightBolder: {
      fontWeight: theme.typography.fontWeightBolder,
    },
    fontWeightBold: {
      fontWeight: theme.typography.fontWeightBold,
    },
    fontWeightMedium: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    fontFamilyIBM: {
      fontFamily: theme.typography.fontFamilyIBM,
    },
    fontFamilyMontserrat: {
      fontFamily: theme.typography.fontFamilyMontserrat,
    },
    alignCenter: {
      textAlign: 'center',
    },
    noWrap: {
      ...getTextRowsStyles(1),
    },
  }),
  { name: PREFIX },
);

export const Typography: React.FC<TypographyProps> = (props) => {
  const { className, component, variant = 'body1', color = 'textPrimary', fontWeight, fontFamily, align, noWrap, ...other } = props;

  const classes = useStyles();
  const rootCN = cn(
    classes.root,
    {
      [classes.colorTextSecondary]: color === 'textSecondary',
      [classes.colorPrimary]: color === 'primary',
      [classes.colorTextPrimary]: color === 'textPrimary',
      [classes.variantH1]: variant === 'h1',
      [classes.variantH2]: variant === 'h2',
      [classes.variantH3]: variant === 'h3',
      [classes.variantH4]: variant === 'h4',
      [classes.variantH5]: variant === 'h5',
      [classes.variantH6]: variant === 'h6',
      [classes.variantBody1]: variant === 'body1',
      [classes.variantSubtitle1]: variant === 'subtitle1',
      [classes.variantLabel]: variant === 'label',
      [classes.variantCaption]: variant === 'caption',
      [classes.variantInherit]: variant === 'inherit',
      [classes.variantLink]: variant === 'link',
      [classes.fontWeightBold]: fontWeight === 'bold',
      [classes.fontWeightMedium]: fontWeight === 'medium',
      [classes.fontWeightBolder]: fontWeight === 'bolder',
      [classes.fontFamilyIBM]: fontFamily === 'IBM Plex Sans',
      [classes.fontFamilyMontserrat]: fontFamily === 'Montserrat',
      [classes.alignCenter]: align === 'center',
      [classes.noWrap]: noWrap,
    },
    className,
  );

  const mixProps = variant === 'link' ? { rel: 'noreferrer', target: '_blank' } : {};
  const overrideComponent = component || (variant === 'link' ? 'a' : undefined);

  return React.createElement(overrideComponent || 'p', { className: rootCN, ...mixProps, ...other });
};
