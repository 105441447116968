import { BaseFormStore } from '../../../shared/form';
import { IPortalUpdateNewsArgs } from '../../news';
import { ICreateNews, IPortalNews } from '../../news/types';
import { NEWS_FORM_INITIAL_STATE } from './defaults';
import { newsSchema } from './schema';

export class NewsFormStore extends BaseFormStore<ICreateNews> {

  constructor() {
    super(NEWS_FORM_INITIAL_STATE, newsSchema);
  }

  public load(news: IPortalNews): void {
    super.load({
      title: news.title,
      short_description: news.short_description,
      text_top: news.text_top,
      text_bottom: news.text_bottom,
      images: news.images,
      images_description: news.images_description,
      date: news.date,
      desktop_cover: news.desktop_cover,
      tablet_cover: news.tablet_cover,
      mobile_cover: news.mobile_cover,
      similar_news: news.similar_news,
    });
  }

  public getArgs(): Omit<IPortalUpdateNewsArgs, '_id'> {
    const { title, ...state } = this.getValues();
    const args = {
      ...state,
      title: title || 'Добавить новую запись',
    };

    return args;
  }

  public addImage(): void {
    const images = this.getFieldValue('images') as string[];

    this.setFieldValue('images', [...images, '']);
  }

  public updateImage(index: number, value: string): void {
    const images = this.getFieldValue('images') as string[];
    images.splice(index, 1, value);

    this.setFieldValue('images', images);
  }

  public deleteImage(index: number): void {
    const images = this.getFieldValue('images') as string[];
    const updatedImages = images.filter((_, i) => i !== index);

    this.setFieldValue('images', updatedImages);
  }

  public updateLink(index: number, value: string): void {
    const arr = this.getFieldValue('similar_news') as string[];
    arr.splice(index, 1, value);

    this.setFieldValue('similar_news', arr);
  }

  public deleteLink = (index: number): void => {
    const additionalLinks = this.getFieldValue('similar_news') as string[];
    const updatedLinks = additionalLinks?.filter((_, i) => i !== index);
    this.setFieldValue('similar_news', updatedLinks);
  };

  public addLink(): void {
    const links = this.getFieldValue('similar_news') as string[];
    this.setFieldValue('similar_news', [...links, '']);
  }

}
