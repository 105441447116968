import React from 'react';
import { Toaster } from 'react-hot-toast';
import { createUseStyles } from 'react-jss';
import cn from 'classnames';
import { SnackbarProps } from './snackbar.models';

const PREFIX = 'Snackbar';
const useStyles = createUseStyles(
  () => ({
    root: {
      padding: 0,
      boxShadow: 'none',

      '& > div': {
        margin: 0,
      },
    },
  }),
  { name: PREFIX },
);

export const Snackbar: React.FC<SnackbarProps> = (props) => {
  const { toastOptions: { className, ...toastOptions } = {} } = props;
  const classes = useStyles();

  return (
    <Toaster
      position="top-right"
      toastOptions={{ className: cn(classes.root, className), ...toastOptions }}
      {...props}
    />
  );
};
