import React, { FC } from 'react';
import cn from 'classnames';
import { createUseStyles } from '../theme/createUseStyles';
import { Typography } from '../typography';
import { IFormLabelProps } from './form-label.models';

const PREFIX = 'FormLabel';

const useStyles = createUseStyles(
  () => ({
    root: {
      marginBottom: 4,
    },
  }),
  { name: PREFIX },
);

export const FormLabel: FC<IFormLabelProps> = (props) => {
  const { className, required, children, ...other } = props;

  const classes = useStyles();
  const rootCN = cn(classes.root, className);

  return (
    <Typography variant="label" className={rootCN} {...other}>
      {children}
      {required ? (
        <Typography component="span" color="primary" variant="inherit" style={{ marginLeft: 4 }}>
          *
        </Typography>
      ) : null}
    </Typography>
  );
};
