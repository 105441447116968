import { getUnioneToken } from '@unione-pro/unione.commons.webpack';
import { makeAutoObservable, runInAction } from 'mobx';
import { EnvKeys } from './config.models';
import { RootStore } from './root.store';

export class AuthStore {

  public readonly rootStore: RootStore;
  public token: string | undefined;
  public loaded: boolean = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.token = this.getValue(EnvKeys.LOCAL_TOKEN);

    if (this.token) {
      this.loaded = true;
    }

    makeAutoObservable(this);
  }
  private getValue(key: EnvKeys): string | undefined {
    const value = process.env[key];
    return value;
  }

  public async load(): Promise<void> {
    try {
      const uniOneToken = await getUnioneToken();

      if (typeof uniOneToken === 'string') {
        runInAction(() => {
          this.token = uniOneToken as string;
          this.loaded = true;
          this.rootStore.portal.refresh();
        });
      }
    }
    catch (e) {
      //
    }

    this.loaded = true;
  }

}
