import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '../svg-icon';

export const ArrowLeftIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.66699 9.99996L12.0639 15.8333L13.3337 14.4609L9.20291 9.99608L13.3337 5.5313L12.0639 4.16663L6.66699 9.99996Z"
      fill="currentColor"
    />
  </SvgIcon>
);
