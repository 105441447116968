import React, { FC } from 'react';
import { SvgIcon, SvgIconProps } from '../svg-icon';

export const ResultsIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13 7V13V7ZM10 9.25V13V9.25ZM7 11.5V13V11.5ZM5.5 16H14.5C14.8978 16 15.2794 15.842 15.5607 15.5607C15.842 15.2794 16 14.8978 16 14.5V5.5C16 5.10218 15.842 4.72064 15.5607 4.43934C15.2794 4.15804 14.8978 4 14.5 4H5.5C5.10218 4 4.72064 4.15804 4.43934 4.43934C4.15804 4.72064 4 5.10218 4 5.5V14.5C4 14.8978 4.15804 15.2794 4.43934 15.5607C4.72064 15.842 5.10218 16 5.5 16Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
