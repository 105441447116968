import React from 'react';
import { useDebounce } from 'react-use';

type IUseSearch = {
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  debounced: string;
  change: (v: string) => void;
};

export const useSearch = (): IUseSearch => {
  const [search, setSearch] = React.useState('');
  const [searchD, setSearchD] = React.useState('');

  useDebounce(
    (): void => {
      setSearchD(search);
    },
    300,
    [search],
  );

  return {
    value: search,
    onChange: (e): void => setSearch(e.target.value),
    debounced: searchD,
    change: (v): void => setSearch(v),
  };
};
