import { LocaleObject } from 'yup/lib/locale';
import { toHumanDate } from '../human-date';

export const yupRu: LocaleObject = {
  mixed: {
    required: 'это поле обязательно к заполнению',
  },
  string: {
    max: ({ max }): string => `должно быть не более ${max} ${max === 1 ? 'символа' : 'символов'}`,
    min: ({ min, value }): string => {
      if (value) {
        return `должно быть не менее ${min} ${min === 1 ? 'символа' : 'символов'}`;
      }

      return 'это поле обязательно к заполнению';
    },
    matches: 'не валидный формат данных',
  },
  number: {
    moreThan: ({ more }): string => `должно быть больше ${more}`,
    lessThan: ({ less }): string => `должно быть меньше ${less}`,
    integer: 'число должно быть целым',
    positive: 'число должно быть положительным',
    max: ({ max }): string => `должно быть не больше ${max}`,
    min: ({ min, value }): string => {
      if (value) {
        return `должно быть не меньше ${min}`;
      }

      return 'это поле обязательно к заполнению';
    },
  },
  date: {
    min: ({ min }): string => `выбранная дата должна быть не ранее ${typeof min === 'string' ? min : toHumanDate(min as Date)}`,
    max: ({ max }): string => `выбранная дата должна быть не позднее ${typeof max === 'string' ? max : toHumanDate(max as Date)}`,
  },
};
