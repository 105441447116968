import { IPortalStore } from '..';
import { Order } from '../types';
import { convertSystem } from './system.mappers';
import { IPortalSystem, PortalSystemOrderBy } from './types';


export type IPortalFindSystemArgs = {
  limit?: number;
  offset?: number;
  sort?: string;
} & IPortalTotalSystemsArgs;

export type IPortalFindSystemRes<TDate = Date> = {
  items: IPortalSystem<TDate>[],
  totalCount: number,
};
export type IPortalTotalSystemsArgs = {
  title?: string;
};

export type IPortalUpdateSystemArgs = Omit<IPortalSystem,
| 'created_at'
| 'updated_at'
| 'title'
>;
export type IPortalUpdateSystemRes = void;

export type IPortalCreateSystemArgs = {
  title: string;
};
export type IPortalCreateSystemRes<TDate = Date> = IPortalSystem<TDate>;

export type IPortalDeleteSystemArgs = { _id: string };
export type IPortalDeleteSystemRes = void;

export class PortalSystem {

  protected portal: IPortalStore;
  public uploadUrl: string;

  constructor(portal: IPortalStore) {
    this.portal = portal;
    this.uploadUrl = `${portal.baseURL}/upload/create`;
  }

  public async create(args: IPortalCreateSystemArgs): Promise<IPortalCreateSystemRes> {
    const res = await this.portal
      .jsonRequest<IPortalCreateSystemRes<string>>({
      method: 'POST',
      url: '/system/create',
      data: args,
    });

    return convertSystem(res);
  }

  public async delete(args: IPortalDeleteSystemArgs): Promise<IPortalDeleteSystemRes> {
    await this.portal.textRequest({
      method: 'DELETE',
      url: `system/delete/${args._id}`,
      data: args,
    });
  }

  public async find(args: IPortalFindSystemArgs): Promise<IPortalFindSystemRes> {
    const res = await this.portal
      .jsonRequest<IPortalFindSystemRes<string>>({
      method: 'GET',
      url: '/system/all',
      data: args,
    });

    return {
      items: res.items.map(convertSystem),
      totalCount: res.totalCount,
    };
  }

  public async update(args: IPortalUpdateSystemArgs): Promise<IPortalUpdateSystemRes> {
    await this.portal.textRequest({
      method: 'PATCH',
      url: '/system/update',
      data: args,
    });
  }

}
