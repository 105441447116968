import { ActivityFormState } from '../../activity/types';

export const ACTIVITY_FORM_INITIAL_STATE: ActivityFormState = {
  title: '',
  start_date: undefined,
  end_date: undefined,
  desktop_cover: '',
  tablet_cover: '',
  mobile_cover: '',
  place: '',
  price: 0,
  price_visible: false,
  link_to_entry: '',
  is_main: false,
  short_description: '',
  full_description: '',
  speakers: [{
    fullname: '',
    appointment: '',
    photo_id: '',
  }],
  tags: [''],
  files: [''],
  similar_activity: [],
};
