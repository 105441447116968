import React from 'react';
import cn from 'classnames';
import { createUseStyles } from '../theme/createUseStyles';
import { IFormControlLabelProps } from './form-control-label.models';

const PREFIX = 'FormControlLabel';

const useStyles = createUseStyles(
  (theme) => ({
    root: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      width: 'fit-content',
      userSelect: 'none',
      color: theme.palette.text.primary,

      '& > *:first-child': {
        marginRight: '16px',
      },
      ...theme.typography.subtitle1,
    },
    fullWidth: {
      justifyContent: 'space-between',
      width: '100%',
    },
    right: {
      flexDirection: 'row-reverse',
      '& > *:first-child': {
        marginRight: '0px',
        marginLeft: '16px',
      },
    },
  }),
  { name: PREFIX },
);

export const FormControlLabel: React.FC<IFormControlLabelProps> = (formControlLabelProps) => {
  const { className, control, label, value, checked, name, onChange, defaultChecked, placement, fullWidth, ...other }
    = formControlLabelProps;

  const classes = useStyles();
  const rootCN = cn(
    classes.root,
    { [classes.fullWidth]: fullWidth, [classes.right]: placement === 'right' },
    className,
  );

  const controlEl
    = control &&
    React.cloneElement(control, {
      ...control.props,
      value,
      checked: control.props.checked || checked || false,
      name: control.props.name || name,
      defaultChecked: control.props.defaultChecked || defaultChecked,
      onChange: (e: React.ChangeEvent<HTMLInputElement>): void => {
        if (control.props.onChange) {
          control.props.onChange(e);
        }
        else if (onChange) {
          onChange(e.target.checked, value, name);
        }
      },
    });

  return (
    <label
      {...other}
      data-value={value}
      className={rootCN}
    >
      {controlEl}
      {label}
    </label>
  );
};
