import React, { FC } from 'react';
import { SvgIcon, SvgIconProps } from '../svg-icon';

export const PhoneIcon: FC<SvgIconProps> = ({ style = {}, ...props }) => (
  <SvgIcon
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ padding: 2, ...style }}
    {...props}
  >
    <path
      d="M4.67962 2.20927L5.18178 1.70711C5.57231 1.31658 6.20547 1.31658 6.59599 1.70711L10.0707 5.18178C10.4612 5.57231 10.4612 6.20547 10.0707 6.59599L8.66547 8.0012C8.46712 8.19955 8.41795 8.50256 8.54339 8.75345C9.99376 11.6542 12.3458 14.0062 15.2465 15.4566C15.4974 15.5821 15.8005 15.5329 15.9988 15.3345L17.404 13.9293C17.7945 13.5388 18.4277 13.5388 18.8182 13.9293L22.2929 17.404C22.6834 17.7945 22.6834 18.4277 22.2929 18.8182L21.7907 19.3204C19.6794 21.4317 16.3368 21.6693 13.9481 19.8777L10.9619 17.6381C9.21837 16.3304 7.66955 14.7816 6.36191 13.0381L4.12226 10.0519C2.33072 7.66318 2.56827 4.32062 4.67962 2.20927Z"
      stroke="currentColor"
      strokeWidth="2"
    />
  </SvgIcon>
);
