import React from 'react';
import { PageMessage } from '../../lab/page-message';
import { CircularProgress } from '../ui/circular-progress';
import { Typography } from '../ui/typography';

const iconSize = 48;

export const AuthLoader: React.FC = () => (
  <PageMessage
    message={
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <CircularProgress width={iconSize} height={iconSize} />
        <Typography variant="h2" align="center">
          Выполняется проверка авторизации
        </Typography>
      </div>
    }
  />
);
