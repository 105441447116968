import { BaseFormStore } from '../../../shared/form';
import { IPortalUpdateQuestionArgs } from '../../question';
import { QuestionFormState, IQuestionAnswer, IPortalQuestion } from '../../question/types';
import { QUESTION_FORM_INITIAL_STATE } from './defaults';
import { questionSchema } from './schema';


export class QuestionFormStore extends BaseFormStore<QuestionFormState> {

  constructor() {
    super(QUESTION_FORM_INITIAL_STATE, questionSchema);
  }

  // @ts-ignore
  public load(question: IPortalQuestion): void {
    super.load({
      question: question.question,
      answers: question.answers,
      section: question.section,
      system: question.system,
      sort_priority: question.sort_priority,
    });
  }

  public getArgs(): Omit<IPortalUpdateQuestionArgs, '_id'> {
    const { question, system, section, ...state } = this.getValues();
    const args = {
      ...state,
      question: question || 'Новый вопрос',
      system: typeof system === 'object' ? system._id : system,
      section: typeof section === 'object' ? section._id : section,
    };

    return args;
  }

  public addAnswer(): void {
    const answers = this.getFieldValue('answers') as IQuestionAnswer[];

    this.setFieldValue('answers', [...answers, { text: '', picture: '' }]);
  }

  public updateAnswers(index: number, field: keyof IQuestionAnswer, value: IQuestionAnswer[typeof field]): void {
    const arr = this.getFieldValue('answers') as IQuestionAnswer[];
    const item = arr[index];

    if (item) {
      item[field] = value as Date & string;
      arr.splice(index, 1, item);
    }

    this.setFieldValue('answers', arr);
  }

  public deleteAnswers = (index: number): void => {
    const answers = this.getFieldValue('answers') as IQuestionAnswer[];
    const updatedAnswers = answers?.filter((_, i) => i !== index);

    this.setFieldValue('answers', updatedAnswers);
  };

}
