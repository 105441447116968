import React from 'react';
import cn from 'classnames';
import { FormHelperText } from '../form-helper-text';
import { ChevronsIcon } from '../icons/chevrons';
import { InputBase, useInputBaseStyles } from '../input-base';
import { Menu } from '../menu';
import { useMenuState } from '../menu/menu.hook';
import { createUseStyles } from '../theme/createUseStyles';
import { Typography } from '../typography';
import { getTextRowsStyles } from '../utils/style/get-text-rows-style';
import { SelectProps } from './select.models';

const PREFIX = 'Select';
const useStyles = createUseStyles(
  (theme) => ({
    root: {
      minWidth: 32,
      cursor: 'pointer',
      width: 'fit-content',
      position: 'relative',
    },
    select: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
    },
    input: {
      opacity: 0,
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      userSelect: 'none',
      pointerEvents: 'none',
      position: 'absolute',
    },
    item: {},
    menu: {
      maxHeight: 300,
      maxWidth: 500,

      '& > div': {
        maxHeight: 300,
        overflowY: 'auto',
      },
    },

    icon: {
      // marginLeft: 4,
      // width: 20,
      // height: 20,
      color: theme.palette.text.secondary,
    },
    outlined: {
      height: 42,
      paddingRight: 6,

      '& $icon': {
        // marginLeft: 8,
      },
    },
    label: {
      paddingRight: 32,
    },
    emptyLabel: {
      color: '#909EBB',
    },
    emptyText: {
      cursor: 'default',
      color: '#909EBB',
      padding: '8px 24px',
    },
    fullWidth: { width: '100%' },
    readOnly: {
      cursor: 'default',
    },
  }),
  { name: PREFIX },
);

export const Select = <T extends string = string>(exampleProps: SelectProps<T>): JSX.Element => {
  const {
    className,
    children,
    value,
    onChange,
    variant = 'outlined',
    name,
    color = 'default',
    fullWidth,
    disabled,
    MenuProps: { className: menuClassName, ...otherMenu } = {},
    placeholder,
    required,
    error,
    helperText,
    readOnly,
    ...other
  } = exampleProps;

  const overrideColor = error ? 'error' : color;

  const menuState = useMenuState();

  const classes = useStyles();
  const inputClasses = useInputBaseStyles();
  const rootCN = cn(classes.root, { [classes.fullWidth]: fullWidth, [classes.readOnly]: readOnly }, className);
  const inputCN = cn(classes.select, {
    [classes.outlined]: variant === 'outlined',
    [inputClasses.input]: variant === 'outlined',
    [inputClasses.colorDefault]: overrideColor === 'default',
    [inputClasses.colorError]: overrideColor === 'error',
    [inputClasses.colorWarning]: overrideColor === 'warning',
    [inputClasses.colorSuccess]: overrideColor === 'success',
    [inputClasses.disabled]: disabled,
    [inputClasses.withEndAdornment]: true,
    [inputClasses.fullWidth]: fullWidth,
    [inputClasses.readOnly]: readOnly,
  });

  const elements = React.Children.toArray(children) as React.ReactElement[];
  const content = elements.map((el: React.ReactElement, i) => React.cloneElement(el, {
    ...el.props,
    key: `select-item-${i}`,
    selected: value === el.props.value,
    className: cn(classes.item, el.props.className),
    // eslint-disable-next-line
      onClick: (e: any): void => {
      e.stopPropagation();

      if (el.props.disabled) {
        return;
      }

      if (onChange) {
        onChange(el.props.value);
      }

      menuState.onClose();
    },
  }));
  const selectedElement = elements.find((el: React.ReactElement) => el.props.value === value);

  return (
    <div className={rootCN} onClick={disabled || readOnly ? undefined : menuState.show} {...other}>
      <div className={inputCN}>
        <Typography variant="caption" noWrap className={cn(classes.label, { [classes.emptyLabel]: !selectedElement })}>
          {selectedElement ? selectedElement.props.children : placeholder}
        </Typography>

        <div className={inputClasses.endAdornment}>
          <ChevronsIcon className={classes.icon} />
        </div>
      </div>

      <input
        name={name}
        className={classes.input}
        value={value}
        disabled={disabled}
        onChange={(): void => {}}
        required={required}
        readOnly={readOnly}
      />

      <Menu {...otherMenu} {...menuState} fullWidth className={cn(classes.menu, menuClassName)}>
        {content.length > 0 ? content : undefined}
      </Menu>

      {!!helperText && <FormHelperText color={overrideColor}>{helperText}</FormHelperText>}
    </div>
  );
};
