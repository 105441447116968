import React from 'react';
import { createUseStyles } from 'react-jss';
import { observer } from 'mobx-react';
import { useSearch } from '../../../../shared/utils/hooks';
import { SYSTEM_FORM_INITIAL_STATE } from '../../../../stores/form/system/defaults';
import { useAppStore } from '../../../../stores/root/root.context';
import { IPortalSystem, PortalSystemOrderBy } from '../../../../stores/system/types';
import { Order } from '../../../../stores/types';
import { SystemDialog } from '../../../components/dialog-systems/dialog-system.view';
import { Button } from '../../../components/ui/button';
import { useDialogState } from '../../../components/ui/dialog/dialog.hook';
import { AddIcon } from '../../../components/ui/icons/add';
import { SearchIcon } from '../../../components/ui/icons/search';
import { Menu } from '../../../components/ui/menu';
import { MenuItem } from '../../../components/ui/menu/menu-item.view';
import { useMenuState } from '../../../components/ui/menu/menu.hook';
import { onErrorSnackbar, snackbar } from '../../../components/ui/snackbar/snackbar.utils';
import { Stack } from '../../../components/ui/stack';
import { Table } from '../../../components/ui/table';
import { TablePagination, useTablePaginationState } from '../../../components/ui/table-pagination';
import { TextField } from '../../../components/ui/text-field';
import { IKnowledgeProps } from '../knowledge-base.models';
import { getSystemsListColumns } from './systems-list.columns';


const PREFIX = 'SystemsListPage';
const useStyles = createUseStyles(
  () => ({
    root: {},
    header: {
      flex: 1,
      marginTop: 24,
      width: '100%',
      marginBottom: 24,
    },
  }),
  { name: PREFIX },
);

export const SystemsListPage: React.FC<IKnowledgeProps> = observer((props) => {
  const { refetchQuestions, refetchSections } = props;
  const classes = useStyles();
  const { portal, systemForm: form } = useAppStore();

  const [selectedSystem, setSelectedSystem] = React.useState<IPortalSystem | undefined>();
  const systemDialog = useDialogState();
  const menuState = useMenuState();
  const pagination = useTablePaginationState(0, 10);
  const search = useSearch();


  const [createSystem] = portal.createSystem.useMutation({
    onError: onErrorSnackbar,
  });
  const [deleteSystem] = portal.deleteSystem.useMutation({
    onError: onErrorSnackbar,
  });
  const [updateSystem] = portal.updateSystem.useMutation({
    onError: onErrorSnackbar,
  });

  const { data, loading, refetch } = portal.systemList.useQuery({
    variables: {
      limit: pagination.rowsPerPage,
      offset: pagination.page * pagination.rowsPerPage,
      title: search.debounced,
      sort: '-status,-created_at',
    },
  });

  const handleDelete = (_id: string): void => {
    deleteSystem({ _id }).then((): void => {
      if (refetchQuestions && refetchSections) {
        refetchQuestions();
        refetchSections();
      }
      refetch();
      snackbar({ message: 'Система удалена!', severity: 'success' });
    });
  };
  const columns = getSystemsListColumns({
    onMore:
            (record) => (e): void => {
              e.stopPropagation();
              e.preventDefault();

              if (menuState.open) {
                menuState.onClose();
              }
              else {
                menuState.show(e);
                setSelectedSystem(record);
              }
            },
  });
  const handleSave = (): void => {
    const formValues = form.getArgs();
    if (!selectedSystem) {
      // @ts-ignore
      createSystem({ ...formValues }).then((): void => {
        refetch();
        snackbar({ message: 'Система создана!', severity: 'success' });
        systemDialog.close();
      });
    }
    else {
      updateSystem({
        ...formValues,
        _id: selectedSystem._id,
      }).then((): void => {
        refetch();
        snackbar({ message: 'Система обновлена!', severity: 'success' });
        systemDialog.close();
      });
    }
  };

  return (
    <div className={classes.root}>
      <Stack direction="row" justifyContent="space-between" className={classes.header}>
        <TextField
          style={{ maxWidth: 480 }}
          fullWidth
          placeholder="Поиск по названию"
          InputProps={{ endAdornment: <SearchIcon /> }}
          value={search.value}
          onChange={(e): void => {
            search.change(e.target.value);
          }}
        />
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={(): void => {
            form.reset(SYSTEM_FORM_INITIAL_STATE);
            setSelectedSystem(undefined);
            systemDialog.open();
          }}
        >
                    Добавить новую запись
        </Button>
      </Stack>

      <Table<IPortalSystem>
        columns={columns}
        loading={loading}
        rowKey="_id"
        data={data?.items}
        hover
        footer={(): JSX.Element => (
          <TablePagination
            total={data?.totalCount || 0}
            page={pagination.page}
            rowsPerPage={pagination.rowsPerPage}
            onChangePage={pagination.onChangePage}
            onChangeRowsPerPage={pagination.onChangeRowsPerPage}
          />
        )}
      />

      {selectedSystem && <Menu {...menuState.props} placement="bottom-right">
        <MenuItem
          onClick={(): void => {
            menuState.onClose();
            form.load(selectedSystem);
            systemDialog.open();
          }}
        >
                    Редактировать
        </MenuItem>
        <MenuItem
          onClick={(): void => {
            menuState.onClose();
            handleDelete(selectedSystem._id);
          }}
        >
                    Удалить
        </MenuItem>
      </Menu>}

      <SystemDialog {...systemDialog.props} onSubmit={handleSave} />

    </div>
  );
});
