import React from 'react';
import cn from 'classnames';
import Tooltip from 'rc-tooltip';
import { createUseStyles } from '../theme/createUseStyles';
import { TooltipProps } from './tooltip.models';
import 'rc-tooltip/assets/bootstrap_white.css';

const PREFIX = 'TooltipWindow';
const useStyles = createUseStyles(
  () => ({
    root: {
      opacity: 1,
      borderRadius: '8px',
      filter: 'drop-shadow(4px 4px 15px rgba(33, 36, 55, 0.12))',
      background: 'none',
      '& .rc-tooltip-content': {
        lineHeight: 1,
        borderRadius: '8px',
        border: 'none',
        boxSizing: 'border-box',
      },

      '& .rc-tooltip-inner': {
        padding: '10px',
        border: 'none',
        borderRadius: '8px',
        filter: 'drop-shadow(4px 4px 15px rgba(33, 36, 55, 0.12))',
        minHeight: 'auto',
        boxSizing: 'border-box',
        color: '#3B4168',
      },
    },
  }),
  { name: PREFIX },
);
export const TooltipWindow: React.FC<TooltipProps> = (props: TooltipProps) => {
  const { className, children, hasArrow, label, placement, hidden, ...other } = props;

  const classes = useStyles();
  const rootCN = cn(classes.root, className);

  return (
    hidden
      ? children
      : <Tooltip
        overlayClassName={rootCN}
        placement={placement}
        mouseLeaveDelay={0.2}
        showArrow={!!hasArrow}
        overlay={<span>{label}</span>}
      >
        {children}
      </Tooltip>
  );
};
