import React from 'react';
import { TableCellMoreBtn } from '../table-cell-more-btn';
import { TooltipWindow } from '../tooltip/tooltip.view';
import { Typography } from '../typography';
import { toHumanTextDate } from '../utils/format/date';
import { ITableCell } from './table.models';
import { copyId } from './table.utils';

// eslint-disable-next-line
export const tableCellMore = <T extends any>(onMore: (record: T) => (e: React.MouseEvent<HTMLElement>) => void): ITableCell<T> => ({
  title: '',
  width: 80,
  render: (_, record): JSX.Element => (
    <TableCellMoreBtn
      onClick={(e): void => {
        onMore(record)(e);
      }}
    />
  ),
});

// eslint-disable-next-line
export const tableCellDate = <T extends any>({
  getDate,
  key = 'created_at',
  ...opts
}: ITableCell<T> & { getDate?: (record: T) => Date }): ITableCell<T> => ({
  ...opts,
  dataIndex: key,
  key,
  width: 200,
  render: (value: Date, record) => <Typography noWrap>{toHumanTextDate((getDate && getDate(record)) || value)}</Typography>,
});

// eslint-disable-next-line
export const tableCellLink = <T extends any>({
  getLabel,
  getHref,
  noWrap = true,
  ...opts
}: Omit<ITableCell<T>, 'render'> & {
  getHref: (record: T) => string;
  getLabel?: (record: T) => React.ReactNode;
  noWrap?: boolean;
}): ITableCell<T> => ({
  ...opts,
  width: 200,
  render: (value: string, record: T) => (
    <Typography
      color="primary"
      component={(p): JSX.Element => <a {...p} target="_blank" rel="noreferrer" href={getHref(record)} />}
      noWrap={noWrap}
    >
      {(getLabel && getLabel(record)) || value || '-'}
    </Typography>
  ),
});

// eslint-disable-next-line
export const tableCellId = <T extends any>({
  getId,
  key = '_id',
  ...opts
}: Omit<ITableCell<T>, 'render'> & { getId?: (record: T) => string }): ITableCell<T> => ({
  title: 'ID',
  dataIndex: key,
  key,
  width: 245,
  ...opts,
  render: (value: string, record) => <TooltipWindow
    label="Скопировать"
    placement="bottom"
    children={
      <Typography
        onClick={(e): void => {
          e.stopPropagation();
          copyId((getId && getId(record)) || value || '-');
        }}
        style={{ width: 'fit-content' }}
      >
        {(getId && getId(record)) || value || '-'}
      </Typography>}
  />,
});

// eslint-disable-next-line
export const tableCellTitle = <T extends any>({
  noWrap = false,
  getTitle,
  key = 'title',
  ...opts
}: Omit<ITableCell<T>, 'render'> & {
  noWrap?: boolean;
  getTitle?: (record: T) => React.ReactNode;
}): ITableCell<T> => ({
  dataIndex: key,
  key,
  width: 300,
  ...opts,
  render: (value: string, record: T) => (
    <Typography noWrap={noWrap} style={{ wordBreak: noWrap ? undefined : 'break-word' }}>
      {(getTitle && getTitle(record)) || value || '-'}
    </Typography>
  ),
});
