import React from 'react';
import { createUseStyles } from 'react-jss';
import cn from 'classnames';
import { IconButtonProps } from './icon-button.models';

const PREFIX = 'IconButton';
const useStyles = createUseStyles(
  (theme) => ({
    root: {
      width: 32,
      height: 32,
      borderRadius: '100%',
      background: 'transparent',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      border: 'none',

      '& > svg': {
        width: 16,
        height: 16,
      },

      '&:hover': {
        backgroundColor: '#EBF6FF',
      },
    },
    disabled: {
      opacity: 0.5,
      cursor: 'default',

      '&:hover': {
        background: 'transparent',
      },
    },
    sizeLarge: {
      width: 48,
      height: 48,

      '& > svg': {
        width: 24,
        height: 24,
      },
    },
  }),
  { name: PREFIX },
);

export const IconButton: React.FC<IconButtonProps> = (exampleProps) => {
  const { className, disabled, onClick, type = 'button', size, ...other } = exampleProps;

  const classes = useStyles();
  const rootCN = cn(classes.root, { [classes.disabled]: disabled, [classes.sizeLarge]: size === 'large' }, className);

  return <button className={rootCN} onClick={disabled ? undefined : onClick} type={type} {...other} />;
};
