import React from 'react';
import cn from 'classnames';
import { createUseStyles } from '../theme/createUseStyles';
import { ContainerMaxWidth, ContainerProps } from './container.models';

const PREFIX = 'Container';

const useStyles = createUseStyles(
  (theme) => ({
    root: {
      width: '100%',
      padding: '50px 32px',
      margin: '0 auto',
      maxWidth: (opts: { maxWidth: ContainerMaxWidth }): number | string => (opts.maxWidth === 'none' ? '100%' : theme.breakpoints[opts.maxWidth]),

      [theme.breakpoints.down('lg')]: {
        padding: '0 16px',
      },
    },
    disableGutters: {
      padding: 0,
    },
  }),
  { name: PREFIX },
);

export const Container: React.FC<ContainerProps> = (paperProps) => {
  const { className, children, maxWidth = 'lg', disableGutters, ...other } = paperProps;

  const classes = useStyles({ maxWidth });
  const rootCN = cn(classes.root, { [classes.disableGutters]: disableGutters }, className);

  return (
    <div className={rootCN} {...other}>
      {children}
    </div>
  );
};
