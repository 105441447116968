import React, { FC } from 'react';
import { SvgIcon, SvgIconProps } from '../svg-icon';

export const InfoOutlinedIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10 13.5556V10V13.5556ZM10 6.44444H9.99111H10ZM2 10C2 8.94942 2.20693 7.90914 2.60896 6.93853C3.011 5.96793 3.60028 5.08602 4.34315 4.34315C5.08602 3.60028 5.96793 3.011 6.93853 2.60896C7.90914 2.20693 8.94942 2 10 2C11.0506 2 12.0909 2.20693 13.0615 2.60896C14.0321 3.011 14.914 3.60028 15.6569 4.34315C16.3997 5.08602 16.989 5.96793 17.391 6.93853C17.7931 7.90914 18 8.94942 18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84286 14.1566 2 12.1217 2 10Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
