import React from 'react';
import { createUseStyles } from 'react-jss';
import { Divider } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { useAppStore } from '../../../stores/root/root.context';
import { ControlBlock } from '../../lab/control-block';
import { Button } from '../ui/button';
import { Dialog, DialogFooter, DialogHeader } from '../ui/dialog';
import { Select, SelectItem } from '../ui/select';
import { Stack } from '../ui/stack';
import { TextField } from '../ui/text-field';
import { Typography } from '../ui/typography';
import { SectionDialogProps } from './dialog-section.models';


const PREFIX = 'DialogSectionEdit';
const useStyles = createUseStyles(
  () => ({
    root: {
      width: '992px',
    },
    cancelBtn: {
      justifyContent: 'center',
      height: '44px',
      fontSize: '16px',
    },
    submitBtn: {
      justifyContent: 'center',
      border: 'none',
      height: '44px',
      fontSize: '16px',
    },
  }),
  { name: PREFIX },
);

export const SectionDialog: React.FC<SectionDialogProps> = observer((props) => {
  const { className, onClose, onSubmit, ...other } = props;
  const { sectionForm: form, portal } = useAppStore();
  const section = form.getValues();
  const disabledModeration = !form.isValid;

  const { data, refetch } = portal.systemList.useQuery({ variables: {} });

  const classes = useStyles();
  const rootCN = cn(classes.root, className);

  const handleSave = (): void => {
    if (onSubmit) {
      onSubmit();
    }
  };

  React.useEffect(() => {
    refetch();
  }, [section.title]);

  return (
    <Dialog maxWidth="md" fullWidth color="primary" className={rootCN} onClose={onClose} {...other}>
      <DialogHeader onClose={onClose}>
        <Typography variant="h3" fontWeight="bold">
                    Добавить новую запись
        </Typography>
      </DialogHeader>

      <Divider style={{ marginTop: '20px', marginBottom: '28px' }} />

      <Stack direction="column" spacing={24}>
        <ControlBlock
          label="Введите наименование раздела"
          required
          control={
            <TextField
              fullWidth
              placeholder="Ввод текста"
              value={section.title}
              onChange={(e): void => form.setFieldValue('title', e.target.value)}
            />
          }
        />
        <ControlBlock
          label="Наименование системы"
          required
          control={
            <Select
              placeholder="Ничего не выбрано"
              fullWidth
              variant="outlined"
              // @ts-ignore
              value={section.system?._id ? section.system._id : section.system}
              onChange={(v): void => form.setFieldValue('system', v as string)}
            >
              {(data?.items || []).map((item) => (
                <SelectItem key={item._id} value={item._id}>{item.title}</SelectItem>
              ))}
            </Select>
          }
        />
      </Stack>

      <DialogFooter>
        <Stack direction="column" spacing={8}>
          <Divider style={{ marginTop: '32px', marginBottom: '24px' }} />
          <Stack justifyContent="flex-end" spacing={16}>
            <Button
              variant="outlined"
              className={classes.cancelBtn}
              onClick={onClose}
            >
                            Отменить
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.submitBtn}
              onClick={handleSave}
              disabled={disabledModeration}
            >
                            Сохранить
            </Button>
          </Stack>
        </Stack>
      </DialogFooter>
    </Dialog>
  );
});
