import { IPortalStore } from '..';
import { Order } from '../types';
import { convertSoftware } from './software.mappers';
import { IPortalSoftware, PortalSoftwareOrdereBy } from './types';

export type IPortalGetSoftwareArgs = { _id: string };
export type IPortalGetSoftwareRes<TDate = Date> = IPortalSoftware<TDate>;

export type IPortalFindSoftwareArgs = {
  limit?: number;
  offset?: number;
  sort?: string;
} & IPortalTotalSoftwareArgs;
export type IPortalFindSoftwareRes<TDate = Date> = {
  items: IPortalSoftware<TDate>[],
  totalCount: number,
};
export type IPortalTotalSoftwareArgs = {
  title?: string;
};

export type IPortalUpdateSoftwareArgs = Omit<IPortalSoftware,
| 'created_at'
| 'updated_at'
| 'status'
>;
export type IPortalUpdateSoftwareRes = void;

export type IPortalCreateSoftwareArgs = {
  title: string;
  description: string;
  logo: string;
  link: string;
};
export type IPortalCreateSoftwareRes<TDate = Date> = IPortalSoftware<TDate>;

export type IPortalDeleteSoftwareArgs = { _id: string };
export type IPortalDeleteSoftwareRes = void;

export type IPortalAcceptSoftwareArgs = { _id: string };
export type IPortalAcceptSoftwareRes = void;

export type IPortalDeclineSoftwareArgs = { _id: string };
export type IPortalDeclineSoftwareRes = void;

export class PortalSoftware {

  protected portal: IPortalStore;
  public uploadImageUrl: string;
  public uploadFileUrl: string;

  constructor(portal: IPortalStore) {
    this.portal = portal;
    this.uploadImageUrl = `${portal.baseURL}/upload/image`;
    this.uploadFileUrl = `${portal.baseURL}/upload/doc`;
  }

  public async get(args: IPortalGetSoftwareArgs): Promise<IPortalGetSoftwareRes> {
    const res = await this.portal.jsonRequest<IPortalGetSoftwareRes<string>>({
      method: 'POST',
      url: '/software/get',
      data: args,
    });

    return convertSoftware(res);
  }

  public async find(args: IPortalFindSoftwareArgs): Promise<IPortalFindSoftwareRes> {
    const res = await this.portal
      .jsonRequest<IPortalFindSoftwareRes<string>>({
      method: 'GET',
      url: 'software/all',
      data: args,
    });

    return {
      items: res.items.map(convertSoftware),
      totalCount: res.totalCount,
    };
  }

  public async update(args: IPortalUpdateSoftwareArgs): Promise<IPortalUpdateSoftwareRes> {
    await this.portal.textRequest({
      method: 'PATCH',
      url: '/software/update',
      data: args,
    });
  }

  public async create(args: IPortalCreateSoftwareArgs): Promise<IPortalCreateSoftwareRes> {
    const res = await this.portal
      .jsonRequest<IPortalCreateSoftwareRes<string>>({
      method: 'POST',
      url: '/software/create',
      data: args,
    });

    return convertSoftware(res);
  }

  public async delete(args: IPortalDeleteSoftwareArgs): Promise<IPortalDeleteSoftwareRes> {
    await this.portal.textRequest({
      method: 'DELETE',
      url: 'software/delete',
      data: args,
    });
  }

  public async accept(args: IPortalAcceptSoftwareArgs): Promise<IPortalAcceptSoftwareRes> {
    await this.portal.textRequest({
      method: 'PATCH',
      url: '/software/accept',
      data: args,
    });
  }

  public async decline(args: IPortalDeclineSoftwareArgs): Promise<IPortalDeclineSoftwareRes> {
    await this.portal.textRequest({
      method: 'PATCH',
      url: '/software/decline',
      data: args,
    });
  }

}
