import React from 'react';
import { createUseStyles } from 'react-jss';
import cn from 'classnames';
import { IconButton } from '../icon-button';
import { DeleteIcon } from '../icons/delete';
import { Upload, UploadProps } from '../upload';
import { UploadFileField } from './upload-file-field.view';
import { UploadFilePreview } from './upload-file-preview.view';
import { UploadFileProps } from './upload-file.models';

const PREFIX = 'UploadFile';
const useStyles = createUseStyles(
  (theme) => ({
    root: {
      backgroundClip: '#F5FBFF',
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
  { name: PREFIX },
);

export const UploadFile = <T extends Record<string, unknown> | undefined = undefined>(
  exampleProps: UploadFileProps<T>,
): JSX.Element => {
  const {
    className,
    multiple,
    action,
    authorization,
    onChange,
    value: valueInner,
    accept,
    onError,
    data,
    sizeLimit,
    error,
    readOnly,
    ...other
  } = exampleProps;

  const classes = useStyles();
  const rootCN = cn(classes.root, className);

  const [localValue, setLocalValue] = React.useState<string | undefined>(undefined);

  const value = valueInner || localValue;

  const uploaderProps: UploadProps<T> = {
    action,
    data,
    multiple,
    authorization,
    accept,
    sizeLimit,
    beforeUpload(file) {
      return file;
    },
    onStart: (file) => {},
    onChange(path) {
      if (onChange) {
        onChange((path as string[])[0]);
      }
      else {
        // @ts-ignore
        setLocalValue(path);
      }
    },
    onProgress(step, file) {},
    onError,
  };

  return (
    <div className={rootCN} {...other}>
      {value && (
        <UploadFilePreview
          value={value as string}
          endContent={
            readOnly ? undefined : <IconButton
              style={{ width: 24, height: 24 }}
              onClick={(): void => {
                if (onChange) {
                  onChange(undefined);
                }
                else {
                  setLocalValue(undefined);
                }
              }}
            >
              <DeleteIcon style={{ color: '#909EBB', width: 20, height: 20 }} />
            </IconButton>
          }
        />
      )}
      {!value && (
        <Upload {...uploaderProps} readOnly={readOnly}>
          <UploadFileField multiple={multiple} error={error} />
        </Upload>
      )}
    </div>
  );
};
