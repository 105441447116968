import React from 'react';
import cn from 'classnames';
import { IconButton } from '../icon-button';
import { MoreIcon } from '../icons/more';
import { createUseStyles } from '../theme/createUseStyles';
import { TableCellMoreBtnProps } from './table-cell-more-btn.models';

const PREFIX = 'TableCellMoreBtn';

const useStyles = createUseStyles(
  (theme) => ({
    root: {
      // width: 20,
      // height: 20,
      borderRadius: theme.shape.borderRadius,

      '& > svg': {
        transform: 'scale(1.8)',
      },

      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
  }),
  { name: PREFIX },
);

export const TableCellMoreBtn: React.FC<TableCellMoreBtnProps> = (props) => {
  const { className, onClick, ...other } = props;
  const classes = useStyles();

  const rootCN = cn(classes.root);

  return (
    <IconButton
      className={rootCN}
      {...other}
      onClick={(e): void => {
        e.stopPropagation();
        e.preventDefault();

        if (onClick) {
          onClick(e);
        }
      }}
    >
      <MoreIcon />
    </IconButton>
  );
};
