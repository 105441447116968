import React, { FC } from 'react';
import { SvgIcon, SvgIconProps } from '../svg-icon';

export const DeleteIcon: FC<SvgIconProps> = ({ style = {}, ...props }) => (
  <SvgIcon
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ padding: 3, ...style }}
    {...props}
  >
    <path
      d="M12.1667 17.3332H3.83333C2.91286 17.3332 2.16667 16.587 2.16667 15.6665V4.83317H0.5V3.1665H3.83333V2.33317C3.83333 1.4127 4.57953 0.666504 5.5 0.666504H10.5C11.4205 0.666504 12.1667 1.4127 12.1667 2.33317V3.1665H15.5V4.83317H13.8333V15.6665C13.8333 16.587 13.0871 17.3332 12.1667 17.3332ZM3.83333 4.83317V15.6665H12.1667V4.83317H3.83333ZM5.5 2.33317V3.1665H10.5V2.33317H5.5ZM10.5 13.9998H8.83333V6.49984H10.5V13.9998ZM7.16667 13.9998H5.5V6.49984H7.16667V13.9998Z"
      fill="currentColor"
    />
  </SvgIcon>
);
