import { IPortalTag } from '../tag/types';

export type IPortalCourse<TDate = Date> = {
  _id: string;

  title: string;
  tag: IPortalTag;
  status: IPortalCourseStatus;
  card_color: string;
  card_color_tag: string;
  slug: string;

  created_at: TDate;
  updated_at: TDate;
};

export type ICreateCourse = {
  title: string;
  tag: string;
  slug: string;
  card_color: string;
  card_color_tag: string;
};

export enum IPortalCourseStatus {
  published = 'published',
  draft = 'draft',
}

export enum PortalCourseOrderBy {
  title = 'title',
  status = 'status',
}
