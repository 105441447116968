import { createUseStyles } from 'react-jss';
import { observer } from 'mobx-react';
import { useAppStore } from '../../../stores/root/root.context';
import { ControlBlock } from '../control-block';
import { Button } from '../ui/button';
import { IconButton } from '../ui/icon-button';
import { AddIcon } from '../ui/icons/add';
import { CloseIcon } from '../ui/icons/close';
import { Select, SelectItem } from '../ui/select';
import { Stack } from '../ui/stack';
import { Typography } from '../ui/typography';

const PREFIX = 'DialogCreateActivityLinks';
const useStyles = createUseStyles(() => ({
  root: {
    maxHeight: 330,
    overflowY: 'auto',
    marginTop: 32,
  },
}), { name: PREFIX });

type DialogCreateActivityLinksProps = {
  readOnly?: boolean;
};

export const DialogCreateActivityLinks: React.FC<DialogCreateActivityLinksProps> = observer((props) => {
  const { readOnly } = props;
  const { activityForm: form, portal } = useAppStore();
  const classes = useStyles();

  const { data } = portal.activitylist.useQuery({ variables: {} });
  const activities = data?.items.filter((item) => item._id !== form.activityId);

  const similarActivities = form.getFieldValue('similar_activity') as string[];

  if (readOnly && similarActivities.length < 1) {
    return (
      <Typography style={{ marginTop: 32 }}>Похожие мероприятия отсутствуют.</Typography>
    );
  }

  return (
    <Stack
      className={classes.root}
      direction="column"
      spacing={24}
    >
      {similarActivities.map((link, index: number) => (
        <Stack key={`additional-link-${index}`} direction="row" spacing={4} alignItems="center">
          <ControlBlock
            label={`Ссылка #${index + 1}`}
            control={
              <Select
                placeholder="Выберите новость"
                fullWidth
                variant="outlined"
                value={link}
                onChange={(v): void => form.updateLink(index, v as string)}
              >
                {(activities || []).map((item) => (
                  <SelectItem key={item._id} value={item._id}>{item.title}</SelectItem>
                ))}
              </Select>
            }
          />
          {similarActivities.length > 1 &&
            <IconButton onClick={(): void => form.deleteLink(index)} style={{ marginBottom: 0, marginTop: 'auto' }}>
              <CloseIcon style={{ width: 24, height: 24, color: '#909EBB', marginBottom: 0, marginTop: 0 }} />
            </IconButton>
          }
        </Stack>
      ))}
      <Button
        startIcon={<AddIcon />}
        variant="outlined"
        color="primary"
        onClick={(): void => form.addLink()}
        style={{ justifyContent: 'center', border: 'none', color: '#0071CE', fontSize: '16px', marginTop: '4px' }}
      >
        добавить еще статью
      </Button>
    </Stack>
  );
});
