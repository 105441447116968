import { IPortalStore } from '..';
import { Order } from '../types';
import { convertCourse } from './course.mappers';
import { IPortalCourse, PortalCourseOrderBy } from './types';

export type IPortalGetCourseArgs = { _id: string };
export type IPortalGetCourseRes<TDate = Date> = IPortalCourse<TDate>;

export type IPortalFindCourseArgs = {
  limit?: number;
  offset?: number;
  sort?: string;
} & IPortalTotalCoursesArgs;
export type IPortalFindCourseRes<TDate = Date> = {
  items: IPortalCourse<TDate>[],
  totalCount: number,
};
export type IPortalTotalCoursesArgs = {
  title?: string;
};
type TCatalogCourse = {
  title: string;
  cardColor: string;
  cardColorTag: string;
  slug: string;
};
export type IPortalGetCatalogCoursesArgs = {};
export type IPortalGetCatalogCoursesRes = TCatalogCourse[];

export type IPortalUpdateCourseArgs = Omit<IPortalCourse,
| 'created_at'
| 'updated_at'
| 'status'
| 'tag'
> & {
  tag: string;
};
export type IPortalUpdateCourseRes = void;

export type IPortalCreateCourseArgs = {
  title: string;
  tag: string;
};
export type IPortalCreateCourseRes<TDate = Date> = IPortalCourse<TDate>;

export type IPortalDeleteCourseArgs = { _id: string };
export type IPortalDeleteCourseRes = void;

export type IPortalAcceptCourseArgs = { _id: string };
export type IPortalAcceptCourseRes = void;

export type IPortalDeclineCourseArgs = { _id: string };
export type IPortalDeclineCourseRes = void;

export class PortalCourse {

  protected portal: IPortalStore;

  constructor(portal: IPortalStore) {
    this.portal = portal;
  }

  public async get(args: IPortalGetCourseArgs): Promise<IPortalGetCourseRes> {
    const res = await this.portal.jsonRequest<IPortalGetCourseRes<string>>({
      method: 'POST',
      url: '/course/get',
      data: args,
    });
    return convertCourse(res);
  }

  public async find(args: IPortalFindCourseArgs): Promise<IPortalFindCourseRes> {
    const res = await this.portal
      .jsonRequest<IPortalFindCourseRes<string>>({
      method: 'GET',
      url: 'course/all',
      data: args,
    });

    return {
      items: res.items.map(convertCourse),
      totalCount: res.totalCount,
    };
  }

  public async getCourses(args: IPortalGetCatalogCoursesArgs): Promise<IPortalGetCatalogCoursesRes> {
    const res = await this.portal.jsonRequest<IPortalGetCatalogCoursesRes>({
      method: 'GET',
      url: 'course/get-courses',
      data: args,
    });

    return res;
  }

  public async update(args: IPortalUpdateCourseArgs): Promise<IPortalUpdateCourseRes> {
    await this.portal.textRequest({
      method: 'PATCH',
      url: '/course/update',
      data: args,
    });
  }

  public async create(args: IPortalCreateCourseArgs): Promise<IPortalCreateCourseRes> {
    const res = await this.portal
      .jsonRequest<IPortalCreateCourseRes<string>>({
      method: 'POST',
      url: '/course/create',
      data: args,
    });

    return convertCourse(res);
  }

  public async delete(args: IPortalDeleteCourseArgs): Promise<IPortalDeleteCourseRes> {
    await this.portal.textRequest({
      method: 'DELETE',
      url: 'course/delete',
      data: args,
    });
  }

  public async accept(args: IPortalAcceptCourseArgs): Promise<IPortalAcceptCourseRes> {
    await this.portal.textRequest({
      method: 'PATCH',
      url: '/course/accept',
      data: args,
    });
  }

  public async decline(args: IPortalDeclineCourseArgs): Promise<IPortalDeclineCourseRes> {
    await this.portal.textRequest({
      method: 'PATCH',
      url: '/course/decline',
      data: args,
    });
  }

}
