import React from 'react';
import { createUseStyles } from 'react-jss';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { useAppStore } from '../../../stores/root/root.context';
import { ControlBlock } from '../control-block';
import { Button } from '../ui/button';
import { Dialog, DialogFooter, DialogHeader } from '../ui/dialog';
import { Divider } from '../ui/divider';
import { Editor } from '../ui/editor';
import { Stack } from '../ui/stack';
import { Tab, TabPanel, Tabs, useTabs } from '../ui/tabs';
import { Typography } from '../ui/typography';
import { ActivityCreateProps } from './dialog-create-activity-models';
import { DialogCreateActivityFiles } from './dialog-create-activity.files';
import { DialogCreateActivityGeneral } from './dialog-create-activity.general';
import { DialogCreateActivityLinks } from './dialog-create-activity.links';
import { DialogCreateActivitySpeaker } from './dialog-create-activity.speaker';
import { DialogCreateActivityTags } from './dialog-create-activity.tags';


const PREFIX = 'DialogCreateActivity';
const useStyles = createUseStyles(
  () => ({
    root: {
      maxHeight: '684px',
      minHeight: '684px',
      maxWidth: '992px',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      position: 'relative',
      margin: '0 auto',
      overflowY: 'hidden',
    },
    footer: {
      marginTop: 24,
      position: 'absolute',
      bottom: 40,
      right: 40,
      width: 'calc(100% - 80px)',
    },
    tabPanel: {
      marginTop: '32px',
      fontSize: '16px',
    },
  }),
  { name: PREFIX },
);

export const DialogCreateActivity: React.FC<ActivityCreateProps> = observer((exampleProps) => {
  const { className, onClose, onSubmit, readOnly, ...other } = exampleProps;

  const classes = useStyles();
  const rootCN = cn(classes.root, className);

  const { activityForm: form } = useAppStore();
  const activity = form.getValues();
  const disabledModeration = !form.isValid;

  const tabs = useTabs('general');

  const handleSave = (): void => {
    if (onSubmit) {
      onSubmit();
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="none"
      color="primary"
      className={rootCN}
      onClose={(e): void => {
        if (onClose) {
          onClose(e);
        }

        form.reset();
      }}
      {...other}>
      <form
        onSubmit={(e): void => {
          e.stopPropagation();
          e.preventDefault();
          handleSave();
        }}
      >
        <DialogHeader onClose={onClose}>
          <Typography variant="h3" fontWeight="bold" style={{ marginBottom: 20 }}>
            {readOnly ? 'Просмотр мероприятия' : 'Добавить новую запись'}
          </Typography>
        </DialogHeader>

        <Divider />

        <Tabs value={tabs.value} onChange={tabs.onChange} style={{ width: '100%' }}>
          <Tab value="general" label="Общая информация" />
          <Tab value="description" label="Описание" />
          <Tab value="speakers" label="Спикеры" />
          <Tab value="tags" label="Теги" />
          <Tab value="files" label="Файлы" />
          <Tab value="links" label="Похожие мероприятия" />
        </Tabs>

        <TabPanel className={classes.tabPanel} current={tabs.value} value="general">
          <DialogCreateActivityGeneral readOnly={readOnly} />
        </TabPanel>

        <TabPanel className={classes.tabPanel} current={tabs.value} value="description">
          <Stack spacing={24} direction="column" style={{ maxHeight: '380px', overflow: 'auto', paddingRight: '20px' }}>
            <ControlBlock label="Текст-описание мероприятия" control={
              <Editor
                fullWidth
                required
                placeholder="Ввод текста"
                value={activity.full_description}
                onChange={(v):void => form.setFieldValue('full_description', v)}
                error={!!form.getFieldError('full_description')}
                helperText={form.getFieldError('full_description')}
                disabled={readOnly}
              />
            }
            />
          </Stack>
        </TabPanel>

        <TabPanel current={tabs.value} value="speakers">
          <DialogCreateActivitySpeaker readOnly={readOnly} />
        </TabPanel>

        <TabPanel current={tabs.value} value="tags">
          <DialogCreateActivityTags readOnly={readOnly} />
        </TabPanel>

        <TabPanel current={tabs.value} value="files">
          <DialogCreateActivityFiles readOnly={readOnly} />
        </TabPanel>

        <TabPanel current={tabs.value} value="links">
          <DialogCreateActivityLinks readOnly={readOnly} />
        </TabPanel>

        {!readOnly && <DialogFooter className={classes.footer}>
          <Stack direction="column" spacing={24}>
            <Divider />
            <Stack spacing={16} justifyContent="flex-end">
              <Button
                variant="outlined"
                color="gray"
                style={{ justifyContent: 'center', height: '44px', fontSize: '16px' }}
                onClick={(e): void => {
                  if (onClose) {
                    onClose(e);
                  }

                  form.reset();
                }}
              >
                Отменить
              </Button>
              <Button
                variant="gradient"
                color="primary"
                style={{ justifyContent: 'center', border: 'none', height: '44px', fontSize: '16px' }}
                type="submit"
                disabled={disabledModeration}
              >
                Сохранить
              </Button>
            </Stack>

          </Stack>
        </DialogFooter>}
      </form>
    </Dialog>
  );
});
