import React, { ButtonHTMLAttributes, FC, MouseEventHandler } from 'react';
import cn from 'classnames';
import { IDialogHeaderProps } from '../dialog/dialog.models';
import { IconButton } from '../icon-button';
import { CloseIcon } from '../icons/close';
// import './dialog-header.styles.scss';
import { createUseStyles } from '../theme/createUseStyles';

const PREFIX = 'DialogHeader';

const useStyles = createUseStyles(
  (theme) => ({
    root: {
      position: 'relative',
    },
    close: {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      top: -6,
      right: 0,
      width: '36px',
      height: '36px',
      padding: 0,
      cursor: 'pointer',
      color: 'rgb(156 163 175/ 1)',
      transitionDuration: '0.15s',
      transitionProperty:
        'color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color',
      transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
      fontFamily: 'inherit',
      fontSize: '100%',
      lineHeight: 'inherit',
      margin: 0,
      backgroundColor: 'initial',
      backgroundImage: 'none',
      border: 'none',

      '& > svg': {
        width: 32,
        height: 32,
      },

      '&:hover': {
        color: '#727783',
      },
    },
    icon: {},
    iconColorError: {
      color: theme.palette.error.main,
    },
    iconColorWarning: {
      color: theme.palette.warning.main,
    },
    iconColorPrimary: {
      color: theme.palette.primary.main,
    },
  }),
  { name: PREFIX },
);

export const DialogHeader: FC<IDialogHeaderProps> = (modalHeaderProps) => {
  const { className, onClose, children, color, closeProps = {}, ...other } = modalHeaderProps;

  const classes = useStyles();
  const modalHeaderCN = cn(classes.root, className);

  const handleClose: MouseEventHandler<HTMLButtonElement> = (event) => {
    if (typeof onClose === 'function') {
      onClose(event);
    }
  };

  const closeBtnProps: ButtonHTMLAttributes<HTMLButtonElement> = {
    ...closeProps,
    onClick: handleClose,
    children: closeProps?.children ?? <CloseIcon />,
    className: cn(closeProps?.className, classes.close),
  };

  const contentCN = cn(classes.icon, {
    [classes.iconColorError]: color === 'error',
    [classes.iconColorPrimary]: color === 'primary',
  });

  return (
    <div {...other} className={modalHeaderCN}>
      <div className={contentCN}>{children}</div>

      {onClose && <IconButton {...closeBtnProps} />}
    </div>
  );
};
