import React from 'react';
import { createUseStyles } from 'react-jss';
import cn from 'classnames';
import RcTable from 'rc-table';
import { DefaultRecordType } from 'rc-table/lib/interface';
import { CircularProgress } from '../circular-progress';
import { ITableProps } from './table.models';

const PREFIX = 'Table';
const useStyles = createUseStyles(
  (theme) => ({
    root: {
      width: '100%',
      maxWidth: '100%',

      '& table': {
        width: '100%',
        borderSpacing: 0,
      },
      '& tr': {
        '& td': {
          borderBottom: `solid 1px ${theme.palette.divider}`,
          padding: 16,
          color: theme.palette.text.primary,
          fontFamily: theme.typography.fontFamily,
          ...theme.typography.subtitle1,
        },
        '& th': {
          textAlign: 'left',
          padding: 16,
          borderBottom: `solid 1px ${theme.palette.divider}`,
          fontFamily: theme.typography.fontFamily,
          ...theme.typography.subtitle1,
          color: theme.palette.text.secondary,
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
      '& tbody tr:last-child': {
        '& td': {
          borderBottom: 'none',
        },
      },
    },
    hover: {
      '& tbody tr:not(.rc-table-placeholder)': {
        '&:hover': {
          '& td': {
            backgroundColor: theme.palette.action.hover,
            cursor: 'pointer',
          },
        },
      },
    },
    variantGrid: {
      borderRadius: theme.shape.borderRadius,
      border: `solid 1px ${theme.palette.divider}`,

      '& th': {
        borderRight: `solid 1px ${theme.palette.divider}`,
        '&:last-child': {
          borderRight: 'none',
        },
      },
      '& td': {
        borderRight: `solid 1px ${theme.palette.divider}`,
        '&:last-child': {
          borderRight: 'none',
        },
      },
    },
  }),
  { name: PREFIX },
);

// @ts-ignore
export const Table = function <T>(exampleProps: ITableProps<T>): JSX.Element {
  const {
    className,
    scroll = {},
    hover,
    loading,
    variant,
    data,
    columns,
    order,
    orderBy,
    onSort,
    ...other
  } = exampleProps;
  const classes = useStyles();
  const rootCN = cn(
    classes.root,
    { [classes.hover]: hover && !loading, [classes.variantGrid]: variant === 'grid' },
    className,
  );

  return (
    // @ts-ignore
    <RcTable<T>
      tableLayout="fixed"
      className={rootCN}
      data={data}
      columns={columns}
      scroll={false ? undefined : { x: 500, ...scroll }}
      emptyText={
        loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 100 }}>
            <CircularProgress style={{ width: 100, height: 100, margin: -20 }} />
          </div>
        ) : (
          'Нет данных'
        )
      }
      {...other}
    />
  );
};
