import { BaseFormStore } from '../../../shared/form';
import { IPortalUpdateEventArgs } from '../../event';
import { ICreateEvent, IPortalEvent } from '../../event/types';
import { EVENT_FORM_INITIAL_STATE } from './defaults';
import { eventSchema } from './schema';

export class EventFormStore extends BaseFormStore<ICreateEvent> {

  constructor() {
    super(EVENT_FORM_INITIAL_STATE, eventSchema);
  }

  public load(event: IPortalEvent): void {
    super.load({
      title: event.title,
      description: event.description,
      desktop_cover: event.desktop_cover,
      tablet_cover: event.tablet_cover,
      mobile_cover: event.mobile_cover,
    });
  }

  public getArgs(): Omit<IPortalUpdateEventArgs, '_id'> {
    const { title, ...state } = this.getValues();

    const args = {
      ...state,
      title: title || 'Новое событие',
    };

    return args;
  }

}
