export type IPortalSoftware<TDate = Date> = {
  _id: string;

  title: string;
  description: string;
  logo: string;
  link: string;
  status: IPortalSoftwareStatus;

  created_at: TDate;
  updated_at: TDate;
};

export type ICreateSoftware = {
  title: string;
  description: string;
  logo: string;
  link: string;
};

export enum IPortalSoftwareStatus {
  published = 'published',
  draft = 'draft',
}

export enum PortalSoftwareOrdereBy {
  title = 'title',
  status = 'status',
}
