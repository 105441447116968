import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '../svg-icon';

export const SaveIcon: FC<SvgIconProps> = ({ style = {}, ...props }) => (
  <SvgIcon
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ padding: 4, width: 21, height: 21, ...style }}
    {...props}
  >
    <path d="M0.75 2C0.75 1.30964 1.30964 0.75 2 0.75H10.0605C10.392 0.75 10.7099 0.881696 10.9443 1.11612L11.4709 0.589526L10.9443 1.11612L12.303 2.47477L12.8839 3.05566C13.1183 3.29008 13.25 3.60802 13.25 3.93954V12C13.25 12.6904 12.6904 13.25 12 13.25H2C1.30964 13.25 0.75 12.6904 0.75 12V2Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
    <path d="M3 13.0001V10.0001C3 9.44778 3.44772 9.00006 4 9.00006H10C10.5523 9.00006 11 9.44778 11 10.0001V13.0001" stroke="currentColor" stroke-width="1.5" />
    <path d="M5 0.000243723V2.00024C5 2.55253 5.44772 3.00024 6 3.00024H8C8.55228 3.00024 9 2.55253 9 2.00024V0.000243723" stroke="currentColor" stroke-width="1.5" />
  </SvgIcon>
);
