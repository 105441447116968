import React from 'react';
import { PageMessage } from '../../lab/page-message';
import { CircularProgress } from '../ui/circular-progress';
import { Typography } from '../ui/typography';

const iconSize = 32;

export const AuthRedirect: React.FC = () => (
  <PageMessage
    message={
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <CircularProgress width={iconSize} height={iconSize} />

        <Typography variant="h3" fontWeight="bold" align="center">
          Переход на страницу авторизации...
        </Typography>
      </div>
    }
  />
);
