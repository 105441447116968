import { yup } from '../../../shared/utils/yup';
import { IPortalNews } from '../../news/types';

const yupNews: Partial<Record<keyof IPortalNews, yup.BaseSchema>> = {
  title: yup.string().required(),
  date: yup.date().required(),
  desktop_cover: yup.string().required(),
  tablet_cover: yup.string().required(),
  mobile_cover: yup.string().required(),
  text_top: yup.string().required(),
};

export const newsSchema = yup.object({}).shape(yupNews);
