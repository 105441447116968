import { IPortalActivityStatus } from '../../stores/activity/types';
import { IPortalCourseStatus } from '../../stores/course/types';
import { IPortalEventStatus } from '../../stores/event/types';
import { IPortalNewsStatus } from '../../stores/news/types';
import { IPortalSoftwareStatus } from '../../stores/software/types';
import { IPortalTagStatus } from '../../stores/tag/types';


export const eventStatusToLocale = (value: IPortalEventStatus): string => ({
  [IPortalEventStatus.draft]: 'Черновик',
  [IPortalEventStatus.published]: 'Опубликован',
}[value]);

export const newsStatusToLocale = (value: IPortalNewsStatus): string => ({
  [IPortalNewsStatus.draft]: 'Черновик',
  [IPortalNewsStatus.published]: 'Опубликован',
}[value]);

export const activityStatusToLocale = (value: IPortalActivityStatus): string => ({
  [IPortalActivityStatus.draft]: 'Черновик',
  [IPortalActivityStatus.published]: 'Опубликован',
}[value]);

export const courseStatusToLocale = (value: IPortalCourseStatus): string => ({
  [IPortalCourseStatus.draft]: 'Черновик',
  [IPortalCourseStatus.published]: 'Опубликован',
}[value]);

export const tagStatusToLocale = (value: IPortalTagStatus): string => ({
  [IPortalTagStatus.draft]: 'Черновик',
  [IPortalTagStatus.published]: 'Опубликован',
}[value]);

export const softwareStatusToLocale = (value: IPortalSoftwareStatus): string => ({
  [IPortalSoftwareStatus.draft]: 'Черновик',
  [IPortalSoftwareStatus.published]: 'Опубликован',
}[value]);
