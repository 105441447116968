// eslint-disable-next-line max-classes-per-file
import { makeAutoObservable, runInAction, toJS } from 'mobx';
import * as yup from 'yup';
import { IFormState } from './types';

// eslint-disable-next-line @typescript-eslint/naming-convention
class FormStore<State extends IFormState> {

  protected readonly state: State;
  protected readonly errors: { [key: string]: string };
  protected readonly initialState: State;
  public isValid: boolean = true;
  public isChanged: boolean = false;
  public fieldsChange: { [key: string]: boolean };

  constructor(initialState: State) {
    this.state = initialState;
    this.initialState = { ...initialState };
    this.errors = {};
    this.fieldsChange = {};

    makeAutoObservable(this);
  }

  public setValues(values: State, updateInitial: boolean = false): void {
    runInAction(() => {
      Object.keys(values).map((key) => {
        this.state[key as keyof State] = values[key as keyof State];
        this.fieldsChange[key] = false;
      });

      if (updateInitial) {
        Object.keys(values).map((key) => {
          this.initialState[key as keyof State] = values[key as keyof State];
        });
      }

      this.isChanged = JSON.stringify(toJS(this.initialState)) !== JSON.stringify(toJS(this.state));
    });
  }

  public setErrors(errors: { [key: string]: string }): void {
    runInAction(() => {
      Object.keys(this.errors).map((key) => {
        delete this.errors[key];
      });

      Object.keys(errors).map((key) => {
        if (this.fieldsChange[key]) {
          this.errors[key] = errors[key];
        }
      });

      if (Object.keys(errors).length > 0) {
        this.isValid = false;
      }
      else {
        this.isValid = true;
      }
    });
  }

  public getValues(): State {
    return this.state;
  }

  public getErrors(): { [key: string]: string } {
    return this.errors;
  }

  public setFieldValue(key: keyof State, value: State[typeof key], deepKey?: string): void {
    runInAction((): void => {
      this.state[key as keyof State] = value;
      this.fieldsChange[deepKey || key as string] = true;

      this.isChanged = JSON.stringify(toJS(this.initialState)) !== JSON.stringify(toJS(this.state));
    });
  }

  public setFieldError(key: string, error: string): void {
    if (!this.fieldsChange[key]) {
      return;
    }

    this.errors[key] = error;
  }

  public getFieldValue(key: keyof State): State[typeof key] {
    return this.state[key];
  }

  public getFieldError(key: string): string {
    return this.errors[key];
  }

  public reset(initialState?: State): void {
    runInAction(() => {
      const newState = initialState || this.initialState;

      Object.keys(this.state).map((key) => {
        delete this.state[key as keyof State];
        this.fieldsChange[key] = false;
      });

      Object.keys(newState).map((key) => {
        this.state[key as keyof State] = newState[key as keyof State];
      });
    });
  }

}

// copy of FormStore
// eslint-disable-next-line @typescript-eslint/naming-convention
export class BaseFormStore<State extends IFormState> {

  private form: FormStore<State>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private schema: yup.ObjectSchema<any>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(initialState: State, schema: yup.ObjectSchema<any>) {
    this.form = new FormStore<State>(initialState);
    this.schema = schema;

    this.validate();
  }

  public load(state: State): void {
    return this.setValues(state, true);
  }

  public setValues(values: State, updateInitial: boolean = false): void {
    this.form.setValues(values, updateInitial);
    this.validate();
  }

  public getValues(): State {
    return this.form.getValues();
  }

  public getErrors(): { [key: string]: string } {
    return this.form.getErrors();
  }

  public setFieldValue(key: keyof State, value: State[typeof key], deepKey?: string): void {
    this.form.setFieldValue(key, value, deepKey);
    this.validate();
  }

  public getFieldValue(key: keyof State): State[typeof key] {
    return this.form.getFieldValue(key);
  }

  public getFieldError(key: string): string | undefined {
    return this.form.getFieldError(key);
  }

  protected validate(): void {
    const state = this.getValues();
    const errors: { [key: string]: string } = {};

    try {
      this.schema.validateSync(state, { recursive: true, abortEarly: false });
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    catch (e: any) {
      const yupErrors = (e as yup.ValidationError).inner;

      for (const yupError of yupErrors) {
        if (yupError.path) {
          errors[yupError.path] = yupError.message;
        }
      }
    }

    this.form.setErrors(errors);
  }

  public reset(initialState?: State): void {
    this.form.reset(initialState);
    this.validate();
  }

  public get isValid(): boolean {
    return this.form.isValid;
  }

  public get isChanged(): boolean {
    return this.form.isChanged;
  }

  public resetIsChanged(): void {
    this.form.isChanged = false;
  }

  public getFieldPropsCompact(key: keyof State): { value: State[typeof key]; checked?: boolean; onChange: (v: State[typeof key]) => void } {
    const value = this.getFieldValue(key);

    return {
      value,
      checked: typeof value === 'boolean' ? value : undefined,
      onChange: (v) => this.setFieldValue(key, v),
    };
  }

  public getFieldProps(
    key: keyof State,
  ): { value: State[typeof key]; checked?: boolean; onChange: (v: State[typeof key]) => void; error: boolean; helperText?: string } {
    const value = this.getFieldValue(key);
    const error = this.getFieldError(key as string);

    return {
      value,
      checked: typeof value === 'boolean' ? value : undefined,
      onChange: (v) => this.setFieldValue(key, v),
      error: !!error,
      helperText: error,
    };
  }

}
