import React, { FC } from 'react';
import { SvgIcon, SvgIconProps } from '../svg-icon';

export const AddIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.8327 10.8334V15.8334H9.16602V10.8334H4.16602V9.16669H9.16602V4.16669H10.8327V9.16669H15.8327V10.8334H10.8327Z"
      fill="currentColor"
    />
  </SvgIcon>
);
