import React from 'react';
import { doc } from 'prettier';
import { MenuProps } from './menu.models';

type MenuState<T> = {
  open: boolean;
  onClose: () => void;
  show: (e: React.MouseEvent<HTMLElement>, data?: T) => void;
  anchorEl?: HTMLElement;
  props: MenuProps;
  data: T;
};

// eslint-disable-next-line
export const useMenuState = <T extends any>(initialState: T = (undefined as any)): MenuState<T> => {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEL] = React.useState<HTMLElement | undefined>(undefined);
  const [state, setState] = React.useState<T>(initialState);

  const onClose = (): void => setOpen(false);

  return {
    open,
    anchorEl,
    onClose,
    data: state,
    show: (e, data?: T): void => {
      setOpen(true);
      setAnchorEL(e.currentTarget);

      if (data) {
        setState(data);
      }
      else if (initialState) {
        setState(initialState);
      }
    },
    props: {
      onClose,
      open,
      anchorEl,
    },
  };
};

export const useScrollHidden = (hidden: boolean): void => {
  React.useEffect(() => {
    if (typeof window === 'undefined') {
      return (): void => {};
    }

    const scrollWidth = window.innerWidth - document.body.offsetWidth;

    if (hidden) {
      document.body.style.overflowY = 'hidden';
      document.body.style.marginRight = '17px';
    }
    else {
      document.body.style.overflowY = 'auto';
      document.body.style.marginRight = '0px';
    }

    return (): void => {
      document.body.style.overflowY = 'auto';
    };
  }, [hidden]);
};
