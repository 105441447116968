import React from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import { BrowserRoute } from '../../../routes/browser.routes';
import { FolderIcon } from '../../components/ui/icons/folder';
import { SaveIcon } from '../../components/ui/icons/save';
import { Paper } from '../../components/ui/paper';
import { Stack } from '../../components/ui/stack';
import { Tab, TabPanel, Tabs, useTabs } from '../../components/ui/tabs';
import { Typography } from '../../components/ui/typography';
import { CourseListPage } from './course/course-list.view';
import { SoftwareListPage } from './software/software-list.view';

const PREFIX = 'AdvertisingBlock';
const useStyles = createUseStyles(
  () => ({
    root: {
      padding: 40,
      paddingTop: 30,
    },
    tabs: {
      width: '100%',
      padding: '8px 0px 0px 0',
    },
  }),
  { name: PREFIX },
);

export const AdvertisingBlock: React.FC = () => {
  const classes = useStyles();

  const tabs = useTabs(BrowserRoute.course);

  return (
    <Paper className={classes.root}>
      <div>
        <Typography variant="h2" fontWeight="bold">
          Управление рекламой каталога курсов и ПО
        </Typography>
        <Stack direction="column">
          <Tabs
            value={tabs.value}
            onChange={tabs.onChange}
            className={classes.tabs}
          >
            <Tab
              value={BrowserRoute.course}
              label="Курсы"
              startIcon={<FolderIcon />}
              component={(p): JSX.Element => <Link to={BrowserRoute.course} {...p} />}
            />
            <Tab
              value={BrowserRoute.software}
              label="Программное обеспечение"
              startIcon={<SaveIcon />}
              component={(p): JSX.Element => <Link to={BrowserRoute.software} {...p} />}
            />
          </Tabs>
        </Stack>

        <TabPanel current={tabs.value} value={BrowserRoute.course}>
          <CourseListPage />
        </TabPanel>
        <TabPanel current={tabs.value} value={BrowserRoute.software}>
          <SoftwareListPage />
        </TabPanel>
      </div>
    </Paper>
  );
};
