import React, { HTMLAttributes } from 'react';
import { createUseStyles } from 'react-jss';
import cn from 'classnames';
import { FileIcon } from '../icons/file';
import { Typography } from '../typography';
import { getFileName } from '../utils/format/file';

const PREFIX = 'UploadFilePreview';
const useStyles = createUseStyles(
  () => ({
    root: {
      padding: 16,
      background: '#F5FBFF',
      borderRadius: 4,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      overflow: 'hidden',
    },
    box: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
  { name: PREFIX },
);

export type UploadFilePreviewProps = HTMLAttributes<HTMLDivElement> & { value?: string; endContent?: React.ReactNode; };

export const UploadFilePreview: React.FC<UploadFilePreviewProps> = (exampleProps) => {
  const { className, value, endContent, ...other } = exampleProps;

  const classes = useStyles();
  const rootCN = cn(classes.root, className);

  return (
    <div className={rootCN} {...other}>
      <div className={classes.box}>
        <FileIcon color="primary" style={{ marginRight: 12 }} />
        <Typography color="primary">Файл загружен: {value ? getFileName(value) : '-'}</Typography>
      </div>
      {endContent}
    </div>
  );
};
