import React, { FC } from 'react';
import { SvgIcon, SvgIconProps } from '../svg-icon';

export const ArchiveIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.44444 10H11.5556M4.55556 7H15.4444H4.55556ZM4.55556 7C4.143 7 3.74733 6.84196 3.45561 6.56066C3.16389 6.27936 3 5.89782 3 5.5C3 5.10218 3.16389 4.72064 3.45561 4.43934C3.74733 4.15804 4.143 4 4.55556 4H15.4444C15.857 4 16.2527 4.15804 16.5444 4.43934C16.8361 4.72064 17 5.10218 17 5.5C17 5.89782 16.8361 6.27936 16.5444 6.56066C16.2527 6.84196 15.857 7 15.4444 7H4.55556ZM4.55556 7V14.5C4.55556 14.8978 4.71944 15.2794 5.01117 15.5607C5.30289 15.842 5.69855 16 6.11111 16H13.8889C14.3014 16 14.6971 15.842 14.9888 15.5607C15.2806 15.2794 15.4444 14.8978 15.4444 14.5V7H4.55556Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
