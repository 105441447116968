import { BaseFormStore } from '../../../shared/form';
import { IPortalUpdateSystemArgs } from '../../system';
import { ICreateSystem, IPortalSystem } from '../../system/types';
import { SYSTEM_FORM_INITIAL_STATE } from './defaults';
import { systemSchema } from './schema';

export class SystemFormStore extends BaseFormStore<ICreateSystem> {

  constructor() {
    super(SYSTEM_FORM_INITIAL_STATE, systemSchema);
  }

  public load(system: IPortalSystem): void {
    super.load({
      title: system.title,
    });
  }

  public getArgs(): Omit<IPortalUpdateSystemArgs, '_id'> {
    const { title, ...state } = this.getValues();
    const args = {
      ...state,
      title: title || 'Новое ПО',
    };

    return args;
  }

}
