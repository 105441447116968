export const getFileName = (str: string): string | undefined => {
  const file: string | undefined = str.split('/').at(-1);

  if (!file) {
    return undefined;
  }

  const name = file.split('.').at(0) as string;

  if (name.length > 26) {
    return `${name.substring(0, 26)}...`;
  }

  return name;
};
