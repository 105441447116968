import React from 'react';
import { createUseStyles } from 'react-jss';
import { observer } from 'mobx-react';
import { useSearch } from '../../../../../shared/utils/hooks';
import { useAppStore } from '../../../../../stores/root/root.context';
import { IPortalTag, IPortalTagStatus } from '../../../../../stores/tag/types';
import { TagDialog } from '../../../../components/dialog-tag/dialog-tag.view';
import { Button } from '../../../../components/ui/button';
import { useDialogState } from '../../../../components/ui/dialog/dialog.hook';
import { AddIcon } from '../../../../components/ui/icons/add';
import { SearchIcon } from '../../../../components/ui/icons/search';
import { Menu } from '../../../../components/ui/menu';
import { MenuItem } from '../../../../components/ui/menu/menu-item.view';
import { useMenuState } from '../../../../components/ui/menu/menu.hook';
import { onErrorSnackbar, snackbar } from '../../../../components/ui/snackbar/snackbar.utils';
import { Stack } from '../../../../components/ui/stack';
import { Table } from '../../../../components/ui/table';
import { TablePagination, useTablePaginationState } from '../../../../components/ui/table-pagination';
import { TextField } from '../../../../components/ui/text-field';
import { getTagListColumns } from './tags-list.columns';

const PREFIX = 'TagListPage';
const useStyles = createUseStyles(
  () => ({
    header: {
      flex: 1,
      marginTop: 24,
      width: '100%',
      marginBottom: 24,
    },
  }),
  { name: PREFIX },
);

export const TagsList: React.FC = observer(() => {
  const classes = useStyles();
  const menuState = useMenuState();
  const { portal, tagForm: form } = useAppStore();
  const [selectedTag, setSelectedTag] = React.useState<IPortalTag | undefined>();
  const search = useSearch();
  const pagination = useTablePaginationState(0, 10);
  const tagDialog = useDialogState();

  const columns = getTagListColumns({
    onMore:
      (record) => (e): void => {
        e.stopPropagation();
        e.preventDefault();

        if (menuState.open) {
          menuState.onClose();
        }
        else {
          menuState.show(e);
          setSelectedTag(record);
        }
      },
  });

  const { data, loading, refetch } = portal.tagList.useQuery({ variables: {
    limit: pagination.rowsPerPage,
    offset: pagination.page * pagination.rowsPerPage,
    title: search.debounced,
    sort: '-status,-created_at',
  } });
  const [createTag] = portal.createTag.useMutation({
    onError: onErrorSnackbar,
  });
  const [updateTag] = portal.updateTag.useMutation({
    onError: onErrorSnackbar,
  });
  const [deleteTag] = portal.deleteTag.useMutation({
    onError: onErrorSnackbar,
  });
  const [acceptTag] = portal.acceptTag.useMutation({
    onError: onErrorSnackbar,
  });
  const [declineTag] = portal.declineTag.useMutation({
    onError: onErrorSnackbar,
  });

  const handleSave = (): void => {
    const formValues = form.getArgs();
    if (!selectedTag) {
      createTag({ ...formValues }).then((): void => {
        refetch();
        snackbar({ message: 'Курс создан!', severity: 'success' });
        tagDialog.close();
      });
    }
    else {
      updateTag({
        ...formValues,
        _id: selectedTag._id,
      }).then((): void => {
        refetch();
        snackbar({ message: 'Курс обновлен!', severity: 'success' });
        tagDialog.close();
      });
    }
  };

  const handleDelete = (_id: string): void => {
    deleteTag({ _id }).then((): void => {
      refetch();
      snackbar({ message: 'Тэг удален!', severity: 'success' });
    });
  };

  const handleAccept = (_id: string): void => {
    acceptTag({ _id }).then((): void => {
      refetch();
      snackbar({ message: 'Тэг опубликован', severity: 'success' });
    });
  };

  const handleDecline = (_id: string): void => {
    declineTag({ _id }).then((): void => {
      refetch();
      snackbar({ message: 'Тэг снят с публикации', severity: 'success' });
    });
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between" className={classes.header}>
        <TextField
          style={{ maxWidth: 480 }}
          fullWidth
          placeholder="Поиск по названию"
          InputProps={{ endAdornment: <SearchIcon /> }}
          value={search.value}
          onChange={(e): void => {
            search.change(e.target.value);
          }}
        />

        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={(): void => {
            setSelectedTag(undefined);
            tagDialog.open();
          }}
        >
          Добавить новый тэг
        </Button>
      </Stack>
      <Table<IPortalTag>
        columns={columns}
        loading={loading}
        data={data?.items}
        rowKey="_id"
        footer={(): JSX.Element => (
          <TablePagination
            total={data?.totalCount || 0}
            page={pagination.page}
            rowsPerPage={pagination.rowsPerPage}
            onChangePage={pagination.onChangePage}
            onChangeRowsPerPage={pagination.onChangeRowsPerPage}
          />
        )}
      />

      {selectedTag && <Menu {...menuState.props} placement="bottom-right">
        {selectedTag.status === IPortalTagStatus.published &&
          <MenuItem
            onClick={(): void => {
              menuState.onClose();
              handleDecline(selectedTag._id);
            }}
          >
          Снять с публикации
          </MenuItem>}
        {selectedTag.status === IPortalTagStatus.draft &&
          <MenuItem
            onClick={(): void => {
              menuState.onClose();
              form.load(selectedTag);
              tagDialog.open();
            }}
          >
          Редактировать
          </MenuItem>}
        {selectedTag.status === IPortalTagStatus.draft &&
          <MenuItem
            onClick={(): void => {
              menuState.onClose();
              handleAccept(selectedTag._id);
            }}
          >
          Опубликовать
          </MenuItem>}
        {selectedTag.status === IPortalTagStatus.draft &&
          <MenuItem
            onClick={(): void => {
              menuState.onClose();
              handleDelete(selectedTag._id);
            }}
          >
            Удалить
          </MenuItem>}
      </Menu>}

      <TagDialog {...tagDialog.props} onSubmit={handleSave} />
    </>
  );
});
