import React from 'react';
import { createUseStyles } from 'react-jss';
import cn from 'classnames';
import { CircularProgress } from '../circular-progress';
import { IconButton } from '../icon-button';
import { DeleteIcon } from '../icons/delete';
import { ImageIcon } from '../icons/image';
import { PaperClipIcon } from '../icons/paper-clip';
import { Stack } from '../stack';
import { Typography } from '../typography';
import { Upload } from '../upload';
import { getFileName } from '../utils/format/file';
import { UploadRowProps } from './upload-row.models';

const PREFIX = 'UploadRow';
const useStyles = createUseStyles(
  (theme) => ({
    root: {},
    uploadWrapper: {
      display: 'flex',
      gap: '10px',
      wordBreak: 'break-word',
      alignItems: 'center',
    },
    upload: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      color: theme.palette.primary.main,

      '&:hover': {
        cursor: 'pointer',
        textDecoration: 'underline',
      },
    },
  }),
  { name: PREFIX },
);

export const UploadRow = <T extends Record<string, unknown> | undefined = undefined>(
  exampleProps: UploadRowProps<T>,
): JSX.Element => {
  const {
    className,
    title,
    subtitle,
    name,
    value,
    onChange,
    authorization,
    action,
    required,
    sizeLimit,
    accept,
    onError,
    data,
    multiple,
    onDelete,
    disabled,
    readOnly,
    ...other
  } = exampleProps;

  const classes = useStyles();
  const rootCN = cn(classes.root, className);

  const [loading, setLoading] = React.useState(false);

  return (
    <div className={rootCN} {...other}>
      <Stack direction="row" spacing={24} alignItems="center" style={{ flexWrap: 'wrap' }}>
        <Stack direction="column" style={{ flex: 1 }}>
          <Typography>{title}</Typography>
          <Typography color="textSecondary" variant="caption">
            {subtitle}
          </Typography>
        </Stack>

        {!value && (
          <Upload
            data={data}
            action={action}
            authorization={authorization}
            onChange={(v): void => {
              if (onChange) {
                onChange(v);
              }
            }}
            // onError={(err, ret): void => {
            //   setLoading(false);

            //   if (onError) {
            //     onError(err, ret);
            //   }
            // }}
            onSuccess={(): void => {
              setLoading(false);
            }}
            onStart={(): void => {
              setLoading(true);
            }}
            required={required}
            sizeLimit={sizeLimit}
            accept={accept}
            multiple={multiple}
            disabled={disabled}
            readOnly={readOnly}
          >
            <div>
              {!loading && !readOnly && (
                <Stack className={classes.upload} direction="row" spacing={8} alignItems="center">
                  <PaperClipIcon style={{ width: 20, height: 20 }} />
                  <Typography component="span" color="primary">
                      Прикрепить
                  </Typography>
                </Stack>
              )}
              {loading && <CircularProgress style={{ width: 48, height: 48 }} />}
            </div>
          </Upload>
        )}
        {value && (
          <div className={classes.uploadWrapper}>
            <div className={classes.upload}>
              <ImageIcon style={{ width: 20, height: 20 }} />
              <Typography
                color="primary"
                component={(p): JSX.Element => <a href={value} target="_blank" rel="noreferrer" {...p} />}
              >
                {typeof value === 'string' ? getFileName(value) : '-'}
              </Typography>
            </div>

            {!disabled && !readOnly && <IconButton
              onClick={(): void => {
                if (onDelete) {
                  onDelete();
                  return;
                }

                if (onChange) {
                  onChange([]);
                }
              }}
            >
              <DeleteIcon style={{ color: '#909EBB', width: 20, height: 20 }} />
            </IconButton>}
          </div>
        )}
      </Stack>
    </div>
  );
};
