import React from 'react';
import RcUpload, { UploadProps as RcUploadProps } from 'rc-upload';
import { RcFile, BeforeUploadFileType } from 'rc-upload/lib/interface';
import { IOverrideProps } from '../types';

export type IUploadExtendProps = Extract<
keyof UploadProps,
| 'onChange'
| 'onError'
| 'sizeLimit'
| 'accept'
| 'authorization'
| 'data'
| 'value'
| 'action'
| 'headers'
| 'readOnly'
>;
export type UploadProps<T extends Record<string, unknown> | undefined = undefined> = IOverrideProps<
RcUploadProps,
{
  onChange?: (path: string | undefined | string[]) => void;
  onError?: (err: Error, ret?: Record<string, unknown>, file?: RcFile) => void;
  sizeLimit?: number;
  accept?: string;
  authorization?: string;
  data?: T;
}
>;

export const Upload = <T extends Record<string, unknown> | undefined = undefined>(
  props: UploadProps<T>,
): JSX.Element => {
  const {
    onChange,
    onError,
    children,
    value,
    onSuccess,
    sizeLimit,
    accept,
    authorization,
    headers = {},
    data,
    disabled,
    readOnly,
    ...other
  } = props;

  const child = React.isValidElement(children) && React.cloneElement(children, { ...children.props, src: value, disabled });

  const isFileValid = (file: RcFile): boolean => {
    if (sizeLimit && file.size / 1024 > sizeLimit) {
      if (onError) {
        onError(
          new Error(
            `Файл должен быть размером не более ${sizeLimit >= 1024 ? `${sizeLimit / 1024}МБ` : `${sizeLimit}кБ`}`,
          ),
        );
      }
      return false;
    }

    if (accept && !accept.includes(file.name.split('.').pop() || '')) {
      if (onError) {
        onError(new Error(`Файл должен быть формата ${accept}`));
      }
      return false;
    }

    return true;
  };

  return (
    <RcUpload
      method="post"
      multipart
      accept={accept}
      data={data}
      // action={config.imagesStorage}
      disabled={disabled || readOnly}
      readOnly={readOnly}
      headers={{ ...headers, authorization: authorization || headers?.authorization }}
      {...other}
      onError={(err, ret, file): void => {
        if (onError) {
          onError(err, ret, file);
        }
      }}
      beforeUpload={(file, arr): BeforeUploadFileType => isFileValid(file)}
      onSuccess={(response, file, xhr): void => {
        if (onChange) {
          onChange(response as unknown as string);
        }
        if (onSuccess) {
          onSuccess(response, file, xhr);
        }
      }}
    >
      {child}
    </RcUpload>
  );
};
