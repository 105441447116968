// eslint-disable-next-line
import React from 'react';
import { AxiosRequestConfig, AxiosError } from 'axios';
import { makeAutoObservable, runInAction } from 'mobx';


export interface IRequestOptions extends Omit<AxiosRequestConfig, 'responseType'> {}
export interface ISdkEmptyRequestOptions extends Omit<IRequestOptions, 'url' | 'method' | 'data' | 'params'> {}
export interface ISdkRequestOptions<T> extends ISdkEmptyRequestOptions {
  data: T;
}

export interface ICommonStore<TData> {
  data: TData | undefined;
  loading: boolean;
  error: string | undefined;
  date?: Date;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IQueryStore<Res, Args> {
  load(args: Args): Promise<Res | undefined>;
  useQuery: (opts: { variables: Args }) => ICommonStore<Res> & {
    refetch: () => Promise<Res | undefined>;
  };
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IMutationStore<Res, Args> {
  load(args: Args): Promise<Res>;
  useMutation: () => readonly [(args: Args) => Promise<Res>, ICommonStore<Res>];
}

// eslint-disable-next-line
export const EMPTY_STATE: ICommonStore<any> = { data: undefined, loading: false, error: undefined };

export const getErrorMessage = (error: unknown): string => {
  if (error instanceof AxiosError) {
    try {
      const str = error.response?.data;

      if (str) {
        const res = JSON.parse(str);

        if (typeof res.message === 'string') {
          return res.message;
        }
        if (Array.isArray(res.message)) {
          return res.message.join(', ');
        }
      }
    }
    catch (e) {
      //
    }
  }

  if (error instanceof Error) {
    return error.message;
  }
  return String(error);
};


// eslint-disable-next-line
export const getCacheKey = (args: any): string => {
  return JSON.stringify(args);
};

// eslint-disable-next-line
export class QueryStore<Res = {}, Args = {}> {
  protected readonly fetch: (args: Args) => Promise<Res>;
  protected readonly state: { [key: string]: ICommonStore<Res> } = {};

  constructor(fetch: (args: Args) => Promise<Res>) {
    this.fetch = fetch;

    makeAutoObservable(this);
  }

  protected createIfEmpty(cacheKey: string): ICommonStore<Res> {
    if (!this.state[cacheKey]) {
      runInAction(() => {
        this.state[cacheKey] = {
          data: undefined,
          loading: true,
          error: undefined,
        };
      });
    }

    return this.state[cacheKey];
  }

  public async load(args: Args, useCache: boolean = true): Promise<Res | undefined> {
    const cacheKey = getCacheKey(args);

    if (useCache) {
      const v = this.state[cacheKey];
      const now = new Date();

      if (v && v.date && (now.getTime() - v.date.getTime()) < 1000 * 60) {
        return;
      }
    }

    this.createIfEmpty(cacheKey);
    this.state[cacheKey].date = new Date();

    try {
      const res: Res = await this.fetch(args);

      runInAction(() => {
        this.state[cacheKey].data = res;
        this.state[cacheKey].loading = false;
      });

      // eslint-disable-next-line
      return res;
    }
    catch (e) {
      runInAction(() => {
        this.state[cacheKey].error = getErrorMessage(e);
        this.state[cacheKey].loading = false;
      });

      // eslint-disable-next-line
      return undefined;
    }
  }

  public revalidate(): void {
    runInAction(() => {
      // eslint-disable-next-line
      for (const k in this.state) {
        this.state[k].date = undefined;
      }
    });
  }

  public reset(): void {
    runInAction(() => {
      // eslint-disable-next-line
      for (const k in this.state) {
        delete this.state[k];
      }
    });
  }

  protected getState = (key: string): ICommonStore<Res> => {
    const state = this.state[key];

    if (!state) {
      return this.createIfEmpty(key);
    }

    return state;
  };

  // eslint-disable-next-line
  public useQuery = (opts: { variables: Args; skip?: boolean }) => {
    const cacheKey = React.useMemo(() => getCacheKey(opts.variables), [opts.variables]);
    const state = this.getState(cacheKey);

    React.useEffect(() => {
      if (!opts.skip) {
        this.load(opts.variables).then().catch();
      }
    }, [cacheKey, opts.skip]);

    return {
      refetch: (): Promise<Res | undefined> => this.load(opts.variables, false),
      data: state.data,
      loading: state.loading,
      error: state.error,
    } as const;
  };

}

// eslint-disable-next-line
export class MutationStore<Res = {}, Args = {}> {
  protected readonly fetch: (args: Args) => Promise<Res>;
  protected readonly state: { [key: string]: ICommonStore<Res> } = {};

  constructor(fetch: (args: Args) => Promise<Res>) {
    this.fetch = fetch;

    makeAutoObservable(this);
  }

  public async load(args: Args): Promise<Res> {
    const cacheKey = getCacheKey(args);

    this.state[cacheKey] = {
      data: undefined,
      loading: true,
      error: undefined,
    };


    try {
      const res: Res = await this.fetch(args);

      runInAction(() => {
        this.state[cacheKey].data = res;
        this.state[cacheKey].loading = false;
      });

      return res;
    }
    catch (e) {
      const errMessage = getErrorMessage(e);

      runInAction(() => {
        this.state[cacheKey].error = errMessage;
        this.state[cacheKey].loading = false;
      });

      throw e;
    }
  }

  protected getState = (key: string): ICommonStore<Res> => this.state[key] || EMPTY_STATE;

  // eslint-disable-next-line
  public useMutation = (opts: { onError?: (err: Error) => void } = {}) => {
    const [cacheKey, setCacheKey] = React.useState('');
    const state = this.getState(cacheKey);

    return [
      (args: Args): Promise<Res | void> => {
        setCacheKey(getCacheKey(args));

        return this.load(args)
          .catch((err: Error) => {
            err.message = getErrorMessage(err);

            if (opts.onError) {
              opts.onError(err);
            }

            throw err;
          });
      },
      state,
    ] as const;
  };

}
