import React, { FC } from 'react';
import { SvgIcon, SvgIconProps } from '../svg-icon';

export const DoubleRightIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.3447 15.0083L9.16551 13.8308L12.9988 9.9975L9.16551 6.16417L10.3447 4.99167L15.353 10L10.3455 15.0083H10.3447ZM5.82384 15.0083L4.64551 13.8308L8.47884 9.9975L4.64551 6.17L5.82384 4.99167L10.833 10L5.82467 15.0083H5.82384Z"
      fill="currentColor"
    />
  </SvgIcon>
);
