import { IPortalStore } from '../index';
import { Order } from '../types';
import { convertQuestion } from './question.mappers';
import { IPortalQuestion, IPortalQuestionOrderBy } from './types';

export type IPortalGetQuestionArgs = { id: string };
export type IPortalGetQuestionRes<TDate = Date> = IPortalQuestion<TDate>;

export type IPortalFindQuestionArgs = {
  limit?: number;
  offset?: number;
  sort?: string;
  title?: string;
};
export type IPortalFindQuestionRes<TDate = Date> = {
  items: IPortalQuestion<TDate>[],
  totalCount: number;
};


export type IPortalUpdateQuestionArgs = Omit<
IPortalQuestion,
| 'created_at'
| 'updated_at'
>;

export type IPortalUpdateQuestionRes = void;

export type IPortalCreateQuestionArgs = Omit<
IPortalQuestion,
| '_id'
| 'created_at'
| 'updated_at'
>;
export type IPortalCreateQuestionRes<TDate = Date> = IPortalQuestion<TDate>;

export type IPortalDeleteQuestionArgs = { _id: string };
export type IPortalDeleteQuestionRes = void;

export class PortalQuestion {

  protected portal: IPortalStore;
  public uploadImageUrl: string;

  constructor(portal: IPortalStore) {
    this.portal = portal;
    this.uploadImageUrl = `${portal.baseURL}/upload/image`;
  }


  public async find(args: IPortalFindQuestionArgs): Promise<IPortalFindQuestionRes> {
    const res = await this.portal
      .jsonRequest<IPortalFindQuestionRes<string>>({
      method: 'GET',
      url: '/question/all',
      data: args,
    });

    return {
      items: res.items.map(convertQuestion),
      totalCount: res.totalCount,
    };
  }


  public async update(args: IPortalUpdateQuestionArgs): Promise<IPortalUpdateQuestionRes> {
    await this.portal.textRequest({
      method: 'PATCH',
      url: '/question/update',
      data: args,
    });
  }

  public async create(args: IPortalCreateQuestionArgs): Promise<IPortalCreateQuestionRes> {
    const res = await this.portal
      .jsonRequest<IPortalCreateQuestionRes<string>>({
      method: 'POST',
      url: '/question/create',
      data: args,
    });

    return convertQuestion(res);
  }

  public async delete(args: IPortalDeleteQuestionArgs): Promise<IPortalDeleteQuestionRes> {
    await this.portal.textRequest({
      method: 'DELETE',
      url: `/question/delete/${args._id}`,
      data: args,
    });
  }

}
