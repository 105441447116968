import React, { FC } from 'react';
import { SvgIcon, SvgIconProps } from '../svg-icon';

export const CalendarIcon: FC<SvgIconProps> = ({ style = {}, ...props }) => (
  <SvgIcon
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ paddingLeft: 4, paddingTop: 4, ...style }}
    {...props}
  >
    <path
      d="M12.6667 14.6666H3.33333C2.59695 14.6666 2 14.0697 2 13.3333V3.99998C2 3.2636 2.59695 2.66665 3.33333 2.66665H4.66667V1.33331H6V2.66665H10V1.33331H11.3333V2.66665H12.6667C13.403 2.66665 14 3.2636 14 3.99998V13.3333C14 14.0697 13.403 14.6666 12.6667 14.6666ZM3.33333 6.66665V13.3333H12.6667V6.66665H3.33333ZM3.33333 3.99998V5.33331H12.6667V3.99998H3.33333ZM11.3333 12H10V10.6666H11.3333V12ZM8.66667 12H7.33333V10.6666H8.66667V12ZM6 12H4.66667V10.6666H6V12ZM11.3333 9.33331H10V7.99998H11.3333V9.33331ZM8.66667 9.33331H7.33333V7.99998H8.66667V9.33331ZM6 9.33331H4.66667V7.99998H6V9.33331Z"
      fill="currentColor"
    />
  </SvgIcon>
);
