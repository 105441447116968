export type IPortalTag<TDate = Date> = {
  _id: string;

  title: string;
  status: IPortalTagStatus;
  created_at: TDate;
  updated_at: TDate;
};

export enum IPortalTagStatus {
  published = 'published',
  draft = 'draft',
}

export type ICreateTag = {
  title: string;
};
