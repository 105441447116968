import React from 'react';
import cn from 'classnames';
import { createUseStyles } from '../theme/createUseStyles';
import { TabProps } from './tab.models';

const PREFIX = 'Tab';

const useStyles = createUseStyles(
  (theme) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      cursor: 'pointer',
      padding: '10px 12px',
      paddingTop: 16,
      color: theme.palette.text.primary,
      borderBottom: 'solid 2px transparent',
      zIndex: 1,
      fontFamily: theme.typography.fontFamily,
      ...theme.typography.h5,
      whiteSpace: 'nowrap',

      '& > svg': {
        width: 16,
        height: 16,
      },
    },
    selected: {
      color: theme.palette.primary.main,
      borderBottom: 'solid 2px rgb(0 113 206 / 1)',
    },
  }),
  { name: PREFIX },
);

export const Tab: React.FC<TabProps> = (props) => {
  const { className, selected, value, label, component = 'a', endIcon, startIcon, ...other } = props;
  const classes = useStyles();

  const rootCN = cn(classes.root, className, {
    [classes.selected]: selected,
  });

  return React.createElement(component, {
    ...other,
    className: rootCN,
    children: (
      <>
        {startIcon}
        {label}
        {endIcon}
      </>
    ),
  });
};
