export type IPortalActivity<TDate = Date> = {
  _id: string;

  title: string;
  start_date?: TDate | null;
  end_date?: TDate | null;
  desktop_cover: string;
  tablet_cover: string;
  mobile_cover: string;
  place: string;
  price: number;
  price_visible: boolean;
  link_to_entry: string;
  is_main: boolean;
  short_description: string;
  full_description: string;
  speakers?: IActivitySpeaker[] | undefined;
  tags: string[];
  files: string[];
  similar_activity: IPortalActivity[];
  status: IPortalActivityStatus;

  created_at: TDate;
  updated_at: TDate;
};

export type ActivityFormState = {
  title: string;
  start_date?: Date | null;
  end_date?: Date | null;
  desktop_cover: string;
  tablet_cover: string;
  mobile_cover: string;
  place: string;
  price: number;
  price_visible: boolean;
  link_to_entry: string;
  is_main: boolean;
  short_description: string;
  full_description: string;
  speakers: IActivitySpeaker[] | undefined;
  tags: string[];
  files: string[];
  similar_activity: string[];
};

export type IActivitySpeaker = {
  fullname?: string;
  appointment?: string;
  photo_id?: string;
};

export enum IPortalActivityStatus {
  published = 'published',
  draft = 'draft',
}

export enum IPortalActivityOrderBy {
  title = 'title',
  status = 'status',
}
