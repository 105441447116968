import { IAppTheme, IAppThemeOptions } from './types';

export const createTheme = (opts: IAppThemeOptions): IAppTheme => ({
  ...opts,
  breakpoints: {
    down: (key): string => `@media screen and (max-width: ${opts.breakpoints[key]}px)`,
    up: (key): string => `@media screen and (min-width: ${opts.breakpoints[key]}px)`,
    between: (from, to): string => `@media screen and (min-width: ${opts.breakpoints[from]}px) and (max-width: ${opts.breakpoints[to]}px)`,
    values: Object.values(opts.breakpoints).sort((a, b): number => (a > b ? 1 : -1)),
    keys: Object.keys(opts.breakpoints),
    ...opts.breakpoints,
  },
  spacing: (value, second): string => {
    const first = `${opts.spacing * value}px`;

    if (typeof second === 'undefined') {
      return first;
    }

    return `${first} ${opts.spacing * second}px`;
  },
});
