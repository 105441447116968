import React from 'react';
import { toast } from 'react-hot-toast';
import { Alert, AlertSeverity } from '../alert';
import { SnackbarProps } from './snackbar.models';

export const onErrorSnackbar = (error: unknown): void => {
  let message: string | undefined = undefined;

  if (error instanceof Error) {
    message = error.message;
  }
  else {
    message = String(error);
  }

  snackbar({ message, severity: 'error' });
};

type SbackbarOpts = { message: React.ReactNode; severity?: AlertSeverity };

export const snackbar = (opts: SbackbarOpts): void => {
  toast(
    <Alert severity={opts.severity} size="small">
      {opts.message}
    </Alert>,
  );
};
snackbar.success = (message: string): void => snackbar({ severity: 'success', message });
snackbar.info = (message: string): void => snackbar({ severity: 'info', message });
snackbar.warning = (message: string): void => snackbar({ severity: 'warning', message });
snackbar.error = (message: string): void => snackbar({ severity: 'error', message });
