import React from 'react';
import { TablePaginationProps } from './table-pagination.models';

type IUseTablePaginationState = Pick<
TablePaginationProps,
'page' | 'rowsPerPage' | 'onChangePage' | 'onChangeRowsPerPage'
> & { reset: () => void };

export const useTablePaginationState = (
  defaultPage: number = 0,
  defaultRowsPerPage: number = 10,
): IUseTablePaginationState => {
  const [page, setPage] = React.useState(defaultPage);
  const [rowsPerPage, setRowsPerPage] = React.useState(defaultRowsPerPage);

  return {
    page,
    rowsPerPage,
    onChangePage: setPage,
    onChangeRowsPerPage: setRowsPerPage,
    reset: () => setPage(0),
  };
};
