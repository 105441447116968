import { createUseStyles } from 'react-jss';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { useAppStore } from '../../../stores/root/root.context';
import { ControlBlock } from '../control-block';
import { DateField } from '../ui/date-field';
import { Divider } from '../ui/divider';
import { Stack } from '../ui/stack';
import { TextField } from '../ui/text-field';
import { UploadRow } from '../ui/upload-row';

const PREFIX = 'DialogCreateNewsGeneral';
const useStyles = createUseStyles(() => ({
  root: {
    height: '100%',
    overflowY: 'auto',
    paddingRight: '20px',
  },
  editContent: {
    maxHeight: '380px',
  },
}), { name: PREFIX });

type DialogCreateNewsGeneralProps = {
  readOnly?: boolean;
};


export const DialogCreateNewsGeneral:React.FC<DialogCreateNewsGeneralProps> = observer((props) => {
  const { readOnly } = props;
  const { portal, auth, newsForm: form } = useAppStore();
  const news = form.getValues();
  const classes = useStyles();
  const className = readOnly ? classes.root : cn(classes.root, classes.editContent);

  return (
    <Stack
      className={className}
      direction="column"
      spacing={12}
      divider={<Divider />}
    >
      <Stack direction="row" spacing={24}>
        <ControlBlock
          label="Название"
          required
          style={{ flex: 2 }}
          control={
            <TextField
              fullWidth
              placeholder="Укажите название новости"
              value={news.title}
              onChange={(e): void => form.setFieldValue('title', e.target.value)}
              readOnly={readOnly}
            />
          }
        />
        <ControlBlock
          label="Дата новости"
          required
          control={
            <DateField
              style={{ maxWidth: '240px' }}
              placeholder="Введите дату новости"
              value={news.date}
              onChange={(v): void => form.setFieldValue('date', v)}
              readOnly={readOnly}
            />
          }
        />
      </Stack>

      <Stack direction="column" spacing={24} divider={<Divider />}>
        <ControlBlock
          label="Название"
          required
          style={{ flex: 2 }}
          control={
            <TextField
              fullWidth
              placeholder="Введите краткое описание новости"
              value={news.short_description}
              onChange={(e): void => form.setFieldValue('short_description', e.target.value)}
              readOnly={readOnly}
            />
          }
        />

        <UploadRow
          action={portal.portal.news.uploadImageUrl}
          authorization={auth.token}
          title="Обложка для десктоп *"
          subtitle="Загрузите баннер размером 1020х437px, максимальный вес 5мб"
          sizeLimit={5000}
          accept=".png,.jpg"
          value={news.desktop_cover}
          onChange={(v): void => form.setFieldValue('desktop_cover', (v as string[])[0])}
          readOnly={readOnly}
        />
        <UploadRow
          action={portal.portal.news.uploadImageUrl}
          authorization={auth.token}
          title="Обложка для планшета *"
          subtitle="Загрузите баннер размером 904х387px, максимальный вес 5мб"
          sizeLimit={5000}
          accept=".png,.jpg"
          value={news.tablet_cover}
          onChange={(v): void => form.setFieldValue('tablet_cover', (v as string[])[0])}
          readOnly={readOnly}
        />
        <UploadRow
          action={portal.portal.news.uploadImageUrl}
          authorization={auth.token}
          title="Обложка для мобильной версии *"
          subtitle="Загрузите баннер размером 335х214px, максимальный вес 5мб"
          sizeLimit={5000}
          accept=".png,.jpg"
          value={news.mobile_cover}
          onChange={(v): void => form.setFieldValue('mobile_cover', (v as string[])[0])}
          readOnly={readOnly}
        />

      </Stack>
    </Stack>
  );
});
