import React from 'react';
import cn from 'classnames';
import { createUseStyles } from '../theme/createUseStyles';
import { RadioProps } from './radio.models';

const PREFIX = 'Radio';

const useStyles = createUseStyles(
  (theme) => ({
    root: {
      width: 16,
      height: 16,
      position: 'relative',
    },
    input: { opacity: 0,
      width: 0,
      height: 0,
      top: 0,
      left: 0,
      position: 'absolute' },
    preview: {
      cursor: 'pointer',
      width: 16,
      height: 16,
      border: 'solid #E4E7F2 1px',
      borderRadius: '100%',
      padding: 1,

      '&:hover': {
        border: `solid ${theme.palette.primary.main} 1px`,
      },
      '& > div': {
        borderRadius: '100%',
      },
    },
    checked: {
      '&$preview': {
        border: `solid ${theme.palette.primary.main} 1px`,
        '& > div': {
          width: 12,
          height: 12,
          background: theme.palette.primary.main,
        },
      },
    },

    disabled: {
      '&$preview': {
        cursor: 'default',
        background: theme.palette.action.disabledBackground,
        border: `1px solid ${theme.palette.action.disabled}`,

        '&:hover': {
          border: `1px solid ${theme.palette.action.disabled}`,
        },
        '&$checked': {
          border: `1px solid ${theme.palette.action.disabled}`,

          '& > div': {
            background: theme.palette.action.disabled,
          },
        },
      },
    },
  }),
  { name: PREFIX },
);

export const Radio: React.FC<RadioProps> = (radioProps) => {
  const { className, checked, onChange, name, defaultChecked, disabled, value, readOnly, ...other } = radioProps;

  const classes = useStyles({});

  const rootCN = cn(classes.root, classes.root, className);
  const previewCN = cn(classes.preview, classes.preview, { [classes.checked]: checked, [classes.disabled]: disabled });
  const inputCN = cn(classes.input, classes.input);

  return (
    <label className={rootCN} {...other}>
      <input
        name={name}
        type="radio"
        className={inputCN}
        value={value}
        checked={checked}
        defaultChecked={defaultChecked}
        onChange={onChange}
        disabled={disabled}
        readOnly={readOnly}
      />
      <div className={previewCN}>
        <div />
      </div>
    </label>
  );
};
