import React from 'react';
import { ColumnsType } from 'rc-table/lib/interface';
import { softwareStatusToLocale } from '../../../../shared/utils/format';
import { toHumanDate } from '../../../../shared/utils/human-date';
import { IPortalSoftware, IPortalSoftwareStatus } from '../../../../stores/software/types';
import { tableCellMore } from '../../../components/ui/table/table.columns';
import { Typography } from '../../../components/ui/typography';

export const getSoftwareListColumns = (opts: {
  onMore: (software: IPortalSoftware) => (e: React.MouseEvent<HTMLElement>) => void;
}): ColumnsType<IPortalSoftware> => {
  const columns: ColumnsType<IPortalSoftware> = [
    {
      title: 'Название',
      width: 200,
      render: (value, record) => <Typography style={{ wordWrap: 'break-word' }}>{record.title}</Typography>,
    },
    {
      title: 'Дата создания/обновления',
      width: 200,
      render: (value, record) => <Typography>{toHumanDate(record.created_at)}</Typography>,
    },
    {
      title: 'Статус',
      width: 200,
      render: (value, record) => <Typography style={{ color: record.status === IPortalSoftwareStatus.published ? '#73D071' : '#BDBDBD' }}>{softwareStatusToLocale(record.status)}</Typography>,
    },
    tableCellMore(opts.onMore),
  ];

  return columns;
};
