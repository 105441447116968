export type IPortalNews<TDate = Date> = {
  _id: string;

  title: string;
  short_description: string;
  text_top: string;
  text_bottom: string;
  images: string[];
  images_description: string;
  date?: TDate;
  desktop_cover: string;
  tablet_cover: string;
  mobile_cover: string;
  similar_news: string[];
  status: IPortalNewsStatus;

  is_deleted?: boolean;

  created_at: TDate;
  updated_at: TDate;
};

export type ICreateNews<TDate = Date> = {
  title: string;
  short_description: string;
  text_top: string;
  text_bottom: string;
  images: string[];
  images_description: string;
  date?: TDate,
  desktop_cover: string;
  tablet_cover: string;
  mobile_cover: string;
  similar_news: string[];
};

export type INews = {
  _id: string;

  title: string;
  text_top: string;
  text_bottom: string;
  images_description: string;
  images: string[];
  date?: Date;
  desktop_cover: string;
  tablet_cover: string;
  mobile_cover: string;
  similar_news: string[];
  status: IPortalNewsStatus;
};

export enum IPortalNewsStatus {
  published = 'published',
  draft = 'draft',
}

export enum IPortalNewsOrderBy {
  title = 'title',
  status = 'status',
}
