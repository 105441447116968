import React from 'react';
import { createUseStyles } from 'react-jss';
import { observer } from 'mobx-react';
import { useSearch } from '../../../shared/utils/hooks';
import { IPortalActivity } from '../../../stores/activity/types';
import { ACTIVITY_FORM_INITIAL_STATE } from '../../../stores/form/activity/defaults';
import { useAppStore } from '../../../stores/root/root.context';
import { DialogCreateActivity } from '../../components/dialog-create-activity/dialog-create-activity.view';
import { Button } from '../../components/ui/button';
import { useDialogState } from '../../components/ui/dialog/dialog.hook';
import { AddIcon } from '../../components/ui/icons/add';
import { SearchIcon } from '../../components/ui/icons/search';
import { Menu } from '../../components/ui/menu';
import { MenuItem } from '../../components/ui/menu/menu-item.view';
import { useMenuState } from '../../components/ui/menu/menu.hook';
import { onErrorSnackbar, snackbar } from '../../components/ui/snackbar/snackbar.utils';
import { Stack } from '../../components/ui/stack';
import { Table } from '../../components/ui/table';
import { TablePagination, useTablePaginationState } from '../../components/ui/table-pagination';
import { TextField } from '../../components/ui/text-field';
import { getActivityListColumns } from './activity-list.collumns';

const PREFIX = 'OrganizationEventListPage';
const useStyles = createUseStyles(
  () => ({
    root: {
      width: '100%',
      marginBottom: 16,
      display: 'flex',
      justifyContent: 'space-between',
      padding: 0,
    },
  }),
  { name: PREFIX },
);

export const ActivityListPage: React.FC = observer(() => {
  const { portal, activityForm: form } = useAppStore();
  const classes = useStyles();

  const menuState = useMenuState();
  const createActivityDialog = useDialogState();
  const [selectedActivity, setSelectedActivity] = React.useState<IPortalActivity | undefined>();
  const [readOnly, setReadOnly] = React.useState<boolean>(false);

  const search = useSearch();
  // const [orderBy, setOrderBy] = React.useState();
  const pagination = useTablePaginationState(0, 10);

  const [createActivity] = portal.createActivity.useMutation({ onError: onErrorSnackbar });
  const [deleteActivity] = portal.deleteActivity.useMutation({ onError: onErrorSnackbar });
  const [acceptActivity] = portal.acceptActivity.useMutation({ onError: onErrorSnackbar });
  const [declineActivity] = portal.declineActivity.useMutation({ onError: onErrorSnackbar });
  const [updateActivity] = portal.updateActivity.useMutation({ onError: onErrorSnackbar });

  const { data, loading, refetch } = portal.activitylist.useQuery({
    variables: {
      limit: pagination.rowsPerPage,
      offset: pagination.page * pagination.rowsPerPage,
      title: search.debounced,
      sort: '-status,-created_at',
    },
  });

  const handleActivityView = (): void => {
    menuState.onClose();

    if (selectedActivity) {
      form.load(selectedActivity);
      createActivityDialog.open();
      setReadOnly(true);
    }
  };

  const handleActivityEdit = (): void => {
    menuState.onClose();

    if (selectedActivity) {
      form.load(selectedActivity);
      createActivityDialog.open();
      setReadOnly(false);
    }
  };

  const handleSave = (): void => {
    const formValues = form.getArgs();

    if (Array.isArray(formValues.speakers)) {
      const filteredSpeakers = formValues.speakers.filter((speaker) => Object.values(speaker).some((value) => !!value));
      if (filteredSpeakers.length === 0) {
        delete formValues.speakers;
      }
      else {
        formValues.speakers = filteredSpeakers;
      }
    }
    if (!selectedActivity) {
      createActivity({ ...formValues }).then((): void => {
        refetch();
        snackbar({ message: 'Меропрятие создано!', severity: 'success' });
        createActivityDialog.close();
        form.reset();
      });
    }
    else {
      updateActivity({
        ...formValues,
        _id: selectedActivity._id,
      }).then((): void => {
        refetch();
        snackbar({ message: 'Мероприятие обновлено!', severity: 'success' });
        createActivityDialog.close();
        form.reset();
      });
    }
  };

  const handleDelete = (_id: string): void => {
    deleteActivity({ _id }).then((): void => {
      refetch();
      snackbar({ message: 'Мероприятие удалено!', severity: 'success' });
    });
  };

  const handleAccept = (_id: string): void => {
    acceptActivity({ _id }).then((): void => {
      refetch();
      snackbar({ message: 'Мероприятие опубликовано', severity: 'success' });
    });
  };

  const handleDecline = (_id: string): void => {
    declineActivity({ _id }).then((): void => {
      refetch();
      snackbar({ message: 'Мероприятие снято с публикации', severity: 'success' });
    });
  };

  const columns = getActivityListColumns({
    onMore:
            (record) => (e): void => {
              e.stopPropagation();
              e.preventDefault();

              if (menuState.open) {
                menuState.onClose();
              }
              else {
                menuState.show(e);
                setSelectedActivity(record);
              }
            },
  });

  return (
    <>
      <div className={classes.root}>
        <Stack direction="row" spacing={16} style={{ flex: 1 }}>
          <TextField
            style={{ maxWidth: 330 }}
            fullWidth
            placeholder="Поиск по названию"
            InputProps={{ endAdornment: <SearchIcon /> }}
            value={search.value}
            onChange={(e): void => {
              search.change(e.target.value);
            }}
          />
        </Stack>

        <Button
          onClick={(): void => {
            form.reset(ACTIVITY_FORM_INITIAL_STATE);
            setSelectedActivity(undefined);
            createActivityDialog.open();
            setReadOnly(false);
          }}
          variant="outlined"
          startIcon={<AddIcon />}
        >
            Добавить новую запись
        </Button>
      </div>

      <Table<IPortalActivity>
        columns={columns}
        loading={loading}
        rowKey="_id"
        data={data?.items}
        hover
        footer={(): JSX.Element => (
          <TablePagination
            style={{ marginTop: 16 }}
            total={data?.totalCount || 0}
            page={pagination.page}
            rowsPerPage={pagination.rowsPerPage}
            onChangePage={pagination.onChangePage}
            onChangeRowsPerPage={pagination.onChangeRowsPerPage}
          />
        )}
      />

      {selectedActivity &&
      <Menu {...menuState.props} placement="bottom-right">
        {selectedActivity.status === 'published' &&
              <MenuItem
                onClick={handleActivityView}
              >
                Посмотреть
              </MenuItem>}
        {selectedActivity.status === 'published' &&
              <MenuItem
                onClick={(): void => {
                  menuState.onClose();
                  handleDecline(selectedActivity._id);
                }}
              >
                Снять с публикации
              </MenuItem>}
        {selectedActivity.status === 'draft' &&
              <MenuItem
                onClick={handleActivityEdit}
              >
                Редактировать
              </MenuItem>}
        {selectedActivity.status === 'draft' &&
              <MenuItem
                onClick={(): void => {
                  menuState.onClose();
                  handleAccept(selectedActivity._id);
                }}
              >
                Опубликовать
              </MenuItem>}
        {selectedActivity.status === 'draft' &&
              <MenuItem
                onClick={(): void => {
                  menuState.onClose();
                  handleDelete(selectedActivity._id);
                }}
              >
                Удалить
              </MenuItem>}
      </Menu>}

      <DialogCreateActivity {...createActivityDialog.props} readOnly={readOnly} onSubmit={handleSave} />

      <Stack justifyContent="center">
        <Button variant="outlined">Показать еще</Button>
      </Stack>

    </>
  );
});
