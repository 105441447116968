import { IPortalStore } from '..';
import { Order } from '../types';
import { convertNews } from './news.mappers';
import { IPortalNews, IPortalNewsOrderBy } from './types';

export type IPortalGetNewsArgs = { _id: string };
export type IPortalGetNewsRes<TDate = Date> = IPortalNews<TDate>;

export type IPortalFindNewsArgs = {
  limit?: number;
  offset?: number;
  sort?: string;
} & IPortalTotalNewsArgs;
export type IPortalFindNewsRes<TDate = Date> = {
  items: IPortalNews<TDate>[],
  totalCount: number,
};

export type IPortalTotalNewsArgs = {
  title?: string;
};
export type IPortalTotalNewsRes = number;

export type IPortalUpdateNewsArgs = Omit<IPortalNews,
| 'created_at'
| 'updated_at'
| 'status'>;
export type IPortalUpdateNewsRes = void;

export type IPortalCreateNewsArgs = Omit<
IPortalNews,
| '_id'
| 'created_at'
| 'updated_at'
| 'status'
>;
export type IPortalCreateNewsRes<TDate = Date> = IPortalNews<TDate>;

export type IPortalDeleteNewsArgs = { _id: string };
export type IPortalDeleteNewsRes = void;

export type IPortalNewsAcceptArgs = { _id: string };
export type IPortalNewsAcceptRes = void;

export type IPortalNewsDeclineArgs = { _id: string };
export type IPortalNewsDeclineRes = void;

export type IPortalUploadFilesArgs = FormData;
export type IPortalUploadFilesRes = string[];

export class PortalNews {

  protected portal: IPortalStore;
  public uploadImageUrl: string;

  constructor(portal: IPortalStore) {
    this.portal = portal;
    this.uploadImageUrl = `${portal.baseURL}/upload/image`;
  }

  public async uploadFiles(args: IPortalUploadFilesArgs): Promise<IPortalUploadFilesRes> {
    const res = await this.portal
      .jsonRequest<IPortalUploadFilesRes>({
      method: 'POST',
      url: '/upload/create',
      data: args,
    });

    return res;
  }

  public async get(args: IPortalGetNewsArgs): Promise<IPortalGetNewsRes> {
    const res = await this.portal
      .jsonRequest<IPortalGetNewsRes<string>>({
      method: 'GET',
      url: '/news/get',
      data: args,
    });

    return convertNews(res);
  }

  public async current(): Promise<IPortalGetNewsRes> {
    const res = await this.portal
      .jsonRequest<IPortalGetNewsRes<string>>({
      method: 'POST',
      url: '/news/current',
      data: {},
    });

    return convertNews(res);
  }

  public async find(args: IPortalFindNewsArgs): Promise<IPortalFindNewsRes> {
    const res = await this.portal
      .jsonRequest<IPortalFindNewsRes<string>>({
      method: 'GET',
      url: '/news/all',
      data: args,
    });

    return {
      items: res.items.map(convertNews),
      totalCount: res.totalCount,
    };
  }

  public total(args: IPortalTotalNewsArgs): Promise<IPortalTotalNewsRes> {
    return this.portal.jsonRequest<IPortalTotalNewsRes>({
      method: 'POST',
      url: '/news/find/count',
      data: args,
    });
  }

  public async update(args: IPortalUpdateNewsArgs): Promise<IPortalUpdateNewsRes> {
    await this.portal.textRequest({
      method: 'PATCH',
      url: '/news/update',
      data: args,
    });
  }

  public async create(args: IPortalCreateNewsArgs): Promise<IPortalCreateNewsRes> {
    const res = await this.portal
      .jsonRequest<IPortalCreateNewsRes<string>>({
      method: 'POST',
      url: '/news/create',
      data: args,
    });

    return convertNews(res);
  }

  public async delete(args: IPortalDeleteNewsArgs): Promise<IPortalDeleteNewsRes> {
    await this.portal.textRequest({
      method: 'DELETE',
      url: '/news/delete',
      data: args,
    });
  }

  public async newsAccept(args: IPortalNewsAcceptArgs): Promise<IPortalNewsAcceptRes> {
    await this.portal.textRequest({
      method: 'PATCH',
      url: '/news/accept',
      data: args,
    });
  }

  public async newsDecline(args: IPortalNewsDeclineArgs): Promise<IPortalNewsDeclineRes> {
    await this.portal.textRequest({
      method: 'PATCH',
      url: '/news/decline',
      data: args,
    });
  }

}
