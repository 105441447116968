import React from 'react';
import { createUseStyles } from 'react-jss';
import { Divider } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { useAppStore } from '../../../stores/root/root.context';
import { IPortalTagStatus } from '../../../stores/tag/types';
import { ControlBlock } from '../../lab/control-block';
import { Button } from '../ui/button';
import { Dialog, DialogFooter, DialogHeader } from '../ui/dialog';
import { Select, SelectItem } from '../ui/select';
import { Stack } from '../ui/stack';
import { Typography } from '../ui/typography';
import { CourseDialogProps } from './dialog-course.models';

const PREFIX = 'DialogCourseEdit';
const useStyles = createUseStyles(
  () => ({
    root: {},
    cancelBtn: {
      justifyContent: 'center',
      height: '44px',
      fontSize: '16px',
    },
    submitBtn: {
      justifyContent: 'center',
      border: 'none',
      height: '44px',
      fontSize: '16px',
    },
  }),
  { name: PREFIX },
);


type TCatalogCourse = {
  title: string;
  cardColor: string;
  cardColorTag: string;
  slug: string;
};


export const CourseDialog: React.FC<CourseDialogProps> = observer((props) => {
  const { className, onClose, onSubmit, readOnly, ...other } = props;
  const { portal, courseForm: form } = useAppStore();
  const classes = useStyles();
  const rootCN = cn(classes.root, className);

  const { data: catalogCourses = [] } = portal.catalogCourses.useQuery({ variables: {} });
  const { data: tags } = portal.tagList.useQuery({ variables: {} });

  const course = form.getValues();
  const acceptedTags = tags?.items.filter((tag) => tag.status === IPortalTagStatus.published) || [];
  const courseTag = acceptedTags.find((tag) => tag._id === course.tag);
  const disabledModeration = !form.isValid;

  const handleSave = (): void => {
    if (onSubmit) {
      onSubmit();
    }
  };

  const handleGetData = (value: string): void => {
    const findCourse: TCatalogCourse | undefined = catalogCourses?.find((item: TCatalogCourse) => item.title === value);

    if (findCourse) {
      form.setFieldValue('title', findCourse?.title);
      form.setFieldValue('card_color', findCourse?.cardColor);
      form.setFieldValue('card_color_tag', findCourse?.cardColorTag);
      form.setFieldValue('slug', findCourse?.slug);
    }
  };

  return (
    <Dialog color="primary" className={rootCN} onClose={onClose} {...other}>
      <DialogHeader onClose={onClose}>
        <Typography variant="h3" fontWeight="bold">
          Добавить новую запись
        </Typography>
      </DialogHeader>

      <Divider style={{ marginTop: '20px', marginBottom: '28px' }} />

      <Stack direction="column" spacing={24}>
        <ControlBlock
          label="Курс"
          control={
            <Select
              variant="outlined"
              fullWidth
              value={course.title}
              onChange={(e): void => handleGetData(e)}
              placeholder="Ничего не выбрано"
              readOnly={readOnly}
            >
              {catalogCourses.map((item, index: number) => (
                <SelectItem value={item.title} key={index}>
                  {item.title}
                </SelectItem>
              ))}
            </Select>
          }
        />
        <ControlBlock
          label="Тэг"
          control={
            <Select
              variant="outlined"
              placeholder="Ничего не выбрано"
              fullWidth
              value={courseTag?._id}
              onChange={(value): void => {
                form.setFieldValue('tag', value);
              }}
              readOnly={readOnly}
            >
              {acceptedTags.map((item) => (
                <SelectItem value={item._id}>
                  {item.title}
                </SelectItem>
              ))}
            </Select>
          }
        />
      </Stack>

      {!readOnly && <DialogFooter>
        <Stack direction="column" spacing={8}>
          <Divider />
          <Stack
            justifyContent="flex-end"
            spacing={16}
          >
            <Button
              variant="outlined"
              className={classes.cancelBtn}
              onClick={onClose}
            >
            Отменить
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.submitBtn}
              onClick={handleSave}
              disabled={disabledModeration}
            >
            Сохранить
            </Button>
          </Stack>
        </Stack>
      </DialogFooter>}

    </Dialog>
  );
});
