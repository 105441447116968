import { BaseFormStore } from '../../../shared/form';
import { IPortalUpdateSectionArgs } from '../../section';
import { ICreateSection, IPortalSection } from '../../section/types';
import { SECTION_FORM_INITIAL_STATE } from './defaults';
import { sectionSchema } from './schema';

export class SectionFormStore extends BaseFormStore<ICreateSection> {

  constructor() {
    super(SECTION_FORM_INITIAL_STATE, sectionSchema);
  }

  public load(section: IPortalSection): void {
    super.load({
      title: section.title,
      system: section.system,
    });
  }

  public getArgs(): Omit<IPortalUpdateSectionArgs, '_id'> {
    const { title, system, ...state } = this.getValues();
    const args = {
      ...state,
      title: title || 'Добавить новую запись',
      system: typeof system === 'object' ? system._id : system,
    };

    return args;
  }

}
