import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import cn from 'classnames';
import ru from 'date-fns/locale/ru';
import { FormHelperText } from '../form-helper-text';
import { CalendarIcon } from '../icons/calendar';
import { CloseIcon } from '../icons/close';
import { InputBase } from '../input-base';
import { createUseStyles } from '../theme/createUseStyles';
import { IDateFieldProps } from './date-field.models';

const PREFIX = 'DateField';
const useStyles = createUseStyles(
  (theme) => ({
    root: {
      '& .react-datepicker__month-container': {
        height: '260px',
      },
    },
    fullWidth: {
      width: '100%',
    },
    input: {},
    inputWithReset: {
      '& .InputBase-endAdornment': {
        width: 32 + 20,
        zIndex: 1,
        pointerEvents: 'initial',
      },
    },
    icons: {
      height: 20,
    },
    icon: {
      width: 20,
      height: 20,
      cursor: 'pointer',
      position: 'relative',
      color: theme.palette.text.secondary,

      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
  }),
  { name: PREFIX },
);

export const DateField: React.FC<IDateFieldProps> = (props) => {
  const {
    value,
    inputValue,
    onChange,
    fullWidth,
    className,
    helperText,
    color,
    placeholder,
    disabled,
    required,
    readOnly,
    error,
    onReset,
    DatePickerProps,
    ...other
  } = props;

  const classes = useStyles();
  const rootCN = cn(classes.root, { [classes.fullWidth]: fullWidth }, className);

  const overrideColor = error ? 'error' : color;
  const showReset = Boolean(value && onReset && !readOnly);

  return (
    <div className={rootCN} {...other}>
      <DatePicker
        selected={value}
        value={inputValue}
        onChange={(date, event): void => {
          if (onChange) {
            onChange(date || undefined, event);
          }
        }}
        placeholderText={placeholder}
        customInput={
          <InputBase
            className={cn(classes.input, { [classes.inputWithReset]: showReset })}
            color={overrideColor}
            fullWidth={fullWidth}
            endAdornment={
              <div className={classes.icons}>
                {showReset && <CloseIcon className={classes.icon} onClick={onReset} />}
                <CalendarIcon className={classes.icon} />
              </div>
            }
          />
        }
        disabled={disabled}
        required={required}
        readOnly={readOnly}
        dateFormat="dd.MM.yyyy"
        locale={ru}
        {...DatePickerProps}
      />
      {!!helperText && <FormHelperText color={overrideColor} variant="label">{helperText}</FormHelperText>}
    </div>
  );
};
