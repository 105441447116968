import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '../svg-icon';

export const ImageIcon: FC<SvgIconProps> = ({ style = {}, ...props }) => (
  <SvgIcon
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ padding: 2, ...style }}
    {...props}
  >
    <g clipPath="url(#clip0_156_532)">
      <path
        d="M7.50002 1.669V1.6665H16.665C17.125 1.6665 17.5 2.04567 17.5 2.49317V17.5065C17.4998 17.7258 17.4125 17.9361 17.2574 18.0911C17.1022 18.2461 16.8918 18.3332 16.6725 18.3332H3.32752C3.21808 18.3324 3.10987 18.3101 3.00906 18.2675C2.90825 18.2249 2.81681 18.1629 2.73997 18.085C2.66313 18.007 2.60239 17.9148 2.56122 17.8134C2.52005 17.712 2.49925 17.6034 2.50002 17.494V6.6665L7.50002 1.669ZM4.85835 6.6665H7.50002V4.02484L4.85835 6.6665ZM9.16669 3.33317V7.49984C9.16669 7.72085 9.07889 7.93281 8.92261 8.08909C8.76633 8.24537 8.55437 8.33317 8.33335 8.33317H4.16669V16.6665H15.8334V3.33317H9.16669Z"
        fill="currentColor"
      />
      <path
        d="M8.75 9.1665C8.05964 9.1665 7.5 9.72615 7.5 10.4165C7.5 11.1069 8.05964 11.6665 8.75 11.6665C9.44036 11.6665 10 11.1069 10 10.4165C10 9.72615 9.44036 9.1665 8.75 9.1665Z"
        fill="currentColor"
      />
      <path
        d="M15.8334 13.0958V15.3809C14.9581 14.6531 14.0836 13.9194 13.265 13.1838C12.8612 13.5474 12.4466 13.9097 12.0358 14.2688L12.0328 14.2714C11.4775 14.7566 10.9292 15.2359 10.4107 15.7141L9.72258 16.3489L8.96884 15.8854L7.16786 14.7486C6.68184 15.2257 6.1825 15.6864 5.69057 16.1402L5.68574 16.1446C5.19517 16.5972 4.63311 17.043 4.16675 17.5002V15.1934C4.39656 14.9785 4.62732 14.7656 4.85601 14.5547L4.86078 14.5503C5.47491 13.9838 6.07752 13.4277 6.64996 12.8469L6.9917 12.5001L9.57446 14.1302L9.58578 14.1198C10.1163 13.6304 10.6753 13.1419 11.2274 12.6594L11.2304 12.6568C11.7856 12.1715 12.334 11.6923 12.8524 11.214L13.2649 10.8335L13.6774 11.214C14.3554 11.8395 15.0837 12.4659 15.8334 13.0958Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_156_532">
        <rect width="20" height="20" fill="currentColor" />
      </clipPath>
    </defs>
  </SvgIcon>
);
