import React, { FC, Fragment, PropsWithChildren, useContext } from 'react';
import { observer } from 'mobx-react';
import { AuthLoader } from '../auth/access-loader.view';
import { LayoutSimple } from '../layout/layout-simple.view';

export const Access: FC<PropsWithChildren> = observer((props) =>
// const { data, error } = digital.user.useQuery({ variables: {}, skip: true });
// const { digital } = useContext(appStoreContext);

// if (
//   error === 'Forbidden resource'
//   || (data && ![DigitalUserRole.admin].includes(data.role))
// ) {
//   return (
//     <LayoutSimple>
//       <AuthLoader />
//     </LayoutSimple>
//   );
// }

  // eslint-disable-next-line implicit-arrow-linebreak
  <Fragment>{props.children}</Fragment>);
