import React from 'react';
import { createUseStyles } from 'react-jss';
import cn from 'classnames';
import Color from 'color';
import { getTextRowsStyles } from '../utils/style/get-text-rows-style';

export type MenuItemProps = React.HTMLAttributes<HTMLElement> & {
  component?: (props: React.HTMLAttributes<HTMLElement>) => JSX.Element;
  selected?: boolean;
  disabled?: boolean;
} & React.RefAttributes<HTMLElement>;

const PREFIX = 'MenuItem';
const useStyles = createUseStyles(
  (theme) => ({
    root: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '14px',
      display: 'block',
      listStyle: 'none',
      padding: '8px 24px',

      '&:hover': {
        cursor: 'pointer',
        color: theme.palette.primary.main,
        background: theme.palette.action.hover,
      },
    },
    value: {
      ...getTextRowsStyles(2),
      color: theme.palette.text.primary,
    },
    selected: {
      color: theme.palette.primary.main,
      background: theme.palette.action.hover,
    },
    disabled: {
      color: theme.palette.text.secondary,
      background: Color(theme.palette.text.secondary).alpha(0.1).toString(),

      '&:hover': {
        cursor: 'default',
        color: theme.palette.text.secondary,
        background: Color(theme.palette.text.secondary).alpha(0.1).toString(),
      },
    },
  }),
  { name: PREFIX },
);

export const MenuItem: React.FC<MenuItemProps> = React.forwardRef<HTMLElement, MenuItemProps>((props, ref) => {
  const { component, selected, className, disabled, ...other } = props;

  const classes = useStyles();

  const rootCN = cn(classes.root, { [classes.selected]: selected, [classes.disabled]: disabled }, className);
  // eslint-disable-next-line
  const Root = component || ((p: MenuItemProps): JSX.Element => <li ref={ref as any} {...p} />);

  return (
    <Root ref={ref} className={rootCN} {...other}>
      <div className={classes.value}>{props.children}</div>
    </Root>
  );
});
