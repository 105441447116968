import React, { FC } from 'react';
import cn from 'classnames';
import { FormHelperText } from '../form-helper-text';
import { InputBase } from '../input-base';
import { createUseStyles } from '../theme/createUseStyles';
import { ITextFieldProps } from './text-field.models';

const PREFIX = 'TextField';

const useStyles = createUseStyles(
  () => ({
    root: {
      width: 'fit-content',
    },
    fullWidth: {
      width: '100%',
    },
  }),
  { name: PREFIX },
);

export const TextField: FC<ITextFieldProps> = (props) => {
  const {
    className,
    helperText,
    color,
    fullWidth,
    value,
    defaultValue,
    type,
    onChange,
    placeholder,
    disabled,
    required,
    readOnly,
    InputProps = {},
    error,
    ...other
  } = props;

  const classes = useStyles();
  const rootCN = cn(classes.root, { [classes.fullWidth]: fullWidth }, className);

  const overrideColor = error ? 'error' : color;

  return (
    <div className={rootCN} {...other}>
      <InputBase
        value={value}
        defaultValue={defaultValue}
        type={type}
        onChange={onChange}
        placeholder={placeholder}
        color={overrideColor}
        disabled={disabled}
        required={required}
        fullWidth={fullWidth}
        readOnly={readOnly}
        { ...InputProps }
      />
      {!!helperText && <FormHelperText color={overrideColor} variant="label">{helperText}</FormHelperText>}
    </div>
  );
};
