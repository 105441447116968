export enum EnvKeys {
  PORTAL_API = 'REACT_APP_PORTAL_ADMIN_API_HOST',
  // websocketURL = 'REACT_APP_WEBSOCKET_URL',
  LOCAL_TOKEN = 'REACT_APP_LOCAL_TOKEN',
  // env = 'NODE_ENV',
}

export interface IConfigStore {
  // websocketURL: string;
  // filesStorage: string;
}
