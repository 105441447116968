import React, { FC, HTMLAttributes } from 'react';
import { createUseStyles } from 'react-jss';
import cn from 'classnames';

const PREFIX = 'NotFound';
const useStyles = createUseStyles(
  () => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  }),
  { name: PREFIX },
);

export type NotFoundProps = HTMLAttributes<HTMLDivElement>;

export const NotFound: FC = (props: NotFoundProps) => {
  const { className, ...other } = props;

  const classes = useStyles();
  const rootCN = cn(classes.root, className);

  return (
    <div className={rootCN} {...other}>
      <p>Страница не найдена</p>
    </div>
  );
};
