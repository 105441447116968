import React from 'react';
import { createUseStyles } from 'react-jss';
import { observer } from 'mobx-react';
import { useSearch } from '../../../../../shared/utils/hooks';
import { IPortalCourse, IPortalCourseStatus, PortalCourseOrderBy } from '../../../../../stores/course/types';
import { COURSE_FORM_INITIAL_STATE } from '../../../../../stores/form/course/defaults';
import { useAppStore } from '../../../../../stores/root/root.context';
import { Order } from '../../../../../stores/types';
import { CourseDialog } from '../../../../components/dialog-course/dialog-course.view';
import { Button } from '../../../../components/ui/button';
import { useDialogState } from '../../../../components/ui/dialog/dialog.hook';
import { AddIcon } from '../../../../components/ui/icons/add';
import { SearchIcon } from '../../../../components/ui/icons/search';
import { Menu } from '../../../../components/ui/menu';
import { MenuItem } from '../../../../components/ui/menu/menu-item.view';
import { useMenuState } from '../../../../components/ui/menu/menu.hook';
import { onErrorSnackbar, snackbar } from '../../../../components/ui/snackbar/snackbar.utils';
import { Stack } from '../../../../components/ui/stack';
import { Table } from '../../../../components/ui/table';
import { TablePagination, useTablePaginationState } from '../../../../components/ui/table-pagination';
import { TextField } from '../../../../components/ui/text-field';
import { getCourseListColumns } from '../course-list.columns';

const PREFIX = 'CourseListPage';
const useStyles = createUseStyles(
  () => ({
    header: {
      flex: 1,
      marginTop: 24,
      width: '100%',
      marginBottom: 24,
    },
  }),
  { name: PREFIX },
);

export const RecordsList: React.FC = observer(() => {
  const { portal, courseForm: form } = useAppStore();
  const classes = useStyles();
  const courseDialog = useDialogState();

  const [selectedCourse, setSelectedCourse] = React.useState<IPortalCourse | undefined>();
  const [readOnly, setReadOnly] = React.useState<boolean>(false);

  const menuState = useMenuState();
  const pagination = useTablePaginationState(0, 10);
  const search = useSearch();

  const { data, loading, refetch } = portal.courseList.useQuery({
    variables: {
      limit: pagination.rowsPerPage,
      offset: pagination.page * pagination.rowsPerPage,
      title: search.debounced,
      sort: '-status,-created_at',
    },
  });

  const columns = getCourseListColumns({
    onMore:
      (record) => (e): void => {
        e.stopPropagation();
        e.preventDefault();

        if (menuState.open) {
          menuState.onClose();
        }
        else {
          menuState.show(e);
          setSelectedCourse(record);
        }
      },
  });

  const [createCourse] = portal.createCourse.useMutation({
    onError: onErrorSnackbar,
  });

  const [updateCourse] = portal.updateCourse.useMutation({
    onError: onErrorSnackbar,
  });

  const [deleteCourse] = portal.deleteCourse.useMutation({
    onError: onErrorSnackbar,
  });
  const [acceptCourse] = portal.acceptCourse.useMutation({
    onError: onErrorSnackbar,
  });
  const [declineCourse] = portal.declineCourse.useMutation({
    onError: onErrorSnackbar,
  });

  const handleRecordView = (): void => {
    menuState.onClose();

    if (selectedCourse) {
      form.load(selectedCourse);
      courseDialog.open();
      setReadOnly(true);
    }
  };

  const handleRecordEdit = (): void => {
    menuState.onClose();

    if (selectedCourse) {
      form.load(selectedCourse);
      courseDialog.open();
      setReadOnly(false);
    }
  };

  const handleSave = (): void => {
    const formValues = form.getArgs();
    if (!selectedCourse) {
      createCourse({ ...formValues }).then((): void => {
        refetch();
        snackbar({ message: 'Курс создан!', severity: 'success' });
        courseDialog.close();
      });
    }
    else {
      updateCourse({
        ...formValues,
        _id: selectedCourse._id,
      }).then((): void => {
        refetch();
        snackbar({ message: 'Курс обновлен!', severity: 'success' });
        courseDialog.close();
      });
    }
  };
  const handleDelete = (_id: string): void => {
    deleteCourse({ _id }).then((): void => {
      refetch();
      snackbar({ message: 'Курс удален!', severity: 'success' });
    });
  };
  const handleAccept = (_id: string): void => {
    acceptCourse({ _id }).then((): void => {
      refetch();
      snackbar({ message: 'Курс обновлен!', severity: 'success' });
    });
  };
  const handleDecline = (_id: string): void => {
    declineCourse({ _id }).then((): void => {
      refetch();
      snackbar({ message: 'Курс обновлен!', severity: 'success' });
    });
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between" className={classes.header}>
        <TextField
          style={{ maxWidth: 480 }}
          fullWidth
          placeholder="Поиск по названию"
          InputProps={{ endAdornment: <SearchIcon /> }}
          value={search.value}
          onChange={(e): void => {
            search.change(e.target.value);
          }}
        />

        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={(): void => {
            form.reset(COURSE_FORM_INITIAL_STATE);
            setSelectedCourse(undefined);
            courseDialog.open();
            setReadOnly(false);
          }}
        >
          Добавить новую запись
        </Button>
      </Stack>
      <Table<IPortalCourse>
        columns={columns}
        loading={loading}
        rowKey="_id"
        data={data?.items}
        hover
        footer={(): JSX.Element => (
          <TablePagination
            total={data?.totalCount || 0}
            page={pagination.page}
            rowsPerPage={pagination.rowsPerPage}
            onChangePage={pagination.onChangePage}
            onChangeRowsPerPage={pagination.onChangeRowsPerPage}
          />
        )}
      />

      {selectedCourse && <Menu {...menuState.props} placement="bottom-right">
        {selectedCourse.status === IPortalCourseStatus.published &&
          <MenuItem onClick={handleRecordView}>
          Посмотреть
          </MenuItem>}
        {selectedCourse.status === IPortalCourseStatus.published &&
          <MenuItem
            onClick={(): void => {
              menuState.onClose();
              handleDecline(selectedCourse._id);
            }}
          >
          Снять с публикации
          </MenuItem>}
        {selectedCourse.status === IPortalCourseStatus.draft &&
          <MenuItem onClick={handleRecordEdit}>
          Редактировать
          </MenuItem>}
        {selectedCourse.status === IPortalCourseStatus.draft &&
          <MenuItem
            onClick={(): void => {
              menuState.onClose();
              handleAccept(selectedCourse._id);
            }}
          >
          Опубликовать
          </MenuItem>}
        {selectedCourse.status === IPortalCourseStatus.draft &&
          <MenuItem
            onClick={(): void => {
              menuState.onClose();
              handleDelete(selectedCourse._id);
            }}
          >
            Удалить
          </MenuItem>}
      </Menu>}
      <CourseDialog {...courseDialog.props} readOnly={readOnly} onSubmit={handleSave} />

    </>
  );
});
