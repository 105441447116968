import { IPortalStore } from '../index';
import { Order } from '../types';
import { convertActivity } from './activity.mappers';
import { IPortalActivity, IPortalActivityOrderBy } from './types';

export type IPortalGetActivityArgs = { id: string };
export type IPortalGetActivityRes<TDate = Date> = IPortalActivity<TDate>;

export type IPortalFindActivityArgs = {
  limit?: number;
  offset?: number;
  sort?: string;
} & IPortalTotalActivityArgs;
export type IPortalFindActivityRes<TDate = Date> = {
  items: IPortalActivity<TDate>[],
  totalCount: number;
};

export type IPortalTotalActivityArgs = {
  title?: string;
};
export type IPortalTotalActivityRes = number;

export type IPortalUpdateActivityArgs = Omit<
IPortalActivity,
| 'created_at'
| 'updated_at'
| 'status'
| 'similar_activity'> & {
  similar_activity: string[];
};
export type IPortalUpdateActivityRes = void;

export type IPortalCreateActivityArgs = Omit<
IPortalActivity,
| '_id'
| 'created_at'
| 'updated_at'
| 'status'
| 'similar_activity'> & {
  similar_activity: string[];
};
export type IPortalCreateActivityRes<TDate = Date> = IPortalActivity<TDate>;

export type IPortalDeleteActivityArgs = { _id: string };
export type IPortalDeleteActivityRes = void;

export type IPortalActivityAcceptArgs = { _id: string };
export type IPortalActivityAcceptRes = void;

export type IPortalActivityDeclineArgs = { _id: string };
export type IPortalActivityDeclineRes = void;

export type IPortalUploadFilesArgs = FormData;
export type IPortalUploadFilesRes = string[];

export class PortalActivity {

  protected portal: IPortalStore;
  public uploadImageUrl: string;
  public uploadFileUrl: string;

  constructor(portal: IPortalStore) {
    this.portal = portal;
    this.uploadImageUrl = `${portal.baseURL}/upload/image`;
    this.uploadFileUrl = `${portal.baseURL}/upload/doc`;
  }

  public async uploadFiles(args: IPortalUploadFilesArgs): Promise<IPortalUploadFilesRes> {
    const res = await this.portal
      .jsonRequest<IPortalUploadFilesRes>({
      method: 'POST',
      url: '/upload/doc',
      data: args,
    });

    return res;
  }

  public async get(args: IPortalGetActivityArgs): Promise<IPortalGetActivityRes> {
    const res = await this.portal
      .jsonRequest<IPortalGetActivityRes<string>>({
      method: 'GET',
      url: '/activity/get',
      data: args,
    });

    return convertActivity(res);
  }

  public async current(): Promise<IPortalGetActivityRes> {
    const res = await this.portal
      .jsonRequest<IPortalGetActivityRes<string>>({
      method: 'POST',
      url: '/activity/current',
      data: {},
    });

    return convertActivity(res);
  }

  public async find(args: IPortalFindActivityArgs): Promise<IPortalFindActivityRes> {
    const res = await this.portal
      .jsonRequest<IPortalFindActivityRes<string>>({
      method: 'GET',
      url: '/activity/all',
      data: args,
    });

    return {
      items: res.items.map(convertActivity),
      totalCount: res.totalCount,
    };
  }

  public total(args: IPortalTotalActivityArgs): Promise<IPortalTotalActivityRes> {
    return this.portal.jsonRequest<IPortalTotalActivityRes>({
      method: 'POST',
      url: '/activity/find/count',
      data: args,
    });
  }

  public async update(args: IPortalUpdateActivityArgs): Promise<IPortalUpdateActivityRes> {
    await this.portal.textRequest({
      method: 'PATCH',
      url: '/activity/update',
      data: args,
    });
  }

  public async create(args: IPortalCreateActivityArgs): Promise<IPortalCreateActivityRes> {
    const res = await this.portal
      .jsonRequest<IPortalCreateActivityRes<string>>({
      method: 'POST',
      url: '/activity/create',
      data: args,
    });

    return convertActivity(res);
  }

  public async delete(args: IPortalDeleteActivityArgs): Promise<IPortalDeleteActivityRes> {
    await this.portal.textRequest({
      method: 'DELETE',
      url: '/activity/delete',
      data: args,
    });
  }

  public async activityAccept(args: IPortalActivityAcceptArgs): Promise<IPortalActivityAcceptRes> {
    await this.portal.textRequest({
      method: 'PATCH',
      url: '/activity/accept',
      data: args,
    });
  }

  public async activityDecline(args: IPortalActivityDeclineArgs): Promise<IPortalActivityDeclineRes> {
    await this.portal.textRequest({
      method: 'PATCH',
      url: '/activity/decline',
      data: args,
    });
  }

}
