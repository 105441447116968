import { ActivityFormStore } from '../form/activity/form';
import { CourseFormStore } from '../form/course/form';
import { EventFormStore } from '../form/event/form';
import { NewsFormStore } from '../form/news/form';
import { QuestionFormStore } from '../form/question/form';
import { SectionFormStore } from '../form/section/form';
import { SoftwareFormStore } from '../form/software/form';
import { SystemFormStore } from '../form/system/form';
import { TagFormStore } from '../form/tag/form';
import { PortalStore } from '../portal/portal.store';
import { AuthStore } from './auth.store';
import { ConfigStore } from './config.store';
import { IRootStore } from './root.models';

export class RootStore implements IRootStore {

  public readonly config: ConfigStore;

  public portal: PortalStore;
  public portalBase: PortalStore;
  public readonly auth: AuthStore;
  public readonly eventForm: EventFormStore;
  public newsForm: NewsFormStore;
  public activityForm: ActivityFormStore;
  public courseForm: CourseFormStore;
  public softwareForm: SoftwareFormStore;
  public systemForm: SystemFormStore;
  public sectionForm: SectionFormStore;
  public questionForm: QuestionFormStore;
  public tagForm: TagFormStore;

  public get today(): Date {
    return new Date();
  }

  constructor() {
    this.config = new ConfigStore();
    this.auth = new AuthStore(this);
    this.portal = new PortalStore(this);
    this.portalBase = new PortalStore(this);
    this.eventForm = new EventFormStore();
    this.newsForm = new NewsFormStore();
    this.activityForm = new ActivityFormStore();
    this.courseForm = new CourseFormStore();
    this.softwareForm = new SoftwareFormStore();
    this.systemForm = new SystemFormStore();
    this.sectionForm = new SectionFormStore();
    this.questionForm = new QuestionFormStore();
    this.tagForm = new TagFormStore();
  }

}
