import { IPortalStore } from '..';
import { IPortalActivityAcceptArgs, IPortalActivityAcceptRes, IPortalActivityDeclineArgs, IPortalActivityDeclineRes, IPortalCreateActivityArgs, IPortalCreateActivityRes, IPortalDeleteActivityArgs, IPortalDeleteActivityRes, IPortalFindActivityArgs, IPortalFindActivityRes, IPortalGetActivityArgs, IPortalGetActivityRes, IPortalUpdateActivityArgs, IPortalUpdateActivityRes } from '../activity';
import { IPortalCreateCourseArgs, IPortalCreateCourseRes, IPortalDeclineCourseArgs, IPortalDeclineCourseRes, IPortalDeleteCourseArgs, IPortalDeleteCourseRes, IPortalFindCourseArgs, IPortalFindCourseRes, IPortalGetCatalogCoursesArgs, IPortalGetCatalogCoursesRes, IPortalGetCourseArgs, IPortalGetCourseRes, IPortalUpdateCourseArgs, IPortalUpdateCourseRes } from '../course';
import { IPortalCreateEventArgs, IPortalCreateEventRes, IPortalDeleteEventArgs, IPortalDeleteEventRes, IPortalEventAcceptArgs, IPortalEventAcceptRes, IPortalEventDeclineArgs, IPortalEventDeclineRes, IPortalFindEventArgs, IPortalFindEventRes, IPortalGetEventArgs, IPortalGetEventRes, IPortalUpdateEventArgs, IPortalUpdateEventRes } from '../event';
import {
  IPortalCreateNewsArgs,
  IPortalCreateNewsRes,
  IPortalDeleteNewsArgs,
  IPortalDeleteNewsRes,
  IPortalFindNewsArgs,
  IPortalFindNewsRes,
  IPortalGetNewsArgs,
  IPortalGetNewsRes,
  IPortalNewsAcceptArgs,
  IPortalNewsAcceptRes, IPortalNewsDeclineArgs, IPortalNewsDeclineRes, IPortalUpdateNewsArgs, IPortalUpdateNewsRes, IPortalUploadFilesArgs, IPortalUploadFilesRes,
} from '../news';
import { IPortalCreateQuestionArgs, IPortalCreateQuestionRes, IPortalDeleteQuestionArgs, IPortalDeleteQuestionRes, IPortalFindQuestionArgs, IPortalFindQuestionRes, IPortalUpdateQuestionArgs, IPortalUpdateQuestionRes } from '../question';
import { RootStore } from '../root/root.store';
import { IPortalCreateSectionArgs, IPortalCreateSectionRes, IPortalDeleteSectionArgs, IPortalDeleteSectionRes, IPortalFindSectionArgs, IPortalFindSectionRes, IPortalUpdateSectionArgs, IPortalUpdateSectionRes } from '../section';
import { IPortalAcceptSoftwareArgs, IPortalAcceptSoftwareRes, IPortalCreateSoftwareArgs, IPortalCreateSoftwareRes, IPortalDeclineSoftwareArgs, IPortalDeclineSoftwareRes, IPortalDeleteSoftwareArgs, IPortalDeleteSoftwareRes, IPortalFindSoftwareArgs, IPortalFindSoftwareRes, IPortalGetSoftwareArgs, IPortalGetSoftwareRes, IPortalUpdateSoftwareArgs, IPortalUpdateSoftwareRes } from '../software';
import { IPortalCreateSystemArgs, IPortalDeleteSystemArgs, IPortalDeleteSystemRes, IPortalCreateSystemRes, IPortalFindSystemArgs, IPortalFindSystemRes, IPortalUpdateSystemArgs, IPortalUpdateSystemRes } from '../system';
import { IPortalFindTagRes, IPortalFindTagArgs, IPortalCreateTagRes, IPortalCreateTagArgs, IPortalUpdateTagArgs, IPortalUpdateTagRes, IPortalDeleteTagArgs, IPortalDeleteTagRes, IPortalAcceptTagRes, IPortalAcceptTagArgs, IPortalDeclineTagRes, IPortalDeclineTagArgs } from '../tag';
import { IPortalUserLoginArgs, IPortalUserLoginRes } from '../user';
import { MutationStore, QueryStore } from '../utils';

export class PortalStore {

  public readonly rootStore: RootStore;
  public readonly portal: IPortalStore;
  public readonly portalBase: IPortalStore;

  public readonly user: QueryStore<IPortalUserLoginRes, IPortalUserLoginArgs>;

  public readonly eventItem: QueryStore<IPortalGetEventRes, IPortalGetEventArgs>;
  public readonly eventList: QueryStore<IPortalFindEventRes, IPortalFindEventArgs>;
  public readonly createEvent: MutationStore<IPortalCreateEventRes, IPortalCreateEventArgs>;
  public readonly deleteEvent: MutationStore<IPortalDeleteEventRes, IPortalDeleteEventArgs>;
  public readonly acceptEvent: MutationStore<IPortalEventAcceptRes, IPortalEventAcceptArgs>;
  public readonly declineEvent: MutationStore<IPortalEventDeclineRes, IPortalEventDeclineArgs>;
  public readonly updateEvent: MutationStore<IPortalUpdateEventRes, IPortalUpdateEventArgs>;

  public readonly newsItem: QueryStore<IPortalGetNewsRes, IPortalGetNewsArgs>;
  public readonly newsList: QueryStore<IPortalFindNewsRes, IPortalFindNewsArgs>;
  public readonly createNews: MutationStore<IPortalCreateNewsRes, IPortalCreateNewsArgs>;
  public readonly deleteNews: MutationStore<IPortalDeleteNewsRes, IPortalDeleteNewsArgs>;
  public readonly acceptNews: MutationStore<IPortalNewsAcceptRes, IPortalNewsAcceptArgs>;
  public readonly declineNews: MutationStore<IPortalNewsDeclineRes, IPortalNewsDeclineArgs>;
  public readonly updateNews: MutationStore<IPortalUpdateNewsRes, IPortalUpdateNewsArgs>;
  public readonly uploadNewsFiles: MutationStore<IPortalUploadFilesRes, IPortalUploadFilesArgs>;

  public readonly activityItem: QueryStore<IPortalGetActivityRes, IPortalGetActivityArgs>;
  public readonly activitylist: QueryStore<IPortalFindActivityRes, IPortalFindActivityArgs>;
  public readonly createActivity: MutationStore<IPortalCreateActivityRes, IPortalCreateActivityArgs>;
  public readonly deleteActivity: MutationStore<IPortalDeleteActivityRes, IPortalDeleteActivityArgs>;
  public readonly acceptActivity: MutationStore<IPortalActivityAcceptRes, IPortalActivityAcceptArgs>;
  public readonly declineActivity: MutationStore<IPortalActivityDeclineRes, IPortalActivityDeclineArgs>;
  public readonly updateActivity: MutationStore<IPortalUpdateActivityRes, IPortalUpdateActivityArgs>;

  public readonly courseItem: QueryStore<IPortalGetCourseRes, IPortalGetCourseArgs>;
  public readonly courseList: QueryStore<IPortalFindCourseRes, IPortalFindCourseArgs>;
  public readonly createCourse: MutationStore<IPortalCreateCourseRes, IPortalCreateCourseArgs>;
  public readonly deleteCourse: MutationStore<IPortalDeleteCourseRes, IPortalDeleteCourseArgs>;
  public readonly acceptCourse: MutationStore<IPortalDeleteCourseRes, IPortalDeleteCourseArgs>;
  public readonly declineCourse: MutationStore<IPortalDeclineCourseRes, IPortalDeclineCourseArgs>;
  public readonly updateCourse: MutationStore<IPortalUpdateCourseRes, IPortalUpdateCourseArgs>;
  public readonly catalogCourses: QueryStore<IPortalGetCatalogCoursesRes, IPortalGetCatalogCoursesArgs>;

  public readonly tagList: QueryStore<IPortalFindTagRes, IPortalFindTagArgs>;
  public readonly createTag: MutationStore<IPortalCreateTagRes, IPortalCreateTagArgs>;
  public readonly deleteTag: MutationStore<IPortalDeleteTagRes, IPortalDeleteTagArgs>;
  public readonly updateTag: MutationStore<IPortalUpdateTagRes, IPortalUpdateTagArgs>;
  public readonly acceptTag: MutationStore<IPortalAcceptTagRes, IPortalAcceptTagArgs>;
  public readonly declineTag: MutationStore<IPortalDeclineTagRes, IPortalDeclineTagArgs>;

  public readonly softwareItem: QueryStore<IPortalGetSoftwareRes, IPortalGetSoftwareArgs>;
  public readonly softwareList: QueryStore<IPortalFindSoftwareRes, IPortalFindSoftwareArgs>;
  public readonly createSoftware: MutationStore<IPortalCreateSoftwareRes, IPortalCreateSoftwareArgs>;
  public readonly deleteSoftware: MutationStore<IPortalDeleteSoftwareRes, IPortalDeleteSoftwareArgs>;
  public readonly acceptSoftware: MutationStore<IPortalAcceptSoftwareRes, IPortalAcceptSoftwareArgs>;
  public readonly declineSoftware: MutationStore<IPortalDeclineSoftwareRes, IPortalDeclineSoftwareArgs>;
  public readonly updateSoftware: MutationStore<IPortalUpdateSoftwareRes, IPortalUpdateSoftwareArgs>;

  public readonly systemList: QueryStore<IPortalFindSystemRes, IPortalFindSystemArgs>;
  public readonly createSystem: MutationStore<IPortalCreateSystemRes, IPortalCreateSystemArgs>;
  public readonly deleteSystem: MutationStore<IPortalDeleteSystemRes, IPortalDeleteSystemArgs>;
  public readonly updateSystem: MutationStore<IPortalUpdateSystemRes, IPortalUpdateSystemArgs>;

  public readonly sectionList: QueryStore<IPortalFindSectionRes, IPortalFindSectionArgs>;
  public readonly createSection: MutationStore<IPortalCreateSectionRes, IPortalCreateSectionArgs>;
  public readonly deleteSection: MutationStore<IPortalDeleteSectionRes, IPortalDeleteSectionArgs>;
  public readonly updateSection: MutationStore<IPortalUpdateSectionRes, IPortalUpdateSectionArgs>;

  public readonly questionList: QueryStore<IPortalFindQuestionRes, IPortalFindQuestionArgs>;
  public readonly createQuestion: MutationStore<IPortalCreateQuestionRes, IPortalCreateQuestionArgs>;
  public readonly deleteQuestion: MutationStore<IPortalDeleteQuestionRes, IPortalDeleteQuestionArgs>;
  public readonly updateQuestion: MutationStore<IPortalUpdateQuestionRes, IPortalUpdateQuestionArgs>;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    this.portal = new IPortalStore(this.rootStore.config.PORTAL_API, this.rootStore.auth.token);
    this.portalBase = new IPortalStore(this.rootStore.config.PORTAL_API_BASE, this.rootStore.auth.token);

    this.user = new QueryStore((args) => this.portal.user.login(args));

    this.eventItem = new QueryStore((args) => this.portal.event.get(args));
    this.eventList = new QueryStore((args) => this.portal.event.find(args));
    this.createEvent = new MutationStore((args) => this.portal.event.create(args));
    this.deleteEvent = new MutationStore((args) => this.portal.event.delete(args));
    this.acceptEvent = new MutationStore((args) => this.portal.event.eventAccept(args));
    this.declineEvent = new MutationStore((args) => this.portal.event.eventDecline(args));
    this.updateEvent = new MutationStore((args) => this.portal.event.update(args));

    this.newsItem = new QueryStore((args) => this.portal.news.get(args));
    this.newsList = new QueryStore((args) => this.portal.news.find(args));
    this.createNews = new MutationStore((args) => this.portal.news.create(args));
    this.deleteNews = new MutationStore((args) => this.portal.news.delete(args));
    this.acceptNews = new MutationStore((args) => this.portal.news.newsAccept(args));
    this.declineNews = new MutationStore((args) => this.portal.news.newsDecline(args));
    this.updateNews = new MutationStore((args) => this.portal.news.update(args));
    this.uploadNewsFiles = new MutationStore((args) => this.portal.news.uploadFiles(args));

    this.activityItem = new QueryStore((args) => this.portal.activity.get(args));
    this.activitylist = new QueryStore((args) => this.portal.activity.find(args));
    this.createActivity = new MutationStore((args) => this.portal.activity.create(args));
    this.deleteActivity = new MutationStore((args) => this.portal.activity.delete(args));
    this.acceptActivity = new MutationStore((args) => this.portal.activity.activityAccept(args));
    this.declineActivity = new MutationStore((args) => this.portal.activity.activityDecline(args));
    this.updateActivity = new MutationStore((args) => this.portal.activity.update(args));

    this.courseItem = new QueryStore((args) => this.portal.course.get(args));
    this.courseList = new QueryStore((args) => this.portal.course.find(args));
    this.createCourse = new MutationStore((args) => this.portal.course.create(args));
    this.deleteCourse = new MutationStore((args) => this.portal.course.delete(args));
    this.acceptCourse = new MutationStore((args) => this.portal.course.accept(args));
    this.declineCourse = new MutationStore((args) => this.portal.course.decline(args));
    this.updateCourse = new MutationStore((args) => this.portal.course.update(args));
    this.catalogCourses = new QueryStore((args) => this.portal.course.getCourses(args));

    this.tagList = new QueryStore((args) => this.portal.tag.find(args));
    this.createTag = new MutationStore((args) => this.portal.tag.create(args));
    this.deleteTag = new MutationStore((args) => this.portal.tag.delete(args));
    this.updateTag = new MutationStore((args) => this.portal.tag.update(args));
    this.acceptTag = new MutationStore((args) => this.portal.tag.publish(args));
    this.declineTag = new MutationStore((args) => this.portal.tag.decline(args));

    this.softwareItem = new QueryStore((args) => this.portal.software.get(args));
    this.softwareList = new QueryStore((args) => this.portal.software.find(args));
    this.createSoftware = new MutationStore((args) => this.portal.software.create(args));
    this.deleteSoftware = new MutationStore((args) => this.portal.software.delete(args));
    this.acceptSoftware = new MutationStore((args) => this.portal.software.accept(args));
    this.declineSoftware = new MutationStore((args) => this.portal.software.decline(args));
    this.updateSoftware = new MutationStore((args) => this.portal.software.update(args));

    this.systemList = new QueryStore((args) => this.portalBase.system.find(args));
    this.createSystem = new MutationStore((args) => this.portalBase.system.create(args));
    this.deleteSystem = new MutationStore((args) => this.portalBase.system.delete((args)));
    this.updateSystem = new MutationStore((args) => this.portalBase.system.update(args));

    this.sectionList = new QueryStore((args) => this.portalBase.section.find(args));
    this.createSection = new MutationStore((args) => this.portalBase.section.create(args));
    this.deleteSection = new MutationStore((args) => this.portalBase.section.delete((args)));
    this.updateSection = new MutationStore((args) => this.portalBase.section.update(args));

    this.questionList = new QueryStore((args) => this.portalBase.question.find(args));
    this.createQuestion = new MutationStore((args) => this.portalBase.question.create(args));
    this.deleteQuestion = new MutationStore((args) => this.portalBase.question.delete((args)));
    this.updateQuestion = new MutationStore((args) => this.portalBase.question.update(args));
  }

  public refresh(): void {
    this.portal.token = this.rootStore.auth.token;
    this.portalBase.token = this.rootStore.auth.token;
  }

}
