import React from 'react';
import { createUseStyles } from 'react-jss';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { useAppStore } from '../../../stores/root/root.context';
import { ControlBlock } from '../../lab/control-block';
import { Button } from '../ui/button';
import { Dialog, DialogFooter, DialogHeader } from '../ui/dialog';
import { Divider } from '../ui/divider';
import { Stack } from '../ui/stack';
import { TextField } from '../ui/text-field';
import { Typography } from '../ui/typography';
import { UploadRow } from '../ui/upload-row';
import { EventDialogProps } from './dialog-event.models';

const PREFIX = 'EventDialog';
const useStyles = createUseStyles(
  () => ({
    root: {
      maxWidth: '992px',
    },
    cancelBtn: {
      justifyContent: 'center',
      height: '44px',
      fontSize: '16px',
    },
    submitBtn: {
      justifyContent: 'center',
      border: 'none',
      height: '44px',
      fontSize: '16px',
    },
  }),
  { name: PREFIX },
);

export const EventDialog: React.FC<EventDialogProps> = observer((props) => {
  const { className, onClose, onSubmit, readOnly, ...other } = props;
  const { portal, auth, eventForm: form } = useAppStore();
  const event = form.getValues();
  const disabledModeration = !form.isValid;

  const classes = useStyles();
  const rootCN = cn(classes.root, className);

  const handleSave = (): void => {
    if (onSubmit) {
      onSubmit();
    }
  };

  return (
    <Dialog color="primary" className={rootCN} onClose={onClose} {...other} maxWidth="md">
      <DialogHeader onClose={onClose}>
        <Typography variant="h3" fontWeight="bold">
          {readOnly ? 'Просмотр события' : 'Добавить новую запись'}
        </Typography>
      </DialogHeader>

      <Divider style={{ marginTop: '20px', marginBottom: '28px' }} />

      <Stack direction="column" spacing={24} divider={<Divider style={{ width: '100%', margin: 0 }} />}>
        <Stack direction="column" spacing={10}>
          <ControlBlock
            label="Заголовок"
            required
            control={
              <TextField
                fullWidth
                placeholder="Ввод текста"
                value={event.title}
                onChange={(e): void => form.setFieldValue('title', e.target.value)}
                error={!!form.getFieldError('title')}
                helperText={form.getFieldError('title')}
                readOnly={readOnly}
              />
            }
          />
          <ControlBlock
            label="Описание"
            required
            control={
              <TextField
                fullWidth
                placeholder="Ввод текста"
                value={event.description}
                onChange={(e): void => form.setFieldValue('description', e.target.value)}
                error={!!form.getFieldError('description')}
                helperText={form.getFieldError('description')}
                readOnly={readOnly}
              />
            }
          />
        </Stack>
        <Stack direction="column" spacing={24} divider={<Divider style={{ width: '100%', margin: 0 }} />}>
          <UploadRow
            action={portal.portal.event.uploadUrl}
            authorization={auth.token}
            title="Обложка для десктоп *"
            subtitle="Загрузите баннер размером 1020х437px, максимальный вес 5мб"
            sizeLimit={5000}
            accept=".png,.jpg"
            value={event.desktop_cover}
            onChange={(v): void => form.setFieldValue('desktop_cover', (v as string[])[0])}
            readOnly={readOnly}
          />
          <UploadRow
            action={portal.portal.event.uploadUrl}
            authorization={auth.token}
            title="Обложка для планшета *"
            subtitle="Загрузите баннер размером 904х387px, максимальный вес 5мб"
            sizeLimit={5000}
            accept=".png,.jpg"
            value={event.tablet_cover}
            onChange={(v): void => form.setFieldValue('tablet_cover', (v as string[])[0])}
            readOnly={readOnly}
          />
          <UploadRow
            action={portal.portal.event.uploadUrl}
            authorization={auth.token}
            title="Обложка для мобильной версии *"
            subtitle="Загрузите баннер размером 335х214px, максимальный вес 5мб"
            sizeLimit={5000}
            accept=".png,.jpg"
            value={event.mobile_cover}
            onChange={(v): void => form.setFieldValue('mobile_cover', (v as string[])[0])}
            readOnly={readOnly}
          />
        </Stack>
      </Stack>

      {!readOnly && <DialogFooter style={{ marginTop: '40px' }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="end"
          style={{ flex: 1 }}
          spacing={16}
        >
          <Button
            variant="outlined"
            className={classes.cancelBtn}
            onClick={onClose}
          >
            Отменить
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.submitBtn}
            onClick={handleSave}
            disabled={disabledModeration}
          >
            Сохранить
          </Button>
        </Stack>
      </DialogFooter>}
    </Dialog>
  );
});
