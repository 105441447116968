import { QuestionFormState } from '../../question/types';

export const QUESTION_FORM_INITIAL_STATE: QuestionFormState = {
  question: '',
  answers: [{
    text: '',
    picture: '',
  }],
  system: '',
  section: '',
  sort_priority: 100,
};
