import React, { FC } from 'react';
import 'react-quill/dist/quill.snow.css';
import { createUseStyles } from 'react-jss';
import ReactQuill from 'react-quill';
import cn from 'classnames';
import { FormHelperText } from '../form-helper-text';
import { useInputBaseStyles } from '../input-base';
import { IEditorProps } from './editor.models';

const PREFIX = 'Editor';
const useStyles = createUseStyles(
  (theme) => ({
    root: {},
    colorDefault: {},
    colorError: {},
    colorWarning: {},
    colorSuccess: {},

    editor: {
      overflow: 'hidden',
      fontFamily: theme.typography.fontFamily,
      ...theme.typography.caption,
      color: theme.palette.text.primary,

      '&$colorDefault': {
        '& > *': {
          borderColor: '#E4E7F2',
        },
      },
      '&$colorError': {
        '& > *': {
          borderColor: theme.palette.error.main,
        },
      },
      '&$colorWarning': {
        '& > *': {
          borderColor: theme.palette.warning.main,
        },
      },
      '&$colorSuccess': {
        '& > *': {
          borderColor: theme.palette.success.main,
        },
      },

      '& > .ql-toolbar': {
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
      },
      '& > .ql-container': {
        minHeight: '200px',
        borderBottomLeftRadius: '4px',
        borderBottomRightRadius: '4px',
      },
      '& .ql-editor': {
        wordBreak: 'break-word',
      },
    },
  }),
  { name: PREFIX },
);


const modules = {
  toolbar: {
    container: [
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['bold', 'italic', 'underline', 'strike', 'link'],
    ],
  },
};

const formats = ['bold', 'italic', 'underline', 'strike', 'list', 'link'];

export const Editor: FC<IEditorProps> = (props) => {
  const {
    placeholder = 'Введите текст',
    className,
    disabled,
    value,
    required,
    readOnly,
    color,
    defaultValue,
    error,
    fullWidth,
    helperText,
    onChange,
    ...other
  } = props;

  const classes = useStyles();
  const inputBaseClasses = useInputBaseStyles();

  const overrideColor = error ? 'error' : color;

  const editorCN = cn(classes.root, className);
  const inputCN = cn(classes.editor, {
    [classes.colorDefault]: overrideColor === 'default',
    [classes.colorError]: overrideColor === 'error',
    [classes.colorWarning]: overrideColor === 'warning',
    [classes.colorSuccess]: overrideColor === 'success',
    [inputBaseClasses.fullWidth]: fullWidth,
    [inputBaseClasses.disabled]: disabled,
    [inputBaseClasses.readOnly]: readOnly,
  });

  return (
    <div className={editorCN} {...other}>
      <ReactQuill
        className={inputCN}
        theme="snow"
        placeholder={placeholder}
        readOnly={disabled || readOnly}
        modules={modules}
        formats={formats}
        value={value}
        defaultValue={defaultValue}
        onChange={(html, delta, source, editor): void => {
          if (source === 'user' && typeof onChange === 'function') {
            onChange(html, delta, source, editor);
          }
        }}
      />
      {!!helperText && <FormHelperText color={overrideColor} variant="label">{helperText}</FormHelperText>}
    </div>
  );
};
