import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import cn from 'classnames';
import { LoaderIcon } from '../icons/loader';
import { CircularProgressProps } from './circular-progress.models';

const PREFIX = 'CircularProgress';
const useStyles = createUseStyles({
  root: {
    width: 100,
    height: 100,
    animation: '$rotating 0.75s linear infinite',
  },
  '@keyframes rotating': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
}, { name: PREFIX });

export const CircularProgress: FC<CircularProgressProps> = (props) => {
  const { className, ...other } = props;

  const classes = useStyles();

  return <LoaderIcon color="primary" className={cn(classes.root, className)} {...other} />;
};
