import React from 'react';
import { createUseStyles } from 'react-jss';
import cn from 'classnames';
import { IconButton } from '../icon-button';
import { ArrowLeftIcon } from '../icons/arrow-left';
import { DoubleRightIcon } from '../icons/double-right';
import { Select, SelectItem } from '../select';
import { Stack } from '../stack';
import { Typography } from '../typography';
import { TablePaginationProps } from './table-pagination.models';

const PREFIX = 'TablePagination';
const useStyles = createUseStyles(
  (theme) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',

      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        gap: 24,
      },
    },
    right: {
      display: 'flex',
      alignItems: 'center',

      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        gap: 24,
      },
    },
    arrow: {
      userSelect: 'none',
    },
    select: {
      marginLeft: 16,
      marginRight: 32,

      '& .Select-label': {
        color: theme.palette.primary.main,
      },
      '& .Select-icon': {
        color: theme.palette.primary.main,
        width: 16,
        height: 16,
      },
      '& > .Select-select': {
        paddingRight: 28,
      },

      [theme.breakpoints.down('md')]: {
        marginRight: 0,
      },
    },
    rowsPerPage: {
      display: 'flex',
      alignItems: 'center',
    },
    selectMenu: {
      left: '-12px',
      transform: 'none',

      '& > div > *': {
        paddingLeft: 12,
        paddingRight: 24,
      },
    },
    btns: {
      marginLeft: 16,

      [theme.breakpoints.down('md')]: {
        marginLeft: 0,
      },
    },
    btn: {
      width: 26,
      height: 26,
      color: theme.palette.primary.main,

      '& > svg': {
        width: 18,
        height: 18,
      },
    },
  }),
  { name: PREFIX },
);

export const TablePagination: React.FC<TablePaginationProps> = (exampleProps) => {
  const { className, total, page, rowsPerPage, onChangePage, onChangeRowsPerPage, ...other } = exampleProps;

  const classes = useStyles();
  const rootCN = cn(classes.root, className);

  const handleChangePage = (p: number): void => {
    if (onChangePage) {
      onChangePage(p);
    }
  };
  const handleChangeRowsPerPage = (v: string): void => {
    if (onChangeRowsPerPage) {
      onChangeRowsPerPage(+v);
    }
  };

  const disabledLeft = page === 0;
  const disabledRight = (page + 1) * rowsPerPage >= total;

  return (
    <div className={rootCN} {...other}>
      <Typography color="textSecondary">Всего записей: {total}</Typography>

      <div className={classes.right}>
        <div className={classes.rowsPerPage}>
          <Typography color="textSecondary">На странице по:</Typography>
          <Select
            variant="standard"
            value={rowsPerPage.toString()}
            onChange={handleChangeRowsPerPage}
            className={classes.select}
            MenuProps={{ className: classes.selectMenu }}
          >
            <SelectItem value="10">10</SelectItem>
            <SelectItem value="20">20</SelectItem>
            <SelectItem value="50">50</SelectItem>
            <SelectItem value="100">100</SelectItem>
          </Select>
        </div>
        <Typography color="textSecondary">
          {(page * rowsPerPage) + 1}-{(page + 1) * rowsPerPage} из {total}
        </Typography>

        <Stack direction="row" spacing={2} className={classes.btns}>
          <IconButton className={classes.btn} disabled={disabledLeft} onClick={(): void => handleChangePage(0)}>
            <DoubleRightIcon className={cn(classes.arrow)} style={{ transform: 'rotate(180deg)' }} />
          </IconButton>
          <IconButton className={classes.btn} disabled={disabledLeft} onClick={(): void => handleChangePage(page - 1)}>
            <ArrowLeftIcon className={cn(classes.arrow)} />
          </IconButton>
          <IconButton className={classes.btn} disabled={disabledRight} onClick={(): void => handleChangePage(page + 1)}>
            <ArrowLeftIcon className={cn(classes.arrow)} style={{ transform: 'rotate(180deg)' }} />
          </IconButton>
          <IconButton
            className={classes.btn}
            disabled={disabledRight}
            onClick={(): void => handleChangePage(Math.floor(total / rowsPerPage) - (total % rowsPerPage === 0 ? 1 : 0))}
          >
            <DoubleRightIcon className={cn(classes.arrow)} />
          </IconButton>
        </Stack>
      </div>
    </div>
  );
};
