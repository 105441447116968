import React from 'react';
import { createUseStyles } from 'react-jss';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { useAppStore } from '../../../stores/root/root.context';
import { ControlBlock } from '../../lab/control-block';
import { Button } from '../ui/button';
import { Dialog, DialogFooter, DialogHeader } from '../ui/dialog';
import { Divider } from '../ui/divider';
import { Stack } from '../ui/stack';
import { TextArea } from '../ui/text-area';
import { TextField } from '../ui/text-field';
import { Typography } from '../ui/typography';
import { UploadRow } from '../ui/upload-row';
import { SoftwareDialogProps } from './dialog-software.models';


const PREFIX = 'SoftwareDialog';
const useStyles = createUseStyles(
  () => ({
    root: {},
    cancelBtn: {
      justifyContent: 'center',
      height: '44px',
      fontSize: '16px',
    },
    submitBtn: {
      justifyContent: 'center',
      border: 'none',
      height: '44px',
      fontSize: '16px',
    },
  }),
  { name: PREFIX },
);

export const SoftwareDialog: React.FC<SoftwareDialogProps> = observer((props) => {
  const { className, onClose, onSubmit, readOnly, ...other } = props;
  const { portal, auth, softwareForm: form } = useAppStore();
  const software = form.getValues();
  const disabledModeration = !form.isValid;

  const classes = useStyles();
  const rootCN = cn(classes.root, className);

  const handleSave = (): void => {
    if (onSubmit) {
      onSubmit();
    }
  };

  return (
    <Dialog color="primary" className={rootCN} onClose={onClose} {...other}>
      <DialogHeader onClose={onClose}>
        <Typography variant="h3" fontWeight="bold">
          Добавить новую запись
        </Typography>
      </DialogHeader>

      <Divider style={{ marginTop: '20px', marginBottom: '28px' }} />

      <Stack direction="column" spacing={24}>
        <ControlBlock
          label="Название ПО"
          control={
            <TextField
              fullWidth
              placeholder="Название ПО"
              value={software.title}
              onChange={(e): void => form.setFieldValue('title', e.target.value)}
              readOnly={readOnly}
            />
          }
        />

        <ControlBlock
          label="Описание ПО"
          control={
            <TextArea
              fullWidth
              placeholder="Название ПО"
              value={software.description}
              onChange={(e): void => form.setFieldValue('description', e.target.value)}
              readOnly={readOnly}
            />
          }
        />

        <UploadRow
          action={portal.portal.software.uploadImageUrl}
          authorization={auth.token}
          title="Обложка ПО"
          subtitle="Загрузите баннер размером 222*104px, максимальный вес 5мб"
          sizeLimit={5000}
          accept=".png,.jpg"
          value={software.logo}
          onChange={(v): void => form.setFieldValue('logo', (v as string[])[0])}
          readOnly={readOnly}
        />

        <ControlBlock
          label="Ссылка"
          control={
            <TextField
              fullWidth
              placeholder="Введите ссылку на ПО"
              value={software.link}
              onChange={(e): void => form.setFieldValue('link', e.target.value)}
              readOnly={readOnly}
            />
          }
        />
      </Stack>

      {!readOnly && <DialogFooter>
        <Stack direction="column" spacing={8}>
          <Divider style={{ margin: '24px 0' }} />
          <Stack
            justifyContent="flex-end"
            spacing={16}
          >
            <Button
              variant="outlined"
              className={classes.cancelBtn}
              onClick={onClose}
            >
              Отменить
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.submitBtn}
              onClick={handleSave}
              disabled={disabledModeration}
            >
              Сохранить
            </Button>
          </Stack>
        </Stack>
      </DialogFooter>}

    </Dialog>
  );
});
