import React, { FC } from 'react';
import { SvgIcon, SvgIconProps } from '../svg-icon/icon.view';

export const SearchIcon: FC<SvgIconProps> = ({ style = {}, ...props }) => (
  <SvgIcon
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ padding: 3, ...style }}
    {...props}
  >
    <path
      d="M13 13L9.72727 9.72727M11.1818 6.09091C11.1818 6.75946 11.0501 7.42146 10.7943 8.03912C10.5385 8.65677 10.1635 9.21799 9.69073 9.69073C9.21799 10.1635 8.65677 10.5385 8.03912 10.7943C7.42146 11.0501 6.75946 11.1818 6.09091 11.1818C5.42236 11.1818 4.76036 11.0501 4.1427 10.7943C3.52505 10.5385 2.96383 10.1635 2.49109 9.69073C2.01836 9.21799 1.64336 8.65677 1.38752 8.03912C1.13168 7.42146 1 6.75946 1 6.09091C1 4.74072 1.53636 3.44582 2.49109 2.49109C3.44582 1.53636 4.74072 1 6.09091 1C7.4411 1 8.736 1.53636 9.69073 2.49109C10.6455 3.44582 11.1818 4.74072 11.1818 6.09091Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
