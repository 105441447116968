import React, { FC } from 'react';
import { SvgIcon, SvgIconProps } from '../svg-icon';

export const CircleQuestionIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: 21, height: 20 }}
    {...props}
  >
    <path
      d="M8.48232 7.91853C8.62915 7.50113 8.91896 7.14917 9.30043 6.92498C9.6819 6.70079 10.1304 6.61884 10.5665 6.69364C11.0026 6.76844 11.3982 6.99517 11.6831 7.33367C11.9681 7.67217 12.124 8.1006 12.1234 8.54306C12.1234 9.79213 10.2498 10.4167 10.2498 10.4167M10.2727 12.9167H10.281M17.8052 10C17.8052 14.1421 14.4474 17.5 10.3052 17.5C6.1631 17.5 2.80524 14.1421 2.80524 10C2.80524 5.85786 6.1631 2.5 10.3052 2.5C14.4474 2.5 17.8052 5.85786 17.8052 10Z"
      stroke="#3B4168"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
