import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '../svg-icon';

export const MoreIcon: FC<SvgIconProps> = ({ style = {}, ...props }) => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ padding: 3, ...style }}
    {...props}
  >
    <circle cx="15.5" cy="10" r="1.25" transform="rotate(180 15.5 10)" fill="currentColor" />
    <circle cx="10" cy="10" r="1.25" transform="rotate(180 10 10)" fill="currentColor" />
    <circle cx="4.5" cy="10" r="1.25" transform="rotate(180 4.5 10)" fill="currentColor" />
  </SvgIcon>
);
