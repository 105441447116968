import React from 'react';
import { createUseStyles } from 'react-jss';
import { observer } from 'mobx-react';
import { useSearch } from '../../../../shared/utils/hooks';
import { SOFTWARE_FORM_INITIAL_STATE } from '../../../../stores/form/software/defaults';
import { useAppStore } from '../../../../stores/root/root.context';
import { IPortalSoftware, IPortalSoftwareStatus, PortalSoftwareOrdereBy } from '../../../../stores/software/types';
import { Order } from '../../../../stores/types';
import { SoftwareDialog } from '../../../components/dialog-software/dialog-software.view';
import { Button } from '../../../components/ui/button';
import { useDialogState } from '../../../components/ui/dialog/dialog.hook';
import { AddIcon } from '../../../components/ui/icons/add';
import { SearchIcon } from '../../../components/ui/icons/search';
import { Menu } from '../../../components/ui/menu';
import { MenuItem } from '../../../components/ui/menu/menu-item.view';
import { useMenuState } from '../../../components/ui/menu/menu.hook';
import { onErrorSnackbar, snackbar } from '../../../components/ui/snackbar/snackbar.utils';
import { Stack } from '../../../components/ui/stack';
import { Table } from '../../../components/ui/table';
import { TablePagination, useTablePaginationState } from '../../../components/ui/table-pagination';
import { TextField } from '../../../components/ui/text-field';
import { getSoftwareListColumns } from './software-list.columns';


const PREFIX = 'SoftwareListPage';
const useStyles = createUseStyles(
  () => ({
    root: {},
    header: {
      flex: 1,
      marginTop: 24,
      width: '100%',
      marginBottom: 24,
    },
  }),
  { name: PREFIX },
);

export const SoftwareListPage: React.FC = observer(() => {
  const classes = useStyles();
  const { portal, softwareForm: form } = useAppStore();

  const [selectedSoftware, setSelectedSoftware] = React.useState<IPortalSoftware | undefined>();
  const [readOnly, setReadOnly] = React.useState<boolean>(false);

  const menuState = useMenuState();
  const search = useSearch();
  const pagination = useTablePaginationState(0, 10);
  const softwareDialog = useDialogState();

  const [createSoftware] = portal.createSoftware.useMutation({
    onError: onErrorSnackbar,
  });
  const [deleteSoftware] = portal.deleteSoftware.useMutation({
    onError: onErrorSnackbar,
  });
  const [acceptSoftware] = portal.acceptSoftware.useMutation({
    onError: onErrorSnackbar,
  });
  const [declineSoftware] = portal.declineSoftware.useMutation({
    onError: onErrorSnackbar,
  });
  const [updateSoftware] = portal.updateSoftware.useMutation({
    onError: onErrorSnackbar,
  });

  const { data, loading, refetch } = portal.softwareList.useQuery({
    variables: {
      limit: pagination.rowsPerPage,
      offset: pagination.page * pagination.rowsPerPage,
      title: search.debounced,
      sort: '-status,-created_at',
    },
  });

  const handleSoftwareView = (): void => {
    menuState.onClose();

    if (selectedSoftware) {
      form.load(selectedSoftware);
      softwareDialog.open();
      setReadOnly(true);
    }
  };

  const handleSoftwareEdit = (): void => {
    menuState.onClose();

    if (selectedSoftware) {
      form.load(selectedSoftware);
      softwareDialog.open();
      setReadOnly(false);
    }
  };

  const handleSave = (): void => {
    const formValues = form.getArgs();

    if (!selectedSoftware) {
      createSoftware({ ...formValues }).then((): void => {
        refetch();
        snackbar({ message: 'ПО создано!', severity: 'success' });
        softwareDialog.close();
      });
    }
    else {
      updateSoftware({
        ...formValues,
        _id: selectedSoftware._id,
      }).then((): void => {
        refetch();
        snackbar({ message: 'ПО обновлено!', severity: 'success' });
        softwareDialog.close();
      });
    }
  };

  const handleDelete = (_id: string): void => {
    deleteSoftware({ _id }).then((): void => {
      refetch();
      snackbar({ message: 'ПО удалено!', severity: 'success' });
    });
  };
  const handleAccept = (_id: string): void => {
    acceptSoftware({ _id }).then((): void => {
      refetch();
      snackbar({ message: 'ПО обновлено!', severity: 'success' });
    });
  };
  const handleDecline = (_id: string): void => {
    declineSoftware({ _id }).then((): void => {
      refetch();
      snackbar({ message: 'ПО обновлено!', severity: 'success' });
    });
  };

  const columns = getSoftwareListColumns({
    onMore:
      (record) => (e): void => {
        e.stopPropagation();
        e.preventDefault();

        if (menuState.open) {
          menuState.onClose();
        }
        else {
          menuState.show(e);
          setSelectedSoftware(record);
        }
      },
  });

  return (
    <div className={classes.root}>
      <Stack direction="row" justifyContent="space-between" className={classes.header}>
        <TextField
          style={{ maxWidth: 480 }}
          fullWidth
          placeholder="Поиск по названию"
          InputProps={{ endAdornment: <SearchIcon /> }}
          value={search.value}
          onChange={(e): void => {
            search.change(e.target.value);
          }}
        />

        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={(): void => {
            form.reset(SOFTWARE_FORM_INITIAL_STATE);
            setSelectedSoftware(undefined);
            softwareDialog.open();
          }}
        >
          Добавить новую запись
        </Button>
      </Stack>

      <Table<IPortalSoftware>
        columns={columns}
        loading={loading}
        rowKey="_id"
        data={data?.items}
        hover
        footer={(): JSX.Element => (
          <TablePagination
            total={data?.totalCount || 0}
            page={pagination.page}
            rowsPerPage={pagination.rowsPerPage}
            onChangePage={pagination.onChangePage}
            onChangeRowsPerPage={pagination.onChangeRowsPerPage}
          />
        )}
      />

      {selectedSoftware && <Menu {...menuState.props} placement="bottom-right">
        {selectedSoftware.status === IPortalSoftwareStatus.published &&
          <MenuItem onClick={handleSoftwareView}>
          Посмотреть
          </MenuItem>}
        {selectedSoftware.status === IPortalSoftwareStatus.published &&
          <MenuItem
            onClick={(): void => {
              menuState.onClose();
              handleDecline(selectedSoftware._id);
            }}
          >
          Снять с публикации
          </MenuItem>}
        {selectedSoftware.status === IPortalSoftwareStatus.draft &&
          <MenuItem onClick={handleSoftwareEdit}>
          Редактировать
          </MenuItem>}
        {selectedSoftware.status === IPortalSoftwareStatus.draft &&
          <MenuItem
            onClick={(): void => {
              menuState.onClose();
              handleAccept(selectedSoftware._id);
            }}
          >
          Опубликовать
          </MenuItem>}
        {selectedSoftware.status === IPortalSoftwareStatus.draft &&
          <MenuItem
            onClick={(): void => {
              menuState.onClose();
              handleDelete(selectedSoftware._id);
            }}
          >
            Удалить
          </MenuItem>}
      </Menu>}

      <SoftwareDialog {...softwareDialog.props} readOnly={readOnly} onSubmit={handleSave} />
    </div>
  );
});
