import React from 'react';

const getTextRowsStyles = (rows: number): React.CSSProperties => ({
  WebkitLineClamp: rows,
  WebkitBoxOrient: 'vertical',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  display: '-webkit-box',
});

export { getTextRowsStyles };
