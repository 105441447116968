export type IPortalEvent<TDate = Date> = {
  _id: string;

  title: string;
  description: string;
  desktop_cover: string;
  tablet_cover: string;
  mobile_cover: string;
  status: IPortalEventStatus;

  created_at: TDate;
  updated_at: TDate;
};

export type ICreateEvent = {
  title: string;
  description: string;
  desktop_cover: string;
  tablet_cover: string;
  mobile_cover: string;
};

export type IMainEvent = {
  _id: string;

  title: string;
  description: string;
  desktop_cover: string;
  tablet_cover: string;
  mobile_cover: string;

  status: IPortalEventStatus;
};

export enum IPortalEventStatus {
  published = 'published',
  draft = 'draft',
}

export enum PortalEventOrderBy {
  title = 'title',
  status = 'status',
}

export type IPortalNewsAdd = {
  _id: string,
  title: string,
  created_at: Date,
  status: string,
};
