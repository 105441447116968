import { makeAutoObservable } from 'mobx';
import { EnvKeys, IConfigStore } from './config.models';

export class ConfigStore implements IConfigStore {

  public readonly PORTAL_API: string;
  public readonly PORTAL_API_BASE: string;

  // public readonly websocketURL: string;
  // public readonly imagesStorage: string;
  // public readonly filesStorage: string;

  constructor() {
    // const storage = this.getRequired(EnvKeys.fileStorage);

    // this.websocketURL = this.getRequired(EnvKeys.websocketURL);
    this.PORTAL_API = `${this.getValue(EnvKeys.PORTAL_API) ?? ''}/api/v1/admin`;
    this.PORTAL_API_BASE = `${this.getValue(EnvKeys.PORTAL_API) ?? ''}/api/v1`;
    // this.imagesStorage = `${storage}/images`;
    // this.filesStorage = `${storage}/files`;

    makeAutoObservable(this);
  }

  private getValue(key: EnvKeys): string | undefined {
    const value = process.env[key];
    return value;
  }

  private getRequired(key: EnvKeys): string {
    const value = this.getValue(key);
    if (typeof value !== 'string' || value.length === 0) {
      throw new Error(`Cannot find "${key}" parameter from environment`);
    }
    return value;
  }


}
