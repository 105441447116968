import { IPortalStore } from '..';
import { convertSection } from './section.mappers';
import { IPortalSection } from './types';

export type IPortalFindSectionArgs = {
  limit?: number;
  offset?: number;
  system?: string;
  sort?: string;
  title?: string;
};

export type IPortalFindSectionRes<TDate = Date> = {
  items: IPortalSection<TDate>[],
  totalCount: number,
};

export type IPortalUpdateSectionArgs = Omit<IPortalSection,
| 'created_at'
| 'updated_at'
| 'title'
| 'system'
>;
export type IPortalUpdateSectionRes = void;

export type IPortalCreateSectionArgs = {
  title: string;
  system: string;
};
export type IPortalCreateSectionRes<TDate = Date> = IPortalSection<TDate>;

export type IPortalDeleteSectionArgs = { _id: string };
export type IPortalDeleteSectionRes = void;

export class PortalSection {

  protected portal: IPortalStore;

  constructor(portal: IPortalStore) {
    this.portal = portal;
  }

  public async create(args: IPortalCreateSectionArgs): Promise<IPortalCreateSectionRes> {
    const res = await this.portal
      .jsonRequest<IPortalCreateSectionRes<string>>({
      method: 'POST',
      url: '/section/create',
      data: args,
    });

    return convertSection(res);
  }

  public async delete(args: IPortalDeleteSectionArgs): Promise<IPortalDeleteSectionRes> {
    await this.portal.textRequest({
      method: 'DELETE',
      url: `section/delete/${args._id}`,
      data: args,
    });
  }

  public async find(args: IPortalFindSectionArgs): Promise<IPortalFindSectionRes> {
    const res = await this.portal
      .jsonRequest<IPortalFindSectionRes<string>>({
      method: 'GET',
      url: '/section/all',
      data: args,
    });

    return {
      items: res.items.map(convertSection),
      totalCount: res.totalCount,
    };
  }

  public async update(args: IPortalUpdateSectionArgs): Promise<IPortalUpdateSectionRes> {
    await this.portal.textRequest({
      method: 'PATCH',
      url: '/section/update',
      data: args,
    });
  }

}
