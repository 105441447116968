import React from 'react';
import { ColumnsType } from 'rc-table/lib/interface';
import { IPortalSection } from '../../../../stores/section/types';
import { tableCellMore } from '../../../components/ui/table/table.columns';
import { Typography } from '../../../components/ui/typography';

export const getSectionListColumns = (opts: {
  onMore: (section: IPortalSection) => (e: React.MouseEvent<HTMLElement>) => void;
}): ColumnsType<IPortalSection> => {
  const columns: ColumnsType<IPortalSection> = [
    {
      title: 'Наименование раздела',
      width: 500,
      render: (value, record) => <Typography style={{ wordWrap: 'break-word' }}>{record.title}</Typography>,
    },
    {
      title: 'Наименование системы',
      width: 500,
      render: (value, record) => <Typography>
        {/* @ts-ignore */}
        {record.system?.title}
      </Typography>
      ,
    },
    tableCellMore(opts.onMore),
  ];

  return columns;
};
