import React from 'react';
import { createUseStyles } from 'react-jss';
import cn from 'classnames';
import { StackProps } from './stack.models';

const PREFIX = 'Stack';
const useStyles = createUseStyles(
  () => ({
    root: {
      display: 'flex',
    },
    column: {
      flexDirection: 'column',
    },
  }),
  { name: PREFIX },
);

export const Stack: React.FC<StackProps> = (exampleProps) => {
  const {
    key,
    className,
    style = {},
    spacing,
    direction,
    divider,
    children,
    justifyContent,
    alignItems,
    ...other
  } = exampleProps;

  const classes = useStyles();
  const rootCN = cn(classes.root, { [classes.column]: direction === 'column' }, className);

  const arr = Array.isArray(children) ? children : [children].filter(Boolean);
  const content = React.useMemo(
    () => (divider
      ? arr
        .filter(Boolean)
        .map((el) => [el, divider])
        .flatMap((el) => el)
        .slice(0, -1)
      : arr),
    [children, divider],
  );

  return (
    <div
      className={rootCN}
      style={{
        ...style,
        gap: spacing,
        justifyContent: justifyContent || style.justifyContent,
        alignItems: alignItems || style.alignItems,
      }}
      {...other}
    >
      {content.map((el, i) => (
        <React.Fragment key={`stack-item-${key}-${i}`}>{el}</React.Fragment>
      ))}
    </div>
  );
};
