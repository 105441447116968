import React from 'react';
import { Outlet, RouteObject } from 'react-router-dom';
import { AppLayout } from '../views/components/layout';
import { AdvertisingBlock } from '../views/content/advertising';
import { KnowledgeBaseBlock } from '../views/content/knowledge-base/knowledge-base-block.view';
import { MainEventListPage } from '../views/content/main-event';
import { NewsActivityBlock } from '../views/content/news-activity';
import { PageNotFound } from '../views/lab/page-not-found';
import { BrowserRoute } from './browser.routes';


export const appRoutesDefinition: RouteObject[] = [
  {
    path: '/',
    element: (
      <AppLayout>
        <Outlet />
      </AppLayout>
    ),
    children: [
      { path: '*', element: <PageNotFound /> },
      { path: BrowserRoute.main, element: <MainEventListPage /> },
      { path: BrowserRoute.mainEvents, element: <MainEventListPage /> },
      { path: BrowserRoute.news, element: <NewsActivityBlock /> },
      { path: BrowserRoute.activity, element: <NewsActivityBlock /> },
      { path: BrowserRoute.course, element: <AdvertisingBlock /> },
      { path: BrowserRoute.software, element: <AdvertisingBlock /> },
      { path: BrowserRoute.systems, element: <KnowledgeBaseBlock /> },
      { path: BrowserRoute.sections, element: <KnowledgeBaseBlock /> },
      { path: BrowserRoute.questions, element: <KnowledgeBaseBlock /> },
    ],
  },
];
