import axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { PortalActivity } from './activity';
import { PortalCourse } from './course';
import { PortalEvent } from './event';
import { PortalNews } from './news';
import { PortalQuestion } from './question';
import { PortalSection } from './section';
import { PortalSoftware } from './software';
import { PortalSystem } from './system';
import { PortalTag } from './tag';
import { PortalUser } from './user';
import { IRequestOptions } from './utils';

export class IPortalStore {

  public readonly baseURL: string;
  public token?: string;

  public readonly user: PortalUser;

  public readonly event: PortalEvent;
  public readonly news: PortalNews;
  public readonly activity: PortalActivity;
  public readonly course: PortalCourse;
  public readonly tag: PortalTag;
  public readonly software: PortalSoftware;
  public readonly system: PortalSystem;
  public readonly section: PortalSection;
  public readonly question: PortalQuestion;

  constructor(baseURL: string, token?: string) {
    this.baseURL = baseURL;
    this.token = token;

    this.user = new PortalUser(this);

    this.event = new PortalEvent(this);
    this.news = new PortalNews(this);
    this.activity = new PortalActivity(this);
    this.course = new PortalCourse(this);
    this.tag = new PortalTag(this);
    this.software = new PortalSoftware(this);
    this.system = new PortalSystem(this);
    this.section = new PortalSection(this);
    this.question = new PortalQuestion(this);
  }

  public textRequest = async(options: IRequestOptions): Promise<string> => {
    const headers: AxiosRequestHeaders = {
      ...options.headers,
    };
    if (typeof this.token === 'string') {
      headers.authorization = this.token;
    }

    const config: AxiosRequestConfig = {
      baseURL: this.baseURL,
      responseType: 'text',
      ...options,
      headers,
      transformResponse: (res) => res,
    };

    if (options.method === 'GET') {
      config.params = config.data;
    }

    const response = await axios(config);

    if (config.responseType === 'text' && typeof response.data !== 'string') {
      throw new Error('Invalid response data');
    }

    return response.data;
  };

  public jsonRequest = async <T>(options: IRequestOptions): Promise<T> => {
    const response = await this.textRequest(options);
    const data: T = JSON.parse(response);

    return data;
  };

  public blobRequest = async(options: IRequestOptions): Promise<Blob> => {
    const headers: AxiosRequestHeaders = {
      ...options.headers,
    };
    if (typeof this.token === 'string') {
      headers.authorization = this.token;
    }

    const config: AxiosRequestConfig = {
      baseURL: this.baseURL,
      responseType: 'blob',
      ...options,
      headers,
      // https://stackoverflow.com/questions/41013082/disable-json-parsing-in-axios
      transformResponse: (res) => res,
    };

    if (options.method === 'GET') {
      config.params = config.data;
    }

    const response = await axios(config);

    return response.data;
  };

}
