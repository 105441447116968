/**
 * BrowserRoute - класс, содержащий статические свойства и методы для роутинга приложения.
 * @class
 */
export class BrowserRoute {

  public static main = '/';

  public static notFound = '/not-found';

  public static mainEvents = '/main-events';

  public static news = '/news';

  public static activity = '/activity';

  public static course = '/course';

  public static software = '/software';

  public static systems = '/systems';

  public static sections = '/sections';

  public static questions = '/questions';

}
