import React from 'react';

export const useTabs = (defaultTab: string): {
  value: string;
  onChange: (tab: string) => void
} => {
  const [tab, setTab] = React.useState(defaultTab);

  return {
    value: tab, onChange: (v: string): void => setTab(v),
  };
};
