import React, { FC, PropsWithChildren } from 'react';
import cn from 'classnames';
import { createUseStyles } from '../theme/createUseStyles';

export type IBackdropProps = React.HTMLAttributes<HTMLDivElement> & { open?: boolean };

const PREFIX = 'Backdrop';

const useStyles = createUseStyles(
  (theme) => ({
    root: {
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      backgroundColor: 'rgba(68, 64, 60, 0.5)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: 'all',
      transitionDuration: '.15s',
      transitionProperty: 'all',
      transitionTimingFunction: 'cubic-bezier(.4,0,.2,1)',
      zIndex: theme.zIndex.dialog,
    },
    wrapperHidden: {
      display: 'none',
    },
    wrapperFixed: {
      position: 'fixed',
    },
  }),
  { name: PREFIX },
);

export const Backdrop: FC<PropsWithChildren<IBackdropProps>> = (modalProps) => {
  const { className, children, open, ...other } = modalProps;

  const classes = useStyles();

  const wrapperCN = cn(
    classes.root,
    {
      [classes.wrapperFixed]: open,
      [classes.wrapperHidden]: !open,
    },
    className,
  );

  return (
    <div className={wrapperCN} {...other}>
      {children}
    </div>
  );
};
