import React, { FC } from 'react';
import { SvgIcon, SvgIconProps } from '../svg-icon';

export const FolderIcon: FC<SvgIconProps> = ({ style = {}, ...props }) => (
  <SvgIcon
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: 15, height: 15, ...style }}
    {...props}
  >
    <path d="M0.75 3.7282C0.75 2.89868 0.751198 2.34165 0.800335 1.92087C0.847583 1.51626 0.930335 1.33515 1.02698 1.21531C1.08287 1.14601 1.14601 1.08287 1.21531 1.02698C1.33515 0.930335 1.51626 0.847583 1.92087 0.800335C2.34165 0.751198 2.89868 0.75 3.7282 0.75C4.47532 0.75 4.67272 0.759682 4.84444 0.819436L5.09093 0.111098L4.84444 0.819436C4.8859 0.833864 4.92658 0.850466 4.96629 0.869174C5.13077 0.946651 5.27856 1.07787 5.81228 1.6007L6.18379 1.96463L6.70863 1.42886L6.18379 1.96463C6.21249 1.99274 6.24082 2.02054 6.26881 2.04802C6.76041 2.53049 7.14944 2.91231 7.65211 3.11749C8.15477 3.32267 8.69986 3.32215 9.38866 3.3215C9.42789 3.32147 9.46758 3.32143 9.50775 3.32143H10C10.964 3.32143 11.6116 3.32302 12.0946 3.38796C12.5561 3.45001 12.7536 3.55723 12.8839 3.68754L13.4142 3.15722L12.8839 3.68755C13.0142 3.81786 13.1214 4.01536 13.1835 4.47682C13.2484 4.95986 13.25 5.60742 13.25 6.57143V8C13.25 8.96401 13.2484 9.61157 13.1835 10.0946C13.1214 10.5561 13.0142 10.7536 12.8839 10.8839C12.7536 11.0142 12.5561 11.1214 12.0946 11.1835C11.6116 11.2484 10.964 11.25 10 11.25H4C3.03599 11.25 2.38843 11.2484 1.90539 11.1835C1.44393 11.1214 1.24643 11.0142 1.11612 10.8839C0.9858 10.7536 0.878577 10.5561 0.816535 10.0946C0.751593 9.61157 0.75 8.96401 0.75 8V3.7282Z" stroke="currentColor" stroke-width="1.5" />
  </SvgIcon>
);
