import { ColumnsType } from 'rc-table/lib/interface';
import { tagStatusToLocale } from '../../../../../shared/utils/format';
import { toHumanDate } from '../../../../../shared/utils/human-date';
import { IPortalTag, IPortalTagStatus } from '../../../../../stores/tag/types';
import { tableCellMore } from '../../../../components/ui/table/table.columns';
import { Typography } from '../../../../components/ui/typography';

export const getTagListColumns = (opts: { onMore: (course: IPortalTag) => (e: React.MouseEvent<HTMLElement>) => void;
}): ColumnsType<IPortalTag> => {
  const columns: ColumnsType<IPortalTag> = [
    {
      title: 'Название',
      width: 200,
      render: (value, record) => <Typography style={{ wordWrap: 'break-word' }}>{record.title}</Typography>,
    },
    {
      title: 'Дата создания/обновления',
      width: 200,
      render: (value, record) => <Typography>{toHumanDate(record.created_at)}</Typography>,
    },
    {
      title: 'Статус',
      width: 200,
      render: (value, record) => <Typography style={{ color: record.status === IPortalTagStatus.published ? '#73D071' : '#BDBDBD' }}>{tagStatusToLocale(record.status)}</Typography>,
    },
    tableCellMore(opts.onMore),
  ];

  return columns;
};
