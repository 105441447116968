import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { Header } from '@unione-pro/unione.commons.webpack';

const PREFIX = 'LayoutSimple';
const useStyles = createUseStyles(
  (theme) => ({
    root: {
      display: 'flex',
      height: '100%',
      minHeight: 'calc(100vh - 64px)',
      width: '100%',
      alignItems: 'center',
      flexDirection: 'column',
      overflowX: 'inherit',
      backgroundColor: theme.palette.background.default,
    },
    content: {
      width: '100%',
      height: '100%',
    },
  }),
  { name: PREFIX },
);

export const LayoutSimple: FC<{
  children: React.ReactNode;
}> = (props) => {
  const classes = useStyles();

  return (
    <>
      <Header />

      <main className={classes.root}>
        <div className={classes.content}>{props.children}</div>
      </main>
    </>
  );
};
