import React from 'react';
import cn from 'classnames';
import { Divider } from '../divider';
import { InfoOutlinedIcon } from '../icons/info-outlined';
import { Paper } from '../paper';
import { createUseStyles } from '../theme/createUseStyles';
import { Typography } from '../typography';
import { AlertProps } from './alert.models';

const PREFIX = 'Alert';

const useStyles = createUseStyles(
  (theme) => ({
    root: {
      padding: '24px 32px',
      borderRadius: theme.shape.borderRadius,
      display: 'flex',
      alignItems: 'center',
      boxShadow: 'none',
      justifyContent: 'space-between',

      '& > svg': {
        width: 24,
        height: 24,
      },
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      wordBreak: 'break-word',
    },
    contentHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      rowGap: 14,
    },
    left: {
      display: 'flex',
      alignItems: 'center',
      gap: 16,
    },
    contentComment: {
      display: 'flex',
      flexDirection: 'column',
    },
    iconBox: {
      height: 24,
    },
    colorError: {},
    colorInfo: {},
    colorWarning: {},
    colorSuccess: {},
    variantOutlined: {
      border: 'solid 1px',
      borderRadius: theme.shape.borderRadius * 4,
      borderColor: '#E4E7F2',

      '& $iconBox': {
        width: 32,
        height: 32,
        padding: 6,
        borderRadius: '100%',
      },

      '&$colorWarning': {
        background: theme.palette.action.hover,
        color: theme.palette.text.secondary,

        '& $iconBox': {
          background: theme.palette.warning.main,
          '& > svg': {
            width: 20,
            height: 20,
            color: theme.palette.warning.contrastText,
          },
        },
      },
      '&$colorSuccess': {
        background: theme.palette.success.contrastText,
        color: theme.palette.text.secondary,

        '& $iconBox': {
          background: theme.palette.success.main,
          '& > svg': {
            width: 20,
            height: 20,
            color: theme.palette.success.contrastText,
          },
        },
      },
      '&$colorInfo': {
        background: theme.palette.info.contrastText,
        color: theme.palette.text.secondary,

        '& $iconBox': {
          background: theme.palette.info.main,
          '& > svg': {
            width: 20,
            height: 20,
            color: theme.palette.info.contrastText,
          },
        },
      },
      '&$colorError': {
        background: theme.palette.error.contrastText,
        color: theme.palette.text.secondary,

        '& $iconBox': {
          background: theme.palette.error.main,
          '& > svg': {
            width: 20,
            height: 20,
            color: theme.palette.error.contrastText,
          },
        },
      },
    },
    variantContained: {
      '&$colorError': {
        background: theme.palette.error.main,
        color: theme.palette.error.contrastText,
      },
      '&$colorInfo': {
        background: theme.palette.info.main,
        color: theme.palette.info.contrastText,
      },
      '&$colorWarning': {
        background: theme.palette.warning.main,
        color: theme.palette.warning.contrastText,
      },
      '&$colorSuccess': {
        background: 'linear-gradient(90deg, #51D54F 0%, #15B012 100%)',
        color: theme.palette.success.contrastText,
      },
    },
    sizeSmall: {
      padding: '16px 24px',
    },
  }),
  { name: PREFIX },
);

export const Alert: React.FC<AlertProps> = (paperProps) => {
  const {
    className,
    severity = 'info',
    children,
    variant = 'contained',
    right,
    comment,
    size = 'medium',
    ...other
  } = paperProps;

  const classes = useStyles();
  const rootCN = cn(
    classes.root,
    {
      [classes.colorInfo]: severity === 'info',
      [classes.colorWarning]: severity === 'warning',
      [classes.colorError]: severity === 'error',
      [classes.colorSuccess]: severity === 'success',
      [classes.variantOutlined]: variant === 'outlined',
      [classes.variantContained]: variant === 'contained',
      [classes.sizeSmall]: size === 'small',
    },
    className,
  );

  return (
    <Paper className={rootCN} {...other}>
      <div className={classes.content}>
        <div className={classes.contentHeader}>
          <div className={classes.left}>
            <div className={classes.iconBox}>
              <InfoOutlinedIcon />
            </div>
            {children}
          </div>

          {right}
        </div>
        {comment && (
          <div className={classes.contentComment}>
            <Divider style={{ margin: '24px 0px', width: '100%' }} />
            <div>
              <Typography variant="label" style={{ marginBottom: 8 }}>
                Комментарий
              </Typography>
              <Typography>{comment}</Typography>
            </div>
          </div>
        )}
      </div>
    </Paper>
  );
};
