import { createUseStyles } from 'react-jss';
import { observer } from 'mobx-react';
import { useAppStore } from '../../../stores/root/root.context';
import { ControlBlock } from '../control-block';
import { Button } from '../ui/button';
import { IconButton } from '../ui/icon-button';
import { AddIcon } from '../ui/icons/add';
import { CloseIcon } from '../ui/icons/close';
import { Stack } from '../ui/stack';
import { TextField } from '../ui/text-field';

const PREFIX = 'DialogCreateActivityTags';
const useStyles = createUseStyles(() => ({
  root: {
    maxHeight: 330,
    overflowY: 'auto',
    marginTop: 32,
  },
}), { name: PREFIX });

type DialogCreateActivityTagsProps = {
  readOnly?: boolean;
};


export const DialogCreateActivityTags: React.FC<DialogCreateActivityTagsProps> = observer((props) => {
  const { readOnly } = props;
  const { activityForm: form } = useAppStore();
  const classes = useStyles();

  const tags = form.getFieldValue('tags') as string[];

  return (
    <Stack
      className={classes.root}
      direction="column"
      spacing={24}
    >
      {tags.map((tag, index: number) => (
        <Stack key={`additional-link-${index}`} direction="row" spacing={4} alignItems="center">
          <ControlBlock
            label={`Тег #${index + 1}`}
            control={
              <TextField
                fullWidth
                placeholder="Ввод текста"
                value={tag}
                onChange={(e): void => form.updateTag(index, e.target.value)}
                error={!!form.getFieldError(`tags[${index}]`)}
                helperText={form.getFieldError(`tags[${index}]`)}
                readOnly={readOnly}
              />
            }
          />
          {tags.length > 1 &&
            <IconButton onClick={(): void => form.deleteTag(index)} style={{ marginBottom: 0, marginTop: 'auto' }}>
              <CloseIcon style={{ width: 24, height: 24, color: '#909EBB', marginBottom: 0, marginTop: 0 }} />
            </IconButton>
          }
        </Stack>
      ))}
      {!readOnly && <Button
        startIcon={<AddIcon />}
        variant="outlined"
        color="primary"
        onClick={(): void => form.addTag()}
        style={{ justifyContent: 'center', border: 'none', color: '#0071CE', fontSize: '16px', marginTop: '4px' }}
      >
        добавить тег
      </Button>}
    </Stack>
  );
});
