import React, { FC } from 'react';
import cn from 'classnames';
import Color from 'color';
import { CircularProgress } from '../circular-progress';
import { createUseStyles } from '../theme/createUseStyles';
import { IButtonProps } from './button.models';

const PREFIX = 'Button';

const useStyles = createUseStyles(
  (theme) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',

      transitionDuration: theme.transitions.duration.fast,
      border: '1px solid transparent',
      borderRadius: theme.shape.borderRadius,
      margin: 0,
      fontWeight: theme.typography.fontWeightMedium,
      fontFamily: theme.typography.fontFamily,
      appearance: 'none',
      outline: 'none',
      cursor: 'pointer',
      padding: '8px 16px',
      ...theme.typography.button,
      width: 'fit-content',

      '&&$disabled': {
        backgroundColor: theme.palette.action.disabledBackground,
        color: theme.palette.action.disabled,
        pointerEvents: 'none',
        cursor: 'default',
      },
      '&&$loading': {
        color: 'transparent',
      },
    },
    disabled: {},
    sizeNone: {
      padding: 0,
    },
    sizeSmall: {
      padding: '8px 16px',
    },
    sizeLarge: {
      padding: '12px 24px',
      ...theme.typography.subtitle1,
    },
    colorPrimary: {},
    colorSuccess: {},
    colorWarning: {},
    colorError: {},
    colorGray: {},
    colorWhite: {},
    colorPrimaryGradient: {
      backgroundSize: '200%',
      backgroundImage: 'linear-gradient(135deg, #007BE0, #0058A0, #007BE0)',
      color: theme.palette.primary.contrastText,

      '&:hover': {
        backgroundPosition: '100%',
      },
    },
    variantContained: {
      '&$colorError': {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,

        '&:hover': {
          backgroundColor: Color(theme.palette.error.main).darken(0.3).toString(),
        },
      },
      '&$colorWarning': {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.warning.contrastText,

        '&:hover': {
          backgroundColor: Color(theme.palette.warning.main).darken(0.3).toString(),
        },
      },
      '&$colorSuccess': {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.success.contrastText,

        '&:hover': {
          backgroundColor: Color(theme.palette.success.main).darken(0.3).toString(),
        },
      },
      '&$colorPrimary': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,

        '&:hover': {
          backgroundColor: Color(theme.palette.primary.main).darken(0.3).toString(),
        },
      },
      '&$colorWhite': {
        backgroundColor: '#ffffff',
        color: '#000000',
      },
    },
    variantOutlined: {
      backgroundColor: 'transparent',
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,

      '&$disabled': {
        borderColor: '#E4E7F2',
      },

      '&:hover': {
        backgroundColor: Color(theme.palette.primary.main).alpha(0.05).toString(),
      },
    },
    variantStandard: {
      backgroundColor: 'transparent',

      '&$colorPrimary': {
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.primary.main,

        '&:hover': {
          backgroundColor: Color(theme.palette.primary.main).alpha(0.1).toString(),
        },
      },
      '&$colorGray': {
        color: '#727783',
        '&:hover': {
          backgroundColor: Color('#727783').alpha(0.1).toString(),
        },
      },
      '&$colorError': {
        color: theme.palette.error.main,

        '&:hover': {
          backgroundColor: Color(theme.palette.error.main).alpha(0.1).toString(),
        },
      },
    },
    variantGradient: {
      '&$colorPrimary': {
        backgroundSize: '200%',
        backgroundImage: 'linear-gradient(135deg, #007BE0, #0058A0, #007BE0)',
        color: theme.palette.primary.contrastText,

        '&:hover': {
          backgroundImage: 'linear-gradient(135deg, #0058A0, #0058A0, #0058A0)',
        },
      },
    },
    startIcon: {
      marginRight: 4,
      height: 16,

      '& > svg': {
        width: 16,
        height: 16,
      },
    },
    endIcon: {
      marginLeft: 4,
      height: 16,

      '& > svg': {
        width: 16,
        height: 16,
      },
    },
    fullWidth: {
      width: '100%',
    },
    loading: {

    },
    loader: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 1,

      '& > *': {
        width: 44,
        height: 44,
      },
    },
  }),
  { name: PREFIX },
);

export const Button: FC<IButtonProps> = React.forwardRef((props, ref) => {
  const {
    color = 'primary',
    variant = 'contained',
    size,
    className,
    disabled,
    startIcon,
    children,
    endIcon,
    component = 'button',
    fullWidth,
    type = 'button',
    onClick,
    loading,
    ...other
  } = props;

  const classes = useStyles();

  const rootCN = cn(
    classes.root,
    {
      [classes.variantStandard]: variant === 'standard',
      [classes.variantContained]: variant === 'contained',
      [classes.variantOutlined]: variant === 'outlined',
      [classes.variantGradient]: variant === 'gradient',
      [classes.colorPrimary]: color === 'primary',
      [classes.colorWarning]: color === 'warning',
      [classes.colorError]: color === 'error',
      [classes.colorSuccess]: color === 'success',
      [classes.colorGray]: color === 'gray',
      [classes.sizeSmall]: size === 'small',
      [classes.sizeLarge]: size === 'large',
      [classes.disabled]: disabled || loading,
      [classes.loading]: loading,
      [classes.fullWidth]: fullWidth,
    },
    className,
  );

  return React.createElement(component, {
    ...other,
    ref,
    type,
    disabled,
    className: rootCN,
    onClick: disabled || loading ? undefined : onClick,
    children: (
      <>
        {startIcon && <div className={classes.startIcon}>{startIcon}</div>}
        {children}
        {endIcon && <div className={classes.endIcon}>{endIcon}</div>}
        {loading && (
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        )}
      </>
    ),
  });
});
