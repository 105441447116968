import React from 'react';
import { createUseStyles } from 'react-jss';
import { observer } from 'mobx-react';
import { eventStatusToLocale } from '../../../shared/utils/format';
import { useSearch } from '../../../shared/utils/hooks';
import { IPortalEvent, PortalEventOrderBy } from '../../../stores/event/types';
import { EVENT_FORM_INITIAL_STATE } from '../../../stores/form/event/defaults';
import { useAppStore } from '../../../stores/root/root.context';
import { Order } from '../../../stores/types';
import { EventDialog } from '../../components/dialog-event';
import { Button } from '../../components/ui/button';
import { useDialogState } from '../../components/ui/dialog/dialog.hook';
import { AddIcon } from '../../components/ui/icons/add';
import { SearchIcon } from '../../components/ui/icons/search';
import { Menu } from '../../components/ui/menu';
import { MenuItem } from '../../components/ui/menu/menu-item.view';
import { useMenuState } from '../../components/ui/menu/menu.hook';
import { Paper } from '../../components/ui/paper';
import { onErrorSnackbar, snackbar } from '../../components/ui/snackbar/snackbar.utils';
import { Stack } from '../../components/ui/stack';
import { Table } from '../../components/ui/table';
import { TablePagination, useTablePaginationState } from '../../components/ui/table-pagination';
import { TextField } from '../../components/ui/text-field';
import { Typography } from '../../components/ui/typography';
import { getUserListColumns } from './main-event-list.columns';


const PREFIX = 'MainEventListPage';
const useStyles = createUseStyles(
  () => ({
    root: {
      width: '100%',
    },
  }),
  { name: PREFIX },
);

export const MainEventListPage: React.FC = observer(() => {
  const classes = useStyles();
  const { portal, eventForm: form } = useAppStore();

  const [selectedEvent, setSelectedEvent] = React.useState<IPortalEvent | undefined>();
  const [readOnly, setReadOnly] = React.useState<boolean>(false);

  const menuState = useMenuState();
  const search = useSearch();
  const pagination = useTablePaginationState(0, 10);
  const eventDialog = useDialogState();

  const { data, loading, refetch } = portal.eventList.useQuery({
    variables: {
      limit: pagination.rowsPerPage,
      offset: pagination.page * pagination.rowsPerPage,
      title: search.debounced,
      sort: '-created_at',
    },
  });

  const [createEvent] = portal.createEvent.useMutation({
    onError: onErrorSnackbar,
  });
  const [deleteEvent] = portal.deleteEvent.useMutation({
    onError: onErrorSnackbar,
  });
  const [acceptEvent] = portal.acceptEvent.useMutation({
    onError: onErrorSnackbar,
  });
  const [declineEvent] = portal.declineEvent.useMutation({
    onError: onErrorSnackbar,
  });
  const [updateEvent] = portal.updateEvent.useMutation({
    onError: onErrorSnackbar,
  });

  const handleEventView = (): void => {
    menuState.onClose();

    if (selectedEvent) {
      form.load(selectedEvent);
      eventDialog.open();
      setReadOnly(true);
    }
  };

  const handleEventEdit = (): void => {
    menuState.onClose();

    if (selectedEvent) {
      form.load(selectedEvent);
      eventDialog.open();
      setReadOnly(false);
    }
  };

  const handleSave = (): void => {
    const formValues = form.getArgs();

    if (!selectedEvent) {
      createEvent({ ...formValues }).then((): void => {
        refetch();
        snackbar({ message: 'Событие создано!', severity: 'success' });
        eventDialog.close();
      });
    }
    else {
      updateEvent({
        ...formValues,
        _id: selectedEvent._id,
      }).then((): void => {
        refetch();
        snackbar({ message: 'Событие обновлено', severity: 'success' });
        eventDialog.close();
      });
    }
  };

  const handleDelete = (_id: string): void => {
    deleteEvent({ _id }).then((): void => {
      refetch();
      snackbar({ message: 'Событие удалено', severity: 'success' });
    });
  };
  const handleAccept = (_id: string): void => {
    acceptEvent({ _id }).then((): void => {
      refetch();
      snackbar({ message: 'Событие обновлено', severity: 'success' });
    });
  };
  const handleDecline = (_id: string): void => {
    declineEvent({ _id }).then((): void => {
      refetch();
      snackbar({ message: 'Событие обновлено', severity: 'success' });
    });
  };

  const columns = getUserListColumns({
    onMore:
     (record) => (e): void => {
       e.stopPropagation();
       e.preventDefault();

       if (menuState.open) {
         menuState.onClose();
       }
       else {
         menuState.show(e);
         setSelectedEvent(record);
       }
     },
  });

  return (
    <>
      <div className={classes.root}>
        <Paper style={{ padding: 24 }}>
          <Typography variant="h2" fontWeight="bold" style={{ marginBottom: 24 }}>
            Управление главными событиями
          </Typography>

          <div style={{ marginBottom: 16, display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Stack direction="row" spacing={16} style={{ flex: 1 }}>
              <TextField
                style={{ maxWidth: 330 }}
                fullWidth
                placeholder="Поиск по названию"
                InputProps={{ endAdornment: <SearchIcon /> }}
                value={search.value}
                onChange={(e): void => {
                  search.change(e.target.value);
                  // pagination.onChangePage(0);
                }}
              />
            </Stack>

            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={(): void => {
                form.reset(EVENT_FORM_INITIAL_STATE);
                setSelectedEvent(undefined);
                eventDialog.open();
                setReadOnly(false);
              }}
            >
              Добавить новую запись
            </Button>
          </div>
          <Table<IPortalEvent>
            columns={columns}
            loading={loading}
            rowKey="_id"
            data={data?.items}
            hover
            footer={(): JSX.Element => (
              <TablePagination
                style={{ marginTop: 16 }}
                total={data?.totalCount || 0}
                page={pagination.page}
                rowsPerPage={pagination.rowsPerPage}
                onChangePage={pagination.onChangePage}
                onChangeRowsPerPage={pagination.onChangeRowsPerPage}
              />
            )}
          />
        </Paper>
      </div>

      {selectedEvent && <Menu {...menuState.props} placement="bottom-right">
        {eventStatusToLocale(selectedEvent.status) === 'Опубликован' &&
        <MenuItem onClick={handleEventView}>
          Посмотреть
        </MenuItem>}
        {eventStatusToLocale(selectedEvent.status) === 'Опубликован' &&
        <MenuItem
          onClick={(): void => {
            menuState.onClose();
            handleDecline(selectedEvent._id);
          }}
        >
          Снять с публикации
        </MenuItem>}
        {eventStatusToLocale(selectedEvent.status) === 'Черновик' &&
        <MenuItem onClick={handleEventEdit}>
          Редактировать
        </MenuItem>}
        {eventStatusToLocale(selectedEvent.status) === 'Черновик' &&
        <MenuItem
          onClick={(): void => {
            menuState.onClose();
            handleAccept(selectedEvent._id);
          }}
        >
          Опубликовать
        </MenuItem>}
        {eventStatusToLocale(selectedEvent.status) === 'Черновик' &&
        <MenuItem
          onClick={(): void => {
            menuState.onClose();
            handleDelete(selectedEvent._id);
          }}
        >
          Удалить
        </MenuItem>}
      </Menu>}

      <EventDialog {...eventDialog.props} readOnly={readOnly} onSubmit={handleSave} />
    </>
  );
});
