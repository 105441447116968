import React from 'react';
import cn from 'classnames';
import { createUseStyles } from '../theme/createUseStyles';
import { IInputBaseProps } from './input-base.models';

const PREFIX = 'InputBase';

export const useInputBaseStyles = createUseStyles(
  (theme) => ({
    root: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'space-between',
    },
    input: {
      zIndex: 0,
      width: '100%',
      padding: '10px 16px',
      borderWidth: '1px',
      borderRadius: '0.25rem',
      borderStyle: 'solid',
      outline: '2px solid transparent',
      outlineOffset: '2px',
      lineHeight: '1rem',
      background: 'transparent',
      fontFamily: theme.typography.fontFamily,
      ...theme.typography.caption,
      color: theme.palette.text.primary,

      '&:focus-visible': {
        outline: 'none',
      },
    },
    colorDefault: {
      borderColor: '#E4E7F2',
    },
    colorError: {
      borderColor: theme.palette.error.main,
    },
    colorWarning: {
      borderColor: theme.palette.warning.main,
    },
    colorSuccess: {
      borderColor: theme.palette.success.main,
    },
    fullWidth: {
      width: '100%',
    },
    disabled: {
      cursor: 'default',
      backgroundColor: theme.palette.action.disabledBackground,
      color: theme.palette.action.disabled,
    },
    readOnly: {
      borderColor: 'transparent',
      background: '#F5F6F8',
    },
    withStartAdornment: {
      paddingLeft: 32 + 6,
    },
    withEndAdornment: {
      paddingRight: 32 + 6,
    },
    startAdornment: {
      pointerEvents: 'none',
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      width: 32,
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 8,
      color: '#909EBB',

      '& svg': {
        width: 20,
        height: 20,
      },
    },
    endAdornment: {
      pointerEvents: 'none',
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: 32,
      display: 'flex',
      alignItems: 'center',
      paddingRight: 8,
      color: '#909EBB',

      '& svg': {
        width: 20,
        height: 20,
      },
    },
  }),
  { name: PREFIX },
);

export const InputBase: React.FC<IInputBaseProps> = (props) => {
  const {
    className,
    color = 'default',
    fullWidth,
    disabled,
    required,
    value,
    defaultValue,
    onChange,
    type,
    placeholder,
    readOnly,
    startAdornment,
    endAdornment,
    inputProps = {},
    min,
    max,
    ...other
  } = props;

  const classes = useInputBaseStyles();
  const rootCN = cn(classes.root, className);
  const inputCN = cn(classes.input, {
    [classes.colorDefault]: color === 'default',
    [classes.colorError]: color === 'error',
    [classes.colorWarning]: color === 'warning',
    [classes.colorSuccess]: color === 'success',
    [classes.disabled]: disabled,
    [classes.readOnly]: readOnly,
    [classes.fullWidth]: fullWidth,
    [classes.withStartAdornment]: !!startAdornment,
    [classes.withEndAdornment]: !!endAdornment,
  });

  return (
    <div className={rootCN} {...other}>
      {startAdornment && <div className={classes.startAdornment}>{startAdornment}</div>}
      <input
        className={inputCN}
        value={value}
        defaultValue={defaultValue}
        type={type}
        onChange={onChange}
        placeholder={placeholder}
        color={color}
        disabled={disabled}
        required={required}
        readOnly={readOnly}
        min={min}
        max={max}
        {...inputProps}
      />
      {endAdornment && <div className={classes.endAdornment}>{endAdornment}</div>}
    </div>
  );
};
