import { IPortalStore } from '..';
import { IPortalUser } from './types';

export type IPortalUserLoginArgs = {};
export type IPortalUserLoginRes = IPortalUser;

export class PortalUser {

  protected portal: IPortalStore;

  constructor(portal: IPortalStore) {
    this.portal = portal;
  }

  public async login(args: IPortalUserLoginArgs): Promise<IPortalUserLoginRes> {
    const res = await this.portal.jsonRequest<IPortalUserLoginRes>({
      method: 'POST',
      url: '/auth/login',
      data: args,
    });

    return res;
  }

}
