import React from 'react';
import { ColumnsType } from 'rc-table/lib/interface';
import { stripHtml } from '../../../../shared/utils/strip-html-tags';
import { IPortalQuestion } from '../../../../stores/question/types';
import { NumberField } from '../../../components/ui/number-field';
import { tableCellMore } from '../../../components/ui/table/table.columns';
import { Typography } from '../../../components/ui/typography';

type options = {
  onUpdatePriority: (priority: number, record: IPortalQuestion) => void;
  // @ts-ignore
  onMore: (record: IPortalQuestion) => (e) => void
};

const MAX_TRIM_DESC_LENGTH = 100;

const trimString = (str: string = '') : string => {
  const strippedHtml = stripHtml(str);
  const result = strippedHtml.length > MAX_TRIM_DESC_LENGTH ? `${strippedHtml.substring(0, MAX_TRIM_DESC_LENGTH)}...` : strippedHtml.substring(0, MAX_TRIM_DESC_LENGTH);

  return result;
};

export const getQuestionListColumns = (opts: options): ColumnsType<IPortalQuestion> => {
  const columns: ColumnsType<IPortalQuestion> = [
    {
      title: 'Вопрос',
      width: 200,
      render: (value, record) => <Typography style={{ wordWrap: 'break-word' }}>
        {trimString(record.question)}
      </Typography>,
    },
    {
      title: 'Ответ',
      width: 200,
      render: (value, record) => <Typography style={{ wordWrap: 'break-word' }}>{trimString(record.answers[0].text)}</Typography>,
    },
    {
      title: 'Наименование системы',
      width: 200,
      // @ts-ignore
      render: (value, record) => <Typography>{record.system?.title}</Typography>,
    },
    {
      title: 'Наименование раздела',
      width: 200,
      // @ts-ignore
      render: (value, record) => <Typography>{record.section?.title}</Typography>,
    },
    {
      title: 'Приоритет',
      width: 100,
      render: (value, record) => <NumberField
        fullWidth
        min={0}
        max={100}
        placeholder="Ввод номера от 0 до 100"
        defaultValue={record.sort_priority}
        onBlur={(e): void => {
          // @ts-ignore
          opts.onUpdatePriority(Number(e.target.value), record);
        }}
      />,
    },
    tableCellMore(opts.onMore),
  ];

  return columns;
};
