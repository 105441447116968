import { BaseFormStore } from '../../../shared/form';
import { IPortalUpdateSoftwareArgs } from '../../software';
import { ICreateSoftware, IPortalSoftware } from '../../software/types';
import { SOFTWARE_FORM_INITIAL_STATE } from './defaults';
import { softwareSchema } from './schema';

export class SoftwareFormStore extends BaseFormStore<ICreateSoftware> {

  constructor() {
    super(SOFTWARE_FORM_INITIAL_STATE, softwareSchema);
  }

  public load(software: IPortalSoftware): void {
    super.load({
      title: software.title,
      description: software.description,
      logo: software.logo,
      link: software.link,
    });
  }

  public getArgs(): Omit<IPortalUpdateSoftwareArgs, '_id'> {
    const { title, ...state } = this.getValues();
    const args = {
      ...state,
      title: title || 'Новое ПО',
    };

    return args;
  }

}
