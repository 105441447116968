import { createUseStyles } from 'react-jss';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { useAppStore } from '../../../stores/root/root.context';
import { ControlBlock } from '../control-block';
import { Button } from '../ui/button';
import { Divider } from '../ui/divider';
import { Editor } from '../ui/editor';
import { AddIcon } from '../ui/icons/add';
import { Stack } from '../ui/stack';
import { TextArea } from '../ui/text-area';
import { UploadRow } from '../ui/upload-row';


const PREFIX = 'DialogCreateNewsText';
const useStyles = createUseStyles(() => ({
  root: {
    height: '100%',
    overflow: 'auto',
    paddingRight: '20px',
  },
  editContent: {
    maxHeight: '380px',
  },
  images: {
    paddingLeft: '20px',
    borderLeft: '1px solid #E4E7F2',
  },
}), { name: PREFIX });

type DialogCreateNewsTextProps = {
  readOnly?: boolean;
};

export const DialogCreateNewsText: React.FC<DialogCreateNewsTextProps> = observer((props) => {
  const { readOnly } = props;
  const { newsForm: form, portal, auth } = useAppStore();
  const news = form.getValues();
  const classes = useStyles();
  const className = readOnly ? classes.root : cn(classes.root, classes.editContent);

  return (
    <Stack
      className={className}
      direction="column"
      spacing={20}
    >
      <ControlBlock
        label="Введите верхнюю часть текста новости *"
        control={
          <Editor
            fullWidth
            placeholder="Введите текст новости"
            value={news.text_top}
            onChange={(v):void => form.setFieldValue('text_top', v)}
            readOnly={readOnly}
          />
        }
      />

      <Divider />

      <Stack className={classes.images} spacing={12} direction="column">
        {news.images.map((image, index): JSX.Element => (
          <UploadRow
            key={`image-${index}`}
            action={portal.portal.news.uploadImageUrl}
            authorization={auth.token}
            title="Добавить фото внутри текста"
            subtitle="Загрузите картинку в формате .png, .jpg"
            accept=".png,.jpg"
            value={image}
            onChange={(v): void => form.updateImage(index, (v as string[])[0])}
            onDelete={(): void => form.deleteImage(index)}
            readOnly={readOnly}
          />
        ))}
        {news.images.length < 2 && !readOnly &&
          <Button
            style={{ marginLeft: 'auto' }}
            variant="outlined"
            endIcon={<AddIcon />}
            onClick={(): void => form.addImage()}
          >
            Добавить фото
          </Button>
        }

        <ControlBlock
          label="Введите описание для фото"
          control={
            <TextArea
              required
              fullWidth
              placeholder="Ввод текста"
              value={news.images_description}
              onChange={(e): void => form.setFieldValue('images_description', e.target.value)}
              readOnly={readOnly}
            />
          }
        />
      </Stack>


      <ControlBlock
        label="Введите нижнюю часть текста новости"
        control={
          <Editor
            required
            fullWidth
            placeholder="Ввод текста"
            value={news.text_bottom}
            onChange={(v): void => form.setFieldValue('text_bottom', v)}
            readOnly={readOnly}
          />
        }
      />
    </Stack>
  );
});
