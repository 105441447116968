import { ColumnsType } from 'rc-table/lib/interface';
import { newsStatusToLocale } from '../../../shared/utils/format';
import { toHumanDate } from '../../../shared/utils/human-date';
import { IPortalNews, IPortalNewsStatus } from '../../../stores/news/types';
import { tableCellMore } from '../../components/ui/table/table.columns';
import { Typography } from '../../components/ui/typography';

export const getNewsListColumns = (opts: {
  onMore: (user: IPortalNews) => (e: React.MouseEvent<HTMLElement>) => void;
}): ColumnsType<IPortalNews> => {
  const columns: ColumnsType<IPortalNews> = [
    {
      title: 'Название',
      width: 200,
      render: (value, record) => <Typography style={{ wordWrap: 'break-word' }}>{record.title}</Typography>,
    },
    {
      title: 'Дата создания/обновления',
      width: 200,
      render: (value, record) => <Typography>{toHumanDate(record.created_at)}</Typography>,
    },
    {
      title: 'Статус',
      width: 200,
      render: (value, record) => <Typography style={{ color: record.status === IPortalNewsStatus.published ? '#73D071' : '#BDBDBD' }}>{newsStatusToLocale(record.status)}</Typography>,
    },
    tableCellMore(opts.onMore),
  ];

  return columns;
};
