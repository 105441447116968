import React from 'react';
import cn from 'classnames';
import { IDialogFooterProps } from '../dialog/dialog.models';
import { createUseStyles } from '../theme/createUseStyles';

const PREFIX = 'DialogFooter';

const useStyles = createUseStyles(
  () => ({
    root: {
      display: 'flex',
      columnGap: 16,

      '& > *': {
        flex: 1,
      },
    },
    column: {
      flexDirection: 'column',
      rowGap: 4,

      '& > *': {
        width: '100%',
        justifyContent: 'center',
      },
    },
    variantRight: {
      justifyContent: 'flex-end',

      '& > *': {
        flex: 'initial',
        width: 'fit-content',
      },
    },
  }),
  { name: PREFIX },
);

export const DialogFooter: React.FC<IDialogFooterProps> = (modalFooterProps) => {
  const { className, children, direction, variant, ...other } = modalFooterProps;

  const classes = useStyles();

  const footerCN = cn(
    classes.root,
    { [classes.column]: direction === 'column', [classes.variantRight]: variant === 'right' },
    className,
  );

  return (
    <div {...other} className={footerCN}>
      {children}
    </div>
  );
};
