import { BaseFormStore } from '../../../shared/form';
import { IPortalUpdateCourseArgs } from '../../course';
import { ICreateCourse, IPortalCourse } from '../../course/types';
import { COURSE_FORM_INITIAL_STATE } from './defaults';
import { courseShema } from './schema';

export class CourseFormStore extends BaseFormStore<ICreateCourse> {

  constructor() {
    super(COURSE_FORM_INITIAL_STATE, courseShema);
  }

  // @ts-ignore
  public load(course: IPortalCourse): void {
    super.load({
      slug: course.slug,
      title: course.title,
      tag: course.tag._id,
      card_color: course.card_color,
      card_color_tag: course.card_color_tag,
    });
  }

  public getArgs(): Omit<IPortalUpdateCourseArgs, '_id'> {
    const { title, ...state } = this.getValues();
    const args = {
      ...state,
      title: title || 'Новый курс',
    };

    return args;
  }

}
