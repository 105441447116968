import React from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import { BrowserRoute } from '../../../routes/browser.routes';
import { useAppStore } from '../../../stores/root/root.context';
import { ArchiveIcon } from '../../components/ui/icons/archive';
import { CircleQuestionIcon } from '../../components/ui/icons/circle-question';
import { ResultsIcon } from '../../components/ui/icons/results';
import { Paper } from '../../components/ui/paper';
import { Stack } from '../../components/ui/stack';
import { Tab, TabPanel, Tabs, useTabs } from '../../components/ui/tabs';
import { Typography } from '../../components/ui/typography';
import { QuestionListPage } from './questions/questions-list.view';
import { SectionsListPage } from './sections/sections-list.view';
import { SystemsListPage } from './systems/systems-list.view';


const PREFIX = 'KnowledgeBaseBlock';
const useStyles = createUseStyles(
  () => ({
    root: {
      padding: 40,
      paddingTop: 30,
    },
    tabs: {
      width: '100%',
      padding: '8px 0px 0px 0',
    },
  }),
  { name: PREFIX },
);

export const KnowledgeBaseBlock: React.FC = () => {
  const classes = useStyles();

  const tabs = useTabs(BrowserRoute.systems);
  const { portal } = useAppStore();
  const { refetch: refetchQuestions } = portal.questionList.useQuery({ variables: {} });
  const { refetch: refetchSections } = portal.sectionList.useQuery({ variables: {} });

  return (
    <Paper className={classes.root}>
      <div>
        <Typography variant="h2" fontWeight="bold">
                    Управление базой знаний
        </Typography>
        <Stack style={{ flexDirection: 'column', marginBottom: 48 }}>
          <Tabs
            value={tabs.value}
            onChange={tabs.onChange}
            className={classes.tabs}
          >
            <Tab
              value={BrowserRoute.systems}
              label="Системы"
              startIcon={<ResultsIcon />}
              component={(p): JSX.Element => <Link to={BrowserRoute.systems} {...p} />}
            />
            <Tab
              value={BrowserRoute.sections}
              label="Разделы"
              startIcon={<ArchiveIcon />}
              component={(p): JSX.Element => <Link to={BrowserRoute.sections} {...p} />}
            />
            <Tab
              value={BrowserRoute.questions}
              label="Вопросы"
              startIcon={<CircleQuestionIcon />}
              component={(p): JSX.Element => <Link to={BrowserRoute.questions} {...p} />}
            />
          </Tabs>
        </Stack>

        <TabPanel current={tabs.value} value={BrowserRoute.systems}>
          <SystemsListPage refetchQuestions={refetchQuestions} refetchSections={refetchSections} />
        </TabPanel>
        <TabPanel current={tabs.value} value={BrowserRoute.sections}>
          <SectionsListPage refetchQuestions={refetchQuestions} />
        </TabPanel>
        <TabPanel current={tabs.value} value={BrowserRoute.questions}>
          <QuestionListPage />
        </TabPanel>
      </div>
    </Paper>
  );
};
