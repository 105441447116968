import React, { FC, SVGProps } from 'react';
import { createUseStyles } from 'react-jss';
import cn from 'classnames';

const PREFIX = 'SvgIcon';
const useStyles = createUseStyles(
  (theme) => ({
    root: {
      color: 'inherit',
    },
    colorPrimary: {
      color: theme.palette.primary.main,
    },
    colorSuccess: {
      color: theme.palette.success.main,
    },
    colorWarning: {
      color: theme.palette.warning.main,
    },
    colorError: {
      color: theme.palette.error.main,
    },
    colorTextSecondary: {
      color: theme.palette.text.secondary,
    },
    sizeSmall: {
      width: 16,
      height: 16,
    },
    sizeMedium: {
      width: 20,
      height: 20,
    },
  }),
  { name: PREFIX },
);

export type SvgIconProps = SVGProps<SVGSVGElement> & { color?: 'primary' | 'textSecondary' | 'success' | 'error' | 'warning'; size?: 'small' | 'medium' };

export const SvgIcon: FC<SvgIconProps> = (props) => {
  const { children, color, className, size, ...other } = props;

  const classes = useStyles();
  const rootCN = cn(
    classes.root,
    {
      [classes.colorPrimary]: color === 'primary',
      [classes.colorTextSecondary]: color === 'textSecondary',
      [classes.colorError]: color === 'error',
      [classes.colorWarning]: color === 'warning',
      [classes.colorSuccess]: color === 'success',
      [classes.sizeSmall]: size === 'small',
      [classes.sizeMedium]: size === 'medium',
    },
    className,
  );

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={rootCN}
      {...other}
    >
      {children}
    </svg>
  );
};
