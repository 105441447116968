import React from 'react';
import { createUseStyles } from 'react-jss';
import { observer } from 'mobx-react';
import { Stack } from '../../../components/ui/stack';
import { Tab, TabPanel, Tabs, useTabs } from '../../../components/ui/tabs';
import { RecordsList } from './records/records-list';
import { TagsList } from './tags/tags-list';

const PREFIX = 'CourseListPage';
const useStyles = createUseStyles(
  () => ({
    root: {},
    tab: {
      fontSize: 14,
    },
    header: {
      flex: 1,
      marginTop: 24,
      width: '100%',
      marginBottom: 24,
    },
  }),
  { name: PREFIX },
);

export const CourseListPage: React.FC = observer(() => {
  const classes = useStyles();
  const tabs = useTabs('tags');

  return (
    <div className={classes.root}>
      <Stack direction="row">
        <Tabs value={tabs.value} onChange={tabs.onChange}>
          <Tab label="Тэги" value="tags" className={classes.tab} />
          <Tab label="Записи" value="records" className={classes.tab} />
        </Tabs>
      </Stack>

      <TabPanel value={'tags'} current={tabs.value}>
        <TagsList />
      </TabPanel>

      <TabPanel value={'records'} current={tabs.value}>
        <RecordsList />
      </TabPanel>

    </div>
  );
});
