import { ColumnsType } from 'rc-table/lib/interface';
import { eventStatusToLocale } from '../../../shared/utils/format';
import { toHumanDate } from '../../../shared/utils/human-date';
import { IPortalEvent, IPortalEventStatus } from '../../../stores/event/types';
import { tableCellMore } from '../../components/ui/table/table.columns';
import { Typography } from '../../components/ui/typography';

export const getUserListColumns = (opts: {
  onMore: (event: IPortalEvent) => (e: React.MouseEvent<HTMLElement>) => void;
}): ColumnsType<IPortalEvent> => {
  const columns: ColumnsType<IPortalEvent> = [
    {
      title: 'Название подборки',
      width: 200,
      render: (value, record) => <Typography style={{ wordWrap: 'break-word' }}>{record.title}</Typography>,
    },
    {
      title: 'Дата создания',
      width: 200,
      render: (value, record) => <Typography>{toHumanDate(record.created_at)}</Typography>,
    },
    {
      title: 'Статус',
      width: 200,
      render: (value, record) => <Typography
        style={{ color: record.status === IPortalEventStatus.published ? '#73D071' : '#BDBDBD' }}>
        {eventStatusToLocale(record.status)}
      </Typography>,
    },
    tableCellMore(opts.onMore),
  ];

  return columns;
};
