import React from 'react';
import { createUseStyles } from 'react-jss';
import { observer } from 'mobx-react';
import { useSearch } from '../../../../shared/utils/hooks';
import { SECTION_FORM_INITIAL_STATE } from '../../../../stores/form/section/defaults';
import { useAppStore } from '../../../../stores/root/root.context';
import { IPortalSection } from '../../../../stores/section/types';
import { SectionDialog } from '../../../components/dialog-section/dialog-section.view';
import { Button } from '../../../components/ui/button';
import { useDialogState } from '../../../components/ui/dialog/dialog.hook';
import { AddIcon } from '../../../components/ui/icons/add';
import { SearchIcon } from '../../../components/ui/icons/search';
import { Menu } from '../../../components/ui/menu';
import { MenuItem } from '../../../components/ui/menu/menu-item.view';
import { useMenuState } from '../../../components/ui/menu/menu.hook';
import { onErrorSnackbar, snackbar } from '../../../components/ui/snackbar/snackbar.utils';
import { Stack } from '../../../components/ui/stack';
import { Table } from '../../../components/ui/table';
import { TablePagination, useTablePaginationState } from '../../../components/ui/table-pagination';
import { TextField } from '../../../components/ui/text-field';
import { IKnowledgeProps } from '../knowledge-base.models';
import { getSectionListColumns } from './sections-list.columns';

const PREFIX = 'SectionsListPage';
const useStyles = createUseStyles(
  () => ({
    root: {},
    header: {
      flex: 1,
      marginTop: 24,
      width: '100%',
      marginBottom: 24,
    },
  }),
  { name: PREFIX },
);

export const SectionsListPage: React.FC<IKnowledgeProps> = observer((props) => {
  const { refetchQuestions } = props;
  const classes = useStyles();
  const { portal, sectionForm: form } = useAppStore();

  const [selectedSection, setSelectedSection] = React.useState<IPortalSection | undefined>();

  const menuState = useMenuState();
  const search = useSearch();
  const pagination = useTablePaginationState(0, 10);
  const sectionDialog = useDialogState();

  const [createSection] = portal.createSection.useMutation({
    onError: onErrorSnackbar,
  });
  const [deleteSection] = portal.deleteSection.useMutation({
    onError: onErrorSnackbar,
  });
  const [updateSection] = portal.updateSection.useMutation({
    onError: onErrorSnackbar,
  });

  const { data, loading, refetch } = portal.sectionList.useQuery({
    variables: {
      limit: pagination.rowsPerPage,
      offset: pagination.page * pagination.rowsPerPage,
      sort: '-status,-created_at',
      title: search.debounced,
    },
  });

  const handleSave = (): void => {
    const formValues = form.getArgs();
    if (!selectedSection) {
      // @ts-ignore
      createSection({ ...formValues }).then((): void => {
        refetch();
        snackbar({ message: 'Раздел создан!', severity: 'success' });
        sectionDialog.close();
      });
    }
    else {
      updateSection({
        ...formValues,
        _id: selectedSection._id,
      }).then((): void => {
        refetch();
        snackbar({ message: 'Раздел обновлен!', severity: 'success' });
        sectionDialog.close();
      });
    }
  };

  const handleDelete = (_id: string): void => {
    deleteSection({ _id }).then((): void => {
      if (refetchQuestions) {
        refetchQuestions();
      }
      refetch();
      snackbar({ message: 'Раздел удален!', severity: 'success' });
    });
  };

  const columns = getSectionListColumns({
    onMore:
            (record) => (e): void => {
              e.stopPropagation();
              e.preventDefault();

              if (menuState.open) {
                menuState.onClose();
              }
              else {
                menuState.show(e);
                setSelectedSection(record);
              }
            },
  });

  return (
    <div className={classes.root}>
      <Stack direction="row" justifyContent="space-between" className={classes.header}>
        <TextField
          style={{ maxWidth: 480 }}
          fullWidth
          placeholder="Поиск по названию"
          InputProps={{ endAdornment: <SearchIcon /> }}
          value={search.value}
          onChange={(e): void => {
            search.change(e.target.value);
          }}
        />

        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={(): void => {
            form.reset(SECTION_FORM_INITIAL_STATE);
            setSelectedSection(undefined);
            sectionDialog.open();
          }}
        >
                    Добавить новую запись
        </Button>
      </Stack>

      <Table<IPortalSection>
        columns={columns}
        loading={loading}
        rowKey="_id"
        data={data?.items}
        hover
        footer={(): JSX.Element => (
          <TablePagination
            total={data?.totalCount || 0}
            page={pagination.page}
            rowsPerPage={pagination.rowsPerPage}
            onChangePage={pagination.onChangePage}
            onChangeRowsPerPage={pagination.onChangeRowsPerPage}
          />
        )}
      />

      {selectedSection && <Menu {...menuState.props} placement="bottom-right">
        <MenuItem
          onClick={(): void => {
            menuState.onClose();
            form?.load(selectedSection);
            sectionDialog.open();
          }}
        >
                    Редактировать
        </MenuItem>
        <MenuItem
          onClick={(): void => {
            menuState.onClose();
            handleDelete(selectedSection._id);
          }}
        >
                    Удалить
        </MenuItem>
      </Menu>}

      <SectionDialog {...sectionDialog.props} onSubmit={handleSave} />
    </div>
  );
});
