import React from 'react';
import cn from 'classnames';
import { TabProps } from '../tab';
import { createUseStyles } from '../theme/createUseStyles';
import { getScrollHiddenStyle } from '../utils/style/scroll';
import { TabsProps } from './tabs.models';

const PREFIX = 'Tabs';

const useStyles = createUseStyles(
  () => ({
    root: {},
    box: {
      display: 'flex',
      color: 'rgb(59 65 104 / 1)',
      gap: '1.5rem',
      overflowX: 'auto',
      overflowY: 'auto',

      ...getScrollHiddenStyle(),
    },
    line: {
      marginTop: -2,
      width: '100%',
      height: 2,
      background: 'rgb(209, 213, 219, 0.5)',
    },
  }),
  { name: PREFIX },
);

export const Tabs: React.FC<TabsProps> = (props) => {
  const { onChange, value, children, className, ...other } = props;

  const classes = useStyles();

  const handleChange = (v: string): void => {
    if (onChange) {
      onChange(v);
    }
  };

  const prevElements = React.Children.toArray(children) as React.ReactElement<TabProps>[];

  const nextElements = prevElements.map((el, i) => React.cloneElement(el, {
    ...el.props,
    key: `tab-${i}`,
    selected: el.props.selected || Boolean(value && value === el.props.value),
    onClick: (): void => {
      handleChange(el.props.value);

      if (el.props.onClick) {
        el.props.onClick();
      }
    },
  }));

  return (
    <div {...other} data-value={value} className={cn(classes.root, className)}>
      <div className={classes.box}>{nextElements}</div>
      <div className={classes.line} />
    </div>
  );
};
