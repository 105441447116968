import React from 'react';
import { createUseStyles } from 'react-jss';
import { observer } from 'mobx-react';
import { useSearch } from '../../../shared/utils/hooks/useSearch';
import { NEWS_FORM_INITIAL_STATE } from '../../../stores/form/news/defaults';
import { IPortalNews, IPortalNewsOrderBy, IPortalNewsStatus } from '../../../stores/news/types';
import { useAppStore } from '../../../stores/root/root.context';
import { Order } from '../../../stores/types';
import { DialogCreateNews } from '../../components/dialog-create-news/dialog-create-news.view';
import { Button } from '../../components/ui/button';
import { useDialogState } from '../../components/ui/dialog/dialog.hook';
import { AddIcon } from '../../components/ui/icons/add';
import { SearchIcon } from '../../components/ui/icons/search';
import { Menu } from '../../components/ui/menu';
import { MenuItem } from '../../components/ui/menu/menu-item.view';
import { useMenuState } from '../../components/ui/menu/menu.hook';
import { onErrorSnackbar, snackbar } from '../../components/ui/snackbar/snackbar.utils';
import { Stack } from '../../components/ui/stack';
import { Table } from '../../components/ui/table';
import { TablePagination, useTablePaginationState } from '../../components/ui/table-pagination';
import { TextField } from '../../components/ui/text-field';
import { getNewsListColumns } from './news-list.collumns';


const PREFIX = 'OrganizationEventListPage';
const useStyles = createUseStyles(
  () => ({
    root: {
      width: '100%',
      marginBottom: 16,
      display: 'flex',
      justifyContent: 'space-between',
      padding: 0,
    },
  }),
  { name: PREFIX },
);

export const NewsListPage: React.FC = observer(() => {
  const classes = useStyles();
  const { portal, newsForm: form } = useAppStore();

  const [selectedNews, setSelectedNews] = React.useState<IPortalNews | undefined>();
  const [readOnly, setReadOnly] = React.useState<boolean>(false);

  const menuState = useMenuState();
  const search = useSearch();
  const pagination = useTablePaginationState(0, 10);
  const editNewsDialog = useDialogState();

  const { data, loading, refetch } = portal.newsList.useQuery({
    variables: {
      limit: pagination.rowsPerPage,
      offset: pagination.page * pagination.rowsPerPage,
      title: search.debounced,
      sort: '-status,-created_at',
    },
  });

  const [createNews] = portal.createNews.useMutation({
    onError: onErrorSnackbar,
  });
  const [deleteNews] = portal.deleteNews.useMutation({
    onError: onErrorSnackbar,
  });
  const [acceptNews] = portal.acceptNews.useMutation({
    onError: onErrorSnackbar,
  });
  const [declineNews] = portal.declineNews.useMutation({
    onError: onErrorSnackbar,
  });
  const [updateNews] = portal.updateNews.useMutation({
    onError: onErrorSnackbar,
  });

  const handleNewsView = (): void => {
    menuState.onClose();

    if (selectedNews) {
      form.load(selectedNews);
      editNewsDialog.open();
      setReadOnly(true);
    }
  };

  const handleNewsEdit = (): void => {
    menuState.onClose();

    if (selectedNews) {
      form.load(selectedNews);
      editNewsDialog.open();
      setReadOnly(false);
    }
  };

  const handleDelete = (_id: string): void => {
    deleteNews({ _id }).then((): void => {
      refetch();
      snackbar({ message: 'Новость удалена', severity: 'success' });
    });
  };
  const handleAccept = (_id: string): void => {
    acceptNews({ _id }).then((): void => {
      refetch();
      snackbar({ message: 'Новость обновлена', severity: 'success' });
    });
  };
  const handleDecline = (_id: string): void => {
    declineNews({ _id }).then((): void => {
      refetch();
      snackbar({ message: 'Новость обновлена', severity: 'success' });
    });
  };
  const handleSave = (): void => {
    const formValues = form.getArgs();
    if (!selectedNews) {
      createNews({ ...formValues }).then((): void => {
        refetch();
        snackbar({ message: 'Новость создана!', severity: 'success' });
        editNewsDialog.close();
      });
    }
    else {
      updateNews({
        ...formValues,
        _id: selectedNews._id,
      }).then((): void => {
        refetch();
        snackbar({ message: 'Новость обновлена!', severity: 'success' });
        editNewsDialog.close();
      });
    }
  };

  const columns = getNewsListColumns({
    onMore:
      (record) => (e): void => {
        e.stopPropagation();
        e.preventDefault();

        if (menuState.open) {
          menuState.onClose();
        }
        else {
          menuState.show(e);
          setSelectedNews(record);
        }
      },
  });

  return (
    <>
      <div className={classes.root}>
        <Stack direction="row" spacing={16} style={{ flex: 1 }}>
          <TextField
            style={{ maxWidth: 480 }}
            fullWidth
            placeholder="Поиск по названию"
            InputProps={{ endAdornment: <SearchIcon /> }}
            value={search.value}
            onChange={(e): void => {
              search.change(e.target.value);
            }}
          />
        </Stack>

        <Button
          onClick={(): void => {
            form.reset(NEWS_FORM_INITIAL_STATE);
            setSelectedNews(undefined);
            editNewsDialog.open();
            setReadOnly(false);
          }}
          variant="outlined"
          startIcon={<AddIcon />}
        >
            Добавить новую запись
        </Button>
      </div>

      <Table<IPortalNews>
        columns={columns}
        loading={loading}
        rowKey="_id"
        data={data?.items}
        hover
        footer={(): JSX.Element => (
          <TablePagination
            style={{ marginTop: 16 }}
            total={data?.totalCount || 0}
            page={pagination.page}
            rowsPerPage={pagination.rowsPerPage}
            onChangePage={pagination.onChangePage}
            onChangeRowsPerPage={pagination.onChangeRowsPerPage}
          />
        )}
      />

      {selectedNews && <Menu {...menuState.props} placement="bottom-right">
        {selectedNews.status === IPortalNewsStatus.published &&
          <MenuItem
            onClick={handleNewsView}
          >
            Посмотреть
          </MenuItem>}
        {selectedNews.status === IPortalNewsStatus.published &&
          <MenuItem
            onClick={(): void => {
              menuState.onClose();
              handleDecline(selectedNews._id);
            }}
          >
            Снять с публикации
          </MenuItem>}
        {selectedNews.status === IPortalNewsStatus.draft &&
          <MenuItem
            onClick={handleNewsEdit}
          >
            Редактировать
          </MenuItem>}
        {selectedNews.status === IPortalNewsStatus.draft &&
          <MenuItem
            onClick={(): void => {
              menuState.onClose();
              handleAccept(selectedNews._id);
            }}
          >
            Опубликовать
          </MenuItem>}
        {selectedNews.status === IPortalNewsStatus.draft &&
          <MenuItem
            onClick={(): void => {
              menuState.onClose();
              handleDelete(selectedNews._id);
            }}
          >
            Удалить
          </MenuItem>}
      </Menu>
      }

      <DialogCreateNews readOnly={readOnly} onSubmit={handleSave} {...editNewsDialog.props} />
    </>
  );
});
