import { BaseFormStore } from '../../../shared/form';
import { IPortalUpdateTagArgs } from '../../tag';
import { ICreateTag, IPortalTag } from '../../tag/types';
import { TAG_FORM_INITIAL_STATE } from './default';
import { tagShema } from './schema.';

export class TagFormStore extends BaseFormStore<ICreateTag> {

  constructor() {
    super(TAG_FORM_INITIAL_STATE, tagShema);
  }

  public load(course: IPortalTag): void {
    super.load({
      title: course.title,
    });
  }

  public getArgs(): Omit<IPortalUpdateTagArgs, '_id'> {
    const { title } = this.getValues();
    const args = {
      title: title || 'Новый Тэг',
    };

    return args;
  }

}
