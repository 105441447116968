import { BaseFormStore } from '../../../shared/form';
import { IPortalUpdateActivityArgs } from '../../activity';
import { ActivityFormState, IActivitySpeaker, IPortalActivity } from '../../activity/types';
import { ACTIVITY_FORM_INITIAL_STATE } from './defaults';
import { activitySchema } from './schema';

export class ActivityFormStore extends BaseFormStore<ActivityFormState> {

  public activityId: string = '_';

  constructor() {
    super(ACTIVITY_FORM_INITIAL_STATE, activitySchema);
  }

  // @ts-ignore
  public load(activity: IPortalActivity): void {
    this.activityId = activity._id;

    super.load({
      title: activity.title,
      start_date: activity.start_date,
      end_date: activity.end_date,
      desktop_cover: activity.desktop_cover,
      tablet_cover: activity.tablet_cover,
      mobile_cover: activity.mobile_cover,
      place: activity.place,
      price: activity.price,
      price_visible: activity.price_visible,
      link_to_entry: activity.link_to_entry,
      is_main: activity.is_main,
      short_description: activity.short_description,
      full_description: activity.full_description,
      speakers: activity.speakers ? activity.speakers : undefined,
      tags: activity.tags,
      files: activity.files,
      similar_activity: activity.similar_activity.map((item) => item._id),
    });
  }

  public getArgs(): Omit<IPortalUpdateActivityArgs, '_id'> {
    const { title, ...state } = this.getValues();
    const args = {
      ...state,
      title: title || 'Новое мероприятие',
      start_date: state.start_date || new Date(),
      end_date: state.end_date || new Date(),
    };

    return args;
  }

  public addSpeaker(): void {
    const speakers = this.getFieldValue('speakers') as IActivitySpeaker[];

    this.setFieldValue('speakers', [...speakers, { fullname: '', appointment: '', photo_id: '' }]);
  }

  public updateSpeaker(index: number, field: keyof IActivitySpeaker, value: IActivitySpeaker[typeof field]): void {
    const arr = this.getFieldValue('speakers') as IActivitySpeaker[];
    const item = arr[index];

    if (item) {
      item[field] = value as Date & string;
      arr.splice(index, 1, item);
    }

    this.setFieldValue('speakers', arr, `speakers[${index}].${field}`);
  }

  public deleteSpeaker = (index: number): void => {
    const speakers = this.getFieldValue('speakers') as IActivitySpeaker[];
    const updatedSpeakers = speakers?.filter((_, i) => i !== index);

    this.setFieldValue('speakers', updatedSpeakers);
  };

  public addTag(): void {
    const tags = this.getFieldValue('tags') as string[];
    this.setFieldValue('tags', [...tags, '']);
  }

  public deleteTag = (index: number): void => {
    const tags = this.getFieldValue('tags') as string[];
    const updatedTags = tags?.filter((_, i) => i !== index);

    this.setFieldValue('tags', updatedTags);
  };

  public updateTag(index: number, value: string): void {
    const arr = this.getFieldValue('tags') as string[];
    let item = arr[index];

    item = value;
    arr.splice(index, 1, item);

    this.setFieldValue('tags', arr);
  }

  public addFile(): void {
    const files = this.getFieldValue('files') as string[];
    this.setFieldValue('files', [...files, '']);
  }

  public deleteFile = (index: number): void => {
    const files = this.getFieldValue('files') as string[];
    const updatedFiles = files?.filter((_, i) => i !== index);

    this.setFieldValue('files', updatedFiles);
  };

  public updateFile(index: number, value: string): void {
    const arr = this.getFieldValue('files') as string[];
    let item = arr[index];
    item = value;
    arr.splice(index, 1, item);

    this.setFieldValue('files', arr);
  }

  public updateLink(index: number, value: string): void {
    const arr = this.getFieldValue('similar_activity') as string[];
    let item = arr[index];

    item = value;
    arr.splice(index, 1, item);

    this.setFieldValue('similar_activity', arr);
  }

  public deleteLink = (index: number): void => {
    const additionalLinks = this.getFieldValue('similar_activity') as string[];
    const updatedLinks = additionalLinks?.filter((_, i) => i !== index);

    this.setFieldValue('similar_activity', updatedLinks);
  };

  public addLink(): void {
    const links = this.getFieldValue('similar_activity') as string[];
    this.setFieldValue('similar_activity', [...links, '']);
  }

}
