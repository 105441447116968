import React, { FC, MouseEventHandler, PropsWithChildren } from 'react';
import cn from 'classnames';
import { Backdrop } from '../backdrop';
import { Container } from '../container';
import { useScrollHidden } from '../menu/menu.hook';
import { Paper } from '../paper';
import { createUseStyles } from '../theme/createUseStyles';
import { IDialogProps } from './dialog.models';

const PREFIX = 'Dialog';

const useStyles = createUseStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      transition: 'all',
      color: '#000000',
      borderTopWidth: '2px',
      borderTopStyle: 'solid',
      borderRadius: theme.shape.borderRadius,
      transitionDuration: '.15s',
      transitionProperty: 'all',
      transitionTimingFunction: 'cubic-bezier(.4,0,.2,1)',
      overflow: 'hidden',
    },
    box: {
      maxHeight: '80vh',
      height: '100%',
      padding: '1.75rem 2.5rem',
      overflowY: 'auto',
    },
    fullWidth: {
      width: '100%',
    },
    colorPrimary: {
      borderTopColor: theme.palette.primary.main,
    },
    colorWarning: {
      borderTopColor: theme.palette.warning.main,
    },
    colorError: {
      borderTopColor: theme.palette.error.main,
    },
    colorSuccess: {
      borderTopColor: theme.palette.success.main,
    },
    disablePadding: {
      '& $box': {
        padding: 0,
      },
    },
  }),
  { name: PREFIX },
);

export const Dialog: FC<PropsWithChildren<IDialogProps>> = (modalProps) => {
  const {
    open,
    maxWidth: size = 'sm',
    onClose,
    className,
    color,
    children,
    BackdropProps = {},
    fullWidth = true,
    disablePadding,
    ContainerProps = {},
    ...modalOtherProps
  } = modalProps;

  const classes = useStyles();

  const handleClose: MouseEventHandler<HTMLDivElement> = (event) => {
    if ((event.target as HTMLElement).getAttribute('data-close') !== 'true') {
      return;
    }

    if (typeof onClose === 'function') {
      onClose(event);
    }
  };

  const modalCN = cn(
    classes.root,
    {
      [classes.fullWidth]: fullWidth,

      [classes.colorError]: color === 'error',
      [classes.colorPrimary]: color === 'primary',
      [classes.colorWarning]: color === 'warning',
      [classes.colorSuccess]: color === 'success',
      [classes.disablePadding]: disablePadding,
    },
    className,
  );

  const handleDialogClick: MouseEventHandler<HTMLDivElement> = (): void => {
    // event.stopPropagation();
  };

  useScrollHidden(!!open);

  return (
    <Backdrop open={open} {...BackdropProps} onClick={handleClose} data-close="false">
      <Container maxWidth={size} {...ContainerProps} data-close="false">
        <Paper {...modalOtherProps} className={modalCN} onClick={handleDialogClick}>
          <div className={classes.box}>{children}</div>
        </Paper>
      </Container>
    </Backdrop>
  );
};
