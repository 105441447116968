import React, { FC } from 'react';
import cn from 'classnames';
import { createUseStyles } from '../theme/createUseStyles';
import { Typography } from '../typography';
import { IFormHelperTextProps } from './form-helper-text.models';

const PREFIX = 'FormHelperText';

const useStyles = createUseStyles(
  (theme) => ({
    root: {
      marginTop: 4,
    },
    colorError: {
      color: theme.palette.error.main,
    },
    colorWarning: {
      color: theme.palette.warning.main,
    },
    colorSuccess: {
      color: theme.palette.success.main,
    },
  }),
  { name: PREFIX },
);

export const FormHelperText: FC<IFormHelperTextProps> = (props) => {
  const { className, color, ...other } = props;

  const classes = useStyles();
  const rootCN = cn(
    classes.root,
    {
      [classes.colorError]: color === 'error',
      [classes.colorWarning]: color === 'warning',
      [classes.colorSuccess]: color === 'success',
    },
    className,
  );

  return <Typography variant="caption" className={rootCN} {...other} />;
};
