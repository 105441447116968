import { createUseStyles } from 'react-jss';
import { observer } from 'mobx-react';
import { useAppStore } from '../../../stores/root/root.context';
import { ControlBlock } from '../control-block';
import { Button } from '../ui/button';
import { IconButton } from '../ui/icon-button';
import { AddIcon } from '../ui/icons/add';
import { CloseIcon } from '../ui/icons/close';
import { Select, SelectItem } from '../ui/select';
import { Stack } from '../ui/stack';

const PREFIX = 'DialogCreateNewsLinks';
const useStyles = createUseStyles(() => ({
  root: {
    maxHeight: 330,
    overflowY: 'auto',
  },
}), { name: PREFIX });


export const DialogCreateNewsLinks: React.FC = observer(() => {
  const { newsForm: form, portal } = useAppStore();
  const classes = useStyles();

  const { data } = portal.newsList.useQuery({ variables: {} });

  const similarNews = form.getFieldValue('similar_news') as string[];

  return (
    <Stack
      className={classes.root}
      direction="column"
      spacing={24}
    >
      {similarNews.map((link, index) => (
        <Stack key={`additional-link-${index}`} direction="row" spacing={4} alignItems="center">
          <ControlBlock
            label={`Ссылка #${index + 1}`}
            control={
              <Select
                placeholder="Выберите новость"
                fullWidth
                variant="outlined"
                value={link}
                onChange={(v): void => form.updateLink(index, v as string)}
              >
                {(data?.items || []).map((item) => (
                  <SelectItem key={item._id} value={item._id}>{item.title}</SelectItem>
                ))}
              </Select>
            }
          />
          {similarNews.length > 1 &&
            <IconButton
              onClick={(): void => form.deleteLink(index)}
              style={{ marginBottom: 0, marginTop: 'auto' }}
            >
              <CloseIcon style={{ width: 24, height: 24, color: '#909EBB', marginBottom: 0, marginTop: 0 }} />
            </IconButton>
          }
        </Stack>
      ))}
      <Button
        startIcon={<AddIcon />}
        variant="outlined"
        color="primary"
        onClick={(): void => form.addLink()}
        style={{ justifyContent: 'center', border: 'none', color: '#0071CE', fontSize: '16px', marginTop: '4px' }}
      >
        добавить еще статью
      </Button>
    </Stack>
  );
});
