import React, { HTMLAttributes } from 'react';
import { createUseStyles } from 'react-jss';
import cn from 'classnames';

export type LayoutProps = HTMLAttributes<HTMLDivElement>;

const PREFIX = 'Layout';
const useStyles = createUseStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: 'calc(100% - 64px)',
      width: '100%',
      overflowX: 'inherit',
      alignItems: 'center',
      backgroundColor: theme.palette.background.default,
    },
  }),
  { name: PREFIX },
);

export const Layout: React.FC<LayoutProps> = (props) => {
  const { className, ...other } = props;

  const classes = useStyles();
  const rootCN = cn(classes.root, className);

  return <main className={rootCN} {...other} />;
};
