import React, { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Header } from '@unione-pro/unione.commons.webpack';
import { BrowserRoute } from '../../../routes/browser.routes';
import { AppFooter } from '../app-footer';
import { Layout } from '../ui/layout';
import { LayoutContent } from '../ui/layout/layout-content.view';
import { Tab } from '../ui/tab';
import { Tabs } from '../ui/tabs';

export const AppLayout: FC<{ children: React.ReactNode; sidebar?: React.ReactNode; }> = (
  props,
) => {
  const location = useLocation();

  const value = React.useMemo((): string => {
    if (location.pathname.includes(BrowserRoute.mainEvents)) {
      return BrowserRoute.mainEvents;
    }

    return location.pathname;
  }, [location.pathname]);

  return (
    <>
      <Header />

      <Layout>
        <Tabs value={value} style={{ width: '100%', padding: '0px 24px' }}>
          <Tab
            value={BrowserRoute.mainEvents}
            selected={[BrowserRoute.mainEvents, BrowserRoute.main].includes(location.pathname)}
            label="Управление главными событиями"
            component={(p): JSX.Element => <Link style={{ textDecoration: 'none' }} to={BrowserRoute.mainEvents} {...p} />}
          />
          <Tab
            value={BrowserRoute.course}
            selected={[BrowserRoute.course, BrowserRoute.software].includes(location.pathname)}
            label="Управление рекламой каталога курсов и ПО"
            component={(p): JSX.Element => <Link style={{ textDecoration: 'none' }} to={BrowserRoute.course} {...p} />}
          />
          <Tab
            value={BrowserRoute.news}
            selected={[BrowserRoute.activity, BrowserRoute.news].includes(location.pathname)}
            label="Управление новостями и мероприятиями"
            component={(p): JSX.Element => <Link style={{ textDecoration: 'none' }} to={BrowserRoute.news} {...p} />}
          />
          <Tab
            value={BrowserRoute.systems}
            selected={[BrowserRoute.sections, BrowserRoute.questions, BrowserRoute.systems].includes(location.pathname)}
            label="Управление базой знаний"
            component={(p): JSX.Element => <Link style={{ textDecoration: 'none' }} to={BrowserRoute.systems} {...p} />}
          />
        </Tabs>
        <LayoutContent>{props.children}</LayoutContent>
      </Layout>

      <AppFooter />
    </>
  );
};
