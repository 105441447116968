import { createUseStyles } from 'react-jss';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { useAppStore } from '../../../stores/root/root.context';
import { ControlBlock } from '../control-block';
import { DateField } from '../ui/date-field';
import { Divider } from '../ui/divider';
import { Editor } from '../ui/editor';
import { FormControlLabel } from '../ui/form-control-label';
import { NumberField } from '../ui/number-field';
import { Radio } from '../ui/radio';
import { Stack } from '../ui/stack';
import { TextField } from '../ui/text-field';
import { TimeField } from '../ui/time-field';
import { UploadRow } from '../ui/upload-row';

const PREFIX = 'DialogCreateActivityGeneral';
const useStyles = createUseStyles(() => ({
  root: {
    height: '100%',
    overflowY: 'auto',
    paddingRight: '20px',
  },
  editContent: {
    maxHeight: '380px',
  },
}), { name: PREFIX });

type DialogCreateActivityGeneralProps = {
  readOnly?: boolean;
};


export const DialogCreateActivityGeneral:React.FC<DialogCreateActivityGeneralProps> = observer((props) => {
  const { readOnly } = props;
  const { portal, auth, activityForm: form } = useAppStore();
  const classes = useStyles();
  const className = readOnly ? classes.root : cn(classes.root, classes.editContent);
  const activity = form.getValues();

  return (
    <Stack
      className={className}
      direction="column"
      spacing={24}
      divider={<Divider />}
    >
      <Stack direction="column" spacing={24} >
        <ControlBlock
          label="Название мероприятия"
          required
          style={{ flex: 6 }}
          control={
            <TextField
              fullWidth
              placeholder="Ввод текста"
              value={activity.title}
              onChange={(e): void => form.setFieldValue('title', e.target.value)}
              error={!!form.getFieldError('title')}
              helperText={form.getFieldError('title')}
              readOnly={readOnly}
            />
          }
        />
        <Stack direction="row" spacing={18}>
          <ControlBlock
            label="Дата проведения (начало)"
            required
            control={
              <DateField
                style={{ width: '186px' }}
                placeholder="дд.мм.гггг"
                value={activity.start_date}
                onReset={(): void => form.setFieldValue('start_date', null)}
                onChange={(v): void => form.setFieldValue('start_date', v)}
                error={!!form.getFieldError('start_date')}
                helperText={form.getFieldError('start_date')}
                readOnly={readOnly}
              />
            }
          />
          <ControlBlock
            label="Дата проведения (конец)"
            required
            control={
              <DateField
                style={{ width: '187px' }}
                placeholder="дд.мм.гггг"
                value={activity.end_date}
                onReset={(): void => form.setFieldValue('end_date', null)}
                onChange={(v): void => form.setFieldValue('end_date', v)}
                error={!!form.getFieldError('end_date')}
                helperText={form.getFieldError('end_date')}
                disabled={!activity.start_date}
                readOnly={readOnly}
              />
            }
          />
          <ControlBlock
            label="Время проведения (от)"
            control={
              <TimeField
                style={{ maxWidth: '165px' }}
                placeholder="ЧЧ:ММ"
                value={activity.start_date}
                onChange={(v): void => form.setFieldValue('start_date', v)}
                fullWidth
                error={!!form.getFieldError('start_date')}
                helperText={form.getFieldError('start_date')}
                readOnly={readOnly}
              />
            }
          />
          <ControlBlock
            label="Время проведения (до)"
            control={
              <TimeField
                style={{ maxWidth: '165px' }}
                placeholder="ЧЧ:ММ"
                value={activity.end_date}
                onChange={(v): void => form.setFieldValue('end_date', v)}
                fullWidth
                error={!!form.getFieldError('end_date')}
                helperText={form.getFieldError('end_date')}
                readOnly={readOnly}
              />
            }
          />

        </Stack>
      </Stack>

      <Stack direction="column" spacing={24} divider={<Divider />}>
        <UploadRow
          action={portal.portal.activity.uploadImageUrl}
          authorization={auth.token}
          title="Обложка для десктоп *"
          subtitle="Загрузите баннер размером 1020х437px, максимальный вес 5мб"
          sizeLimit={5000}
          accept=".png,.jpg"
          value={activity.desktop_cover}
          // @ts-ignore
          onChange={(v): void => form.setFieldValue('desktop_cover', v[0])}
          disabled={readOnly}
        />
        <UploadRow
          action={portal.portal.activity.uploadImageUrl}
          authorization={auth.token}
          title="Обложка для планшета *"
          subtitle="Загрузите баннер размером 904х387px, максимальный вес 5мб"
          sizeLimit={5000}
          accept=".png,.jpg"
          value={activity.tablet_cover}
          // @ts-ignore
          onChange={(v): void => form.setFieldValue('tablet_cover', v[0])}
          disabled={readOnly}
        />
        <UploadRow
          action={portal.portal.activity.uploadImageUrl}
          authorization={auth.token}
          title="Обложка для мобильной версии *"
          subtitle="Загрузите баннер размером 335х214px, максимальный вес 5мб"
          sizeLimit={5000}
          accept=".png,.jpg"
          value={activity.mobile_cover}
          // @ts-ignore
          onChange={(v): void => form.setFieldValue('mobile_cover', v[0])}
          disabled={readOnly}
        />
        <Stack direction="column" spacing={24}>
          <ControlBlock
            label="Место проведения мероприятия"
            required
            style={{ flex: 6 }}
            control={
              <TextField
                fullWidth
                placeholder="Ввод текста"
                value={activity.place}
                onChange={(e): void => form.setFieldValue('place', e.target.value)}
                error={!!form.getFieldError('place')}
                helperText={form.getFieldError('place')}
                readOnly={readOnly}
              />
            }
          />
          <Stack direction="row" spacing={24}>
            <ControlBlock
              label="Стоимость участия, ₽"
              required
              control={
                <NumberField
                  fullWidth
                  placeholder="Ввод текста"
                  value={String(activity.price)}
                  onChange={(e): void => form.setFieldValue('price', Number(e.target.value))}
                  error={!!form.getFieldError('price')}
                  readOnly={readOnly}
                  helperText={form.getFieldError('price')}
                />
              }
            />
            <ControlBlock
              label="Ссылка на запись"
              required
              control={
                <TextField
                  fullWidth
                  placeholder="Ввод текста"
                  value={activity.link_to_entry}
                  onChange={(e): void => form.setFieldValue('link_to_entry', e.target.value)}
                  error={!!form.getFieldError('link_to_entry')}
                  readOnly={readOnly}
                  helperText={form.getFieldError('link_to_entry')}
                />
              }
            />
          </Stack>

          <Stack direction="row" spacing={48}>
            <FormControlLabel
              control={<Radio
                name="price_visible"
                readOnly={readOnly}
                checked={activity.price_visible}
                onClick={(): void => form.setFieldValue('price_visible', !activity.price_visible)} />
              }
              label="Показывать стоимость на странице"
              value="yes"
            />

            <FormControlLabel
              control={<Radio
                name="is_main"
                readOnly={readOnly}
                checked={activity.is_main}
                onClick={(): void => form.setFieldValue('is_main', !activity.is_main)} />
              }
              label="Разместить на главной странице"
              value="yes"
            />
          </Stack>

          {activity.is_main && <ControlBlock
            label="Введите краткое описание"
            required
            style={{ flex: 6 }}
            control={
              <Editor
                fullWidth
                placeholder="Ввод текста"
                value={activity.short_description}
                onChange={(v): void => form.setFieldValue('short_description', v)}
                error={!!form.getFieldError('short_description')}
                helperText={form.getFieldError('short_description')}
                readOnly={readOnly}
              />
            }
          />}
        </Stack>
      </Stack>

    </Stack>
  );
});
