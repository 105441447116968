import React from 'react';
import { ColumnsType } from 'rc-table/lib/interface';
import { IPortalSystem } from '../../../../stores/system/types';
import { tableCellMore } from '../../../components/ui/table/table.columns';
import { Typography } from '../../../components/ui/typography';

export const getSystemsListColumns = (opts: {
  onMore: (system: IPortalSystem) => (e: React.MouseEvent<HTMLElement>) => void;
}): ColumnsType<IPortalSystem> => {
  const columns: ColumnsType<IPortalSystem> = [
    {
      title: 'Наименование системы',
      width: 1000,
      render: (value, record) => <Typography style={{ wordWrap: 'break-word' }}>{record.title}</Typography>,
    },
    tableCellMore(opts.onMore),
  ];

  return columns;
};
