import React from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { createUseStyles } from 'react-jss';
import cn from 'classnames';
import { Paper } from '../paper';
import { Typography } from '../typography';
import { useScrollHidden } from './menu.hook';
import { MenuPlacement, MenuProps } from './menu.models';

const PREFIX = 'Menu';
const useStyles = createUseStyles(
  (theme) => ({
    root: {
      border: 'solid 1px rgb(209 213 219 / 2)',
      position: 'fixed',
      zIndex: theme.zIndex.menu,
      maxHeight: 300,
      overflowY: 'auto',
      overflowX: 'hidden',
      paddingLeft: 0,
    },
    hidden: {
      display: 'none',
    },
    emptyText: {
      cursor: 'default',
      color: '#909EBB',
      padding: '8px 24px',
    },
  }),
  { name: PREFIX },
);

const getStyleByPlacement = (placement: MenuPlacement, rect: DOMRect): React.CSSProperties => {
  switch (placement) {
    case 'bottom-right': {
      return {
        left: rect.left,
        top: rect.top + rect.height - 10,
      };
    }
    case 'bottom-center': {
      return {
        left: rect.left + rect.width / 2,
        top: rect.top + rect.height - 10,
        transform: 'translateX(-50%)',
      };
    }
    case 'bottom-left': {
      return {
        left: rect.left + rect.width,
        top: rect.top + rect.height - 10,
        transform: 'translateX(-100%)',
      };
    }
    default: {
      return {};
    }
  }
};

export const Menu: React.FC<MenuProps> = (props) => {
  const {
    className,
    children,
    open,
    onClose,
    anchorEl,
    style = {},
    placement = 'bottom-center',
    fullWidth,
    emptyText,
    ...other
  } = props;

  const classes = useStyles();

  const isOpen = Boolean(open && anchorEl);
  const rootCN = cn(classes.root, { [classes.hidden]: !isOpen }, className);

  const rect = anchorEl ? anchorEl.getBoundingClientRect() : null;

  const content = (
    <Paper
      component={(p): JSX.Element => <ul {...p} />}
      className={rootCN}
      style={
        rect ? { minWidth: fullWidth ? rect.width : undefined, ...style, ...getStyleByPlacement(placement, rect) } : {}
      }
      {...other}
    >
      {children || (emptyText && <Typography className={classes.emptyText}>{emptyText}</Typography>)}
    </Paper>
  );

  useScrollHidden(isOpen);

  if (!open) {
    return content;
  }

  return (
    <ClickAwayListener
      onClickAway={(e): void => {
        if (open && onClose && e.target !== anchorEl) {
          onClose();
        }
      }}
    >
      {content}
    </ClickAwayListener>
  );
};
