import { yup } from '../../../shared/utils/yup';
import { IPortalActivity } from '../../activity/types';

const yupActivity: Partial<Record<keyof IPortalActivity, yup.BaseSchema>> = {
  title: yup.string().required().nullable().max(200),
  start_date: yup.date().nullable(),
  end_date: yup.date().nullable()
    .test('is-valid', 'Дата окончания не может быть раньше даты начала', function(endDate) {
      const startDate = this.resolve(yup.ref('start_date'));

      return !(startDate && endDate && endDate < startDate);
    }),
  desktop_cover: yup.string().required(),
  tablet_cover: yup.string().required(),
  mobile_cover: yup.string().required(),
  place: yup.string().required().max(150),
  price: yup.number().positive().required()
    .nullable()
    .moreThan(-1, 'должно быть не меньше 0')
    .lessThan(999999999999999)
    .typeError('Введите числовое значение'),
  link_to_entry: yup.string().required(),
  full_description: yup.string().required().max(3000),
  is_main: yup.boolean(),
  short_description: yup.string().when('is_main', {
    is: true,
    then: yup.string().required().max(1000),
  }),
  speakers: yup.array().of(
    yup.object().shape({
      fullname: yup.string()
        .nullable()
        .trim()
        .test(
          'valid-fullname',
          'ФИО должно содержать не меньше двух слов',
          (value) => (value as string).split(' ').length >= 2 || value?.trim() === '',
        )
        .max(150),
      appointment: yup.string().max(150).nullable(),
      photo_id: yup.string().nullable(),
    }),
  ),
  tags: yup.array().of(yup.string().max(60)),
};

export const activitySchema = yup.object({}).shape(yupActivity);
