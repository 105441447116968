import { createUseStyles } from 'react-jss';
import { observer } from 'mobx-react';
import { IActivitySpeaker } from '../../../stores/activity/types';
import { useAppStore } from '../../../stores/root/root.context';
import { ControlBlock } from '../control-block';
import { Button } from '../ui/button';
import { Divider } from '../ui/divider';
import { IconButton } from '../ui/icon-button';
import { AddIcon } from '../ui/icons/add';
import { CloseIcon } from '../ui/icons/close';
import { onErrorSnackbar } from '../ui/snackbar/snackbar.utils';
import { Stack } from '../ui/stack';
import { TextField } from '../ui/text-field';
import { UploadFile } from '../ui/upload-file';


const PREFIX = 'DialogCreateActivitySpeaker';
const useStyles = createUseStyles(() => ({
  root: {
    marginTop: 32,
    maxHeight: '380px',
    overflowY: 'auto',
  },
}), { name: PREFIX });

type DialogCreateActivitySpeakerProps = {
  readOnly?: boolean;
};


export const DialogCreateActivitySpeaker:React.FC<DialogCreateActivitySpeakerProps> = observer((props) => {
  const { readOnly } = props;
  const { portal, auth, activityForm: form } = useAppStore();
  const classes = useStyles();

  const speakers = form.getFieldValue('speakers') as IActivitySpeaker[];

  return (
    <Stack
      className={classes.root}
      direction="column"
      spacing={24}
      divider={<Divider />}
    >
      {speakers.map((speaker, index) => (
        <Stack key={`speakers-${index}`} direction="column" spacing={24} style={{ position: 'relative' }}>
          {speakers.length > 1 &&
            <Stack justifyContent="flex-end" style={{ position: 'absolute', right: 0, top: -3 }}>
              <IconButton onClick={(): void => form.deleteSpeaker(index)} style={{ marginBottom: 0, marginTop: 'auto' }}>
                <CloseIcon style={{ width: 24, height: 24, color: '#909EBB', marginBottom: 0, marginTop: 0 }} />
              </IconButton>
            </Stack>
          }
          <ControlBlock
            label="ФИО"
            style={{ flex: 2 }}
            control={
              <TextField
                fullWidth
                placeholder="Введите имя"
                value={speaker.fullname}
                onChange={(e): void => form.updateSpeaker(index, 'fullname', e.target.value)}
                error={!!form.getFieldError(`speakers[${index}].fullname`)}
                helperText={form.getFieldError(`speakers[${index}].fullname`)}
                readOnly={readOnly}
              />
            }
          />
          <ControlBlock
            label="Должность"
            control={
              <TextField
                fullWidth
                placeholder="Ввод текста"
                value={speaker?.appointment}
                onChange={(e): void => form.updateSpeaker(index, 'appointment', e.target.value)}
                error={!!form.getFieldError(`speakers[${index}].appointment`)}
                helperText={form.getFieldError(`speakers[${index}].appointment`)}
                readOnly={readOnly}
              />
            }
          />
          <ControlBlock
            label="Фото спикера"
            control={
              <UploadFile
                action={portal.portal.activity.uploadImageUrl}
                value={speaker.photo_id}
                authorization={auth.token}
                onError={onErrorSnackbar}
                accept=".jpeg,.jpg,.png"
                // @ts-ignore
                onChange={(v): void => form.updateSpeaker(index, 'photo_id', v)}
                readOnly={readOnly}
              />
            }
          />
        </Stack>
      ))}
      {!readOnly && <Button
        startIcon={<AddIcon />}
        variant="outlined"
        color="primary"
        onClick={(): void => form.addSpeaker()}
        style={{ justifyContent: 'center', border: 'none', color: '#0071CE', fontSize: '16px' }}
      >
        добавить спикера
      </Button>}
    </Stack>
  );
});
