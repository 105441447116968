import React from 'react';
import { createUseStyles } from 'react-jss';
import { Divider } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { IQuestionAnswer } from '../../../stores/question/types';
import { useAppStore } from '../../../stores/root/root.context';
import { ControlBlock } from '../../lab/control-block';
import { Button } from '../ui/button';
import { Dialog, DialogFooter, DialogHeader } from '../ui/dialog';
import { Editor } from '../ui/editor';
import { IconButton } from '../ui/icon-button';
import { AddIcon } from '../ui/icons/add';
import { CloseIcon } from '../ui/icons/close';
import { NumberField } from '../ui/number-field';
import { Select, SelectItem } from '../ui/select';
import { onErrorSnackbar } from '../ui/snackbar/snackbar.utils';
import { Stack } from '../ui/stack';
import { Typography } from '../ui/typography';
import { UploadFile } from '../ui/upload-file';
import { QuestionDialogProps } from './dialog-question.models';


const PREFIX = 'DialogQuestionEdit';
const useStyles = createUseStyles(
  () => ({
    root: {
      width: '992px',
    },
    cancelBtn: {
      justifyContent: 'center',
      height: '44px',
      fontSize: '16px',
    },
    submitBtn: {
      justifyContent: 'center',
      border: 'none',
      height: '44px',
      fontSize: '16px',
    },
    answerBlock: {
      flexDirection: 'row',
    },
    upLoadImage: {
      marginTop: '30px',
    },
  }),
  { name: PREFIX },
);

export const QuestionDialog: React.FC<QuestionDialogProps> = observer((props) => {
  const { className, onClose, onSubmit, ...other } = props;
  const { questionForm: form, portal, auth } = useAppStore();
  const question = form.getValues();
  const disabledModeration = !form.isValid;
  const [selectedSystem, setSelectedSystem] = React.useState('');

  const answers = form.getFieldValue('answers') as IQuestionAnswer[];
  const { data: dataSystem, refetch: refetchSystem } = portal.systemList.useQuery({ variables: {} });

  const handleSelectSystem = (v: string): void => {
    form.setFieldValue('system', v as string);
    setSelectedSystem(v);
  };

  const { data: dataSection, refetch: refetchSection } = portal.sectionList.useQuery({ variables: { system: selectedSystem } });
  const classes = useStyles();
  const rootCN = cn(classes.root, className);

  const handleSave = (): void => {
    if (onSubmit) {
      onSubmit();
    }
  };

  React.useEffect(() => {
    refetchSection();
    refetchSystem();
  }, [question.question]);

  return (
    <Dialog maxWidth="md" fullWidth color="primary" className={rootCN} onClose={onClose} {...other}>
      <DialogHeader onClose={onClose}>
        <Typography variant="h3" fontWeight="bold">
                    Добавить новую запись
        </Typography>
      </DialogHeader>

      <Divider style={{ marginTop: '20px', marginBottom: '28px' }} />

      <Stack direction="column" spacing={24}>
        <ControlBlock
          label="Введите приоритет"
          required
          control={
            <NumberField
              fullWidth
              min={0}
              max={100}
              error={!!form.getFieldError('sort_priority')}
              helperText={form.getFieldError('sort_priority')}
              placeholder="Ввод номера от 0 до 100"
              value={String(question.sort_priority)}
              onChange={(e): void => form.setFieldValue('sort_priority', Number(e.target.value))}
            />
          }
        />
        <ControlBlock
          label="Введите текст вопроса"
          required
          control={
            <Editor
              fullWidth
              placeholder="Ввод текста"
              value={question.question}
              onChange={(v): void => form.setFieldValue('question', v)}
            />
          }
        />
        <Divider style={{ margin: 0 }} />

        {answers.map((answer, index) => (
          <div key={index}>
            {answers.length > 1 &&
              <Stack>
                <IconButton onClick={(): void => form.deleteAnswers(index)}>
                  <CloseIcon style={{ width: 24, height: 24, color: '#909ebb', marginBottom: 0, marginTop: 0 }} />
                </IconButton>
              </Stack>
            }
            <ControlBlock
              label="Введите текст ответа"
              required
              control={
                <Editor
                  fullWidth
                  placeholder="Ввод текста"
                  value={answer.text}
                  onChange={(v): void => form.updateAnswers(index, 'text', v)}
                  error={!!form.getFieldError(`answers[${index}].text`)}
                  helperText={form.getFieldError(`answers[${index}].text`)}
                />
              }
            />
            <ControlBlock
              className={classes.upLoadImage}
              label="Загрузить изображение"
              control={
                <UploadFile
                  action={portal.portal.question.uploadImageUrl}
                  value={answer.picture}
                  authorization={auth.token}
                  onError={onErrorSnackbar}
                  // @ts-ignore
                  onChange={(v): void => form.updateAnswers(index, 'picture', v)}
                />
              }
            />
          </div>
        ))}
        <Button
          startIcon={<AddIcon />}
          variant="outlined"
          color="primary"
          onClick={(): void => form.addAnswer()}
          style={{ justifyContent: 'center', border: 'none', color: '#0071CE', fontSize: '16px' }}
        >
                    добавить еще ответ
        </Button>

        <ControlBlock
          label="Наименование системы"
          required
          control={
            <Select
              placeholder="Ничего не выбрано"
              fullWidth
              variant="outlined"
              // @ts-ignore
              value={question?.system._id ? question.system._id : question.system}
              onChange={(v): void => handleSelectSystem(v)}
            >
              {(dataSystem?.items || []).map((item) => (
                <SelectItem key={item._id} value={item._id}>{item.title}</SelectItem>
              ))}
            </Select>
          }
        />
        <ControlBlock
          label="Наименование раздела"
          required
          control={
            <Select
              placeholder={(dataSection?.items.length === 0) ? 'Разделов нет' : 'Ничего не выбрано'}
              fullWidth
              variant="outlined"
              disabled={selectedSystem === '' || (dataSection?.items.length === 0)}
              // @ts-ignore
              value={question?.section._id ? question.section._id : question.section}
              onChange={(v): void => form.setFieldValue('section', v as string)}
            >
              {(dataSection?.items || []).map((item) => (
                <SelectItem key={item._id} value={item._id}>{item.title}</SelectItem>
              ))}
            </Select>
          }
        />
      </Stack>
      <DialogFooter>
        <Stack direction="column" spacing={8}>
          <Divider style={{ marginTop: '32px', marginBottom: '24px' }} />
          <Stack
            justifyContent="flex-end"
            spacing={16}
          >
            <Button
              variant="outlined"
              className={classes.cancelBtn}
              onClick={onClose}
            >
                            Отменить
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.submitBtn}
              onClick={handleSave}
              disabled={disabledModeration}
            >
                            Сохранить
            </Button>
          </Stack>
        </Stack>
      </DialogFooter>
    </Dialog>
  );
});
