import React from 'react';
import cn from 'classnames';
import { createUseStyles } from '../theme/createUseStyles';
import { PaperProps } from './paper.models';

const PREFIX = 'Paper';

const useStyles = createUseStyles(
  (theme) => ({
    root: {
      boxShadow: theme.shadows[0],
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.shape.borderRadius * 2,
      overflow: 'hidden',
    },
    outlined: {
      border: '1px solid #E4E7F2',
      boxShadow: 'none',
    },
  }),
  { name: PREFIX },
);

export const Paper: React.FC<React.PropsWithRef<PaperProps>> = React.forwardRef((paperProps, ref) => {
  const { className, component = 'div', variant, ...other } = paperProps;

  const classes = useStyles();
  const rootCN = cn(classes.root, { [classes.outlined]: variant === 'outlined' }, className);

  return React.createElement(component, { className: rootCN, ...other, ref });
});
