import { ICreateNews } from '../../news/types';

export const NEWS_FORM_INITIAL_STATE: ICreateNews = {
  title: '',
  short_description: '',
  date: undefined,
  desktop_cover: '',
  tablet_cover: '',
  mobile_cover: '',
  text_top: '',
  text_bottom: '',
  images_description: '',
  images: [''],
  similar_news: [],
};
