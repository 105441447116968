import { IPortalStore } from '..';
import { Order } from '../types';
import { convertEvent } from './event.mappers';
import { IPortalEvent, PortalEventOrderBy } from './types';

export type IPortalGetEventArgs = { id: string };
export type IPortalGetEventRes<TDate = Date> = IPortalEvent<TDate>;

export type IPortalFindEventArgs = {
  limit?: number;
  offset?: number;
  sort?: string;
} & IPortalTotalEventArgs;
export type IPortalFindEventRes<TDate = Date> = {
  items: IPortalEvent<TDate>[],
  totalCount: number,
};

export type IPortalTotalEventArgs = {
  title?: string;
};

export type IPortalTotalEventRes = number;

export type IPortalUpdateEventArgs = Omit<IPortalEvent,
| 'created_at'
| 'updated_at'
| 'status'
>;
export type IPortalUpdateEventRes = void;

export type IPortalCreateEventArgs = {
  title: string;
  description: string;

  desktop_cover: string;
  tablet_cover: string;
  mobile_cover: string;
};
export type IPortalCreateEventRes<TDate = Date> = IPortalEvent<TDate>;

export type IPortalDeleteEventArgs = { _id: string };
export type IPortalDeleteEventRes = void;

export type IPortalEventAcceptArgs = { _id: string };
export type IPortalEventAcceptRes = void;

export type IPortalEventDeclineArgs = { _id: string };
export type IPortalEventDeclineRes = void;

export class PortalEvent {

  protected portal: IPortalStore;
  public uploadUrl: string;

  constructor(portal: IPortalStore) {
    this.portal = portal;
    this.uploadUrl = `${portal.baseURL}/upload/image`;
  }

  public async get(args: IPortalGetEventArgs): Promise<IPortalGetEventRes> {
    const res = await this.portal
      .jsonRequest<IPortalGetEventRes<string>>({
      method: 'POST',
      url: '/event/get',
      data: args,
    });

    return convertEvent(res);
  }

  public async find(args: IPortalFindEventArgs): Promise<IPortalFindEventRes> {
    const res = await this.portal
      .jsonRequest<IPortalFindEventRes<string>>({
      method: 'GET',
      url: '/event/all',
      data: args,
    });

    return {
      items: res.items.map(convertEvent),
      totalCount: res.totalCount,
    };
  }

  public async update(args: IPortalUpdateEventArgs): Promise<IPortalUpdateEventRes> {
    await this.portal.textRequest({
      method: 'PATCH',
      url: '/event/update',
      data: args,
    });
  }

  public async create(args: IPortalCreateEventArgs): Promise<IPortalCreateEventRes> {
    const res = await this.portal
      .jsonRequest<IPortalCreateEventRes<string>>({
      method: 'POST',
      url: '/event/create',
      data: args,
    });

    return convertEvent(res);
  }

  public async delete(args: IPortalDeleteEventArgs): Promise<IPortalDeleteEventRes> {
    await this.portal.textRequest({
      method: 'DELETE',
      url: '/event/delete',
      data: args,
    });
  }

  public async eventAccept(args: IPortalEventAcceptArgs): Promise<IPortalEventAcceptRes> {
    await this.portal.textRequest({
      method: 'PATCH',
      url: '/event/accept',
      data: args,
    });
  }

  public async eventDecline(args: IPortalEventDeclineArgs): Promise<IPortalEventDeclineRes> {
    await this.portal.textRequest({
      method: 'PATCH',
      url: '/event/decline',
      data: args,
    });
  }

}
