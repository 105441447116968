import React from 'react';
import cn from 'classnames';
import BaseTextArea from 'rc-textarea';
import { FormHelperText } from '../form-helper-text';
import { useInputBaseStyles } from '../input-base';
import { createUseStyles } from '../theme/createUseStyles';
import { TextAreaProps } from './text-area.models';

const PREFIX = 'TextArea';

const useStyles = createUseStyles(
  () => ({
    root: {
      width: 250,
      minHeight: 60,
    },
    textarea: {
      minHeight: 60,
    },
    fullWidth: {
      '&$fullWidth': {
        width: '100%',
        minWidth: '100%',
        maxWidth: '100%',
      },
    },
  }),
  { name: PREFIX },
);

export const TextArea: React.FC<TextAreaProps> = (exampleProps) => {
  const {
    className,
    helperText,
    color = 'default',
    rows,
    placeholder,
    value,
    onChange,
    disabled,
    required,
    fullWidth,
    readOnly,
    error,
    name,
    ...other
  } = exampleProps;

  const classes = useStyles();
  const inputBaseClasses = useInputBaseStyles();

  const overrideColor = error ? 'error' : color;

  const rootCN = cn(classes.root, { [classes.fullWidth]: fullWidth }, className);
  const inputCN = cn(inputBaseClasses.input, classes.textarea, {
    [inputBaseClasses.colorDefault]: overrideColor === 'default',
    [inputBaseClasses.colorError]: overrideColor === 'error',
    [inputBaseClasses.colorWarning]: overrideColor === 'warning',
    [inputBaseClasses.colorSuccess]: overrideColor === 'success',
    [inputBaseClasses.fullWidth]: fullWidth,
    [inputBaseClasses.disabled]: disabled,
    [inputBaseClasses.readOnly]: readOnly,
  });

  return (
    <div className={rootCN} {...other}>
      <BaseTextArea
        name={name}
        autoSize
        style={{ width: '100%', maxWidth: '100%', minWidth: '100%' }}
        className={inputCN}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        rows={rows}
        disabled={disabled}
        required={required}
        readOnly={readOnly}
      />
      {!!helperText && <FormHelperText color={overrideColor} variant="label">{helperText}</FormHelperText>}
    </div>
  );
};
