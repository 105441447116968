import React from 'react';
import cn from 'classnames';
import { createUseStyles } from '../theme/createUseStyles';
import { DividerProps } from './divider.models';

const PREFIX = 'Divider';

const useStyles = createUseStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.palette.divider,
      height: '1px',
      width: '100%',
      border: 'none',
    },
    vertical: {
      width: '1px',
      height: 'auto',
      flexShrink: 0,
    },
  }),
  { name: PREFIX },
);

export const Divider: React.FC<DividerProps> = (exampleProps) => {
  const { className, orientation, flexItem, ...other } = exampleProps;

  const classes = useStyles();
  const rootCN = cn(classes.root, { [classes.vertical]: orientation === 'vertical' }, className);

  return <hr className={rootCN} {...other} />;
};
