import React from 'react';
import { createUseStyles } from 'react-jss';
import { Divider } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { useAppStore } from '../../../stores/root/root.context';
import { ControlBlock } from '../../lab/control-block';
import { Button } from '../ui/button';
import { Dialog, DialogFooter, DialogHeader } from '../ui/dialog';
import { Stack } from '../ui/stack';
import { TextField } from '../ui/text-field';
import { Typography } from '../ui/typography';
import { SystemDialogProps } from './dialog-system.models';


const PREFIX = 'DialogSystemEdit';
const useStyles = createUseStyles(
  () => ({
    root: {
      width: '992px',
    },
    cancelBtn: {
      justifyContent: 'center',
      height: '44px',
      fontSize: '16px',
    },
    submitBtn: {
      justifyContent: 'center',
      border: 'none',
      height: '44px',
      fontSize: '16px',
    },
  }),
  { name: PREFIX },
);

export const SystemDialog: React.FC<SystemDialogProps> = observer((props) => {
  const { className, onClose, onSubmit, ...other } = props;
  const { systemForm: form } = useAppStore();
  const system = form.getValues();
  const disabledModeration = !form.isValid;

  const classes = useStyles();
  const rootCN = cn(classes.root, className);

  const handleSave = (): void => {
    if (onSubmit) {
      onSubmit();
    }
  };
  return (
    <Dialog maxWidth="md" fullWidth color="primary" className={rootCN} onClose={onClose} {...other}>
      <DialogHeader onClose={onClose}>
        <Typography variant="h3" fontWeight="bold">
                    Добавить новую запись
        </Typography>
      </DialogHeader>

      <Divider style={{ marginTop: '20px', marginBottom: '28px' }} />

      <Stack direction="column" spacing={24}>
        <ControlBlock
          label="Введите наименование системы"
          required
          control={
            <TextField
              fullWidth
              placeholder="Ввод текста"
              value={system.title}
              onChange={(e): void => form.setFieldValue('title', e.target.value)}
            />
          }
        />
      </Stack>
      <DialogFooter>
        <Stack direction="column" spacing={8}>
          <Divider style={{ marginTop: '32px', marginBottom: '24px' }} />
          <Stack
            justifyContent="flex-end"
            spacing={16}
          >
            <Button
              variant="outlined"
              className={classes.cancelBtn}
              onClick={onClose}
            >
                            Отменить
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.submitBtn}
              onClick={handleSave}
              disabled={disabledModeration}
            >
                            Сохранить
            </Button>
          </Stack>
        </Stack>
      </DialogFooter>
    </Dialog>
  );
});
